import { faExclamationCircle, faPrescriptionBottle } from '@fortawesome/pro-solid-svg-icons';
import $ from '@stylix/core';
import React from 'react';

import { NewFeatureDialogType } from 'api_measure/web/routes/users/lib/users.types';

import {
  type NewFeatureDialogContent,
  type NewFeatureDialogContentProps,
} from 'src/Dashboard/NewFeatureDialog/useNewFeatureDialogContent';
import { trpc, trpcFetch } from 'src/data/trpc';
import { useCurrentUser } from 'src/data/user/useCurrentUser';
import AppToaster from 'src/ui/AppToaster';
import Button from 'src/ui/Button';
import colors from 'src/ui/colors';
import Icon from 'src/ui/Icon';

export const fdaApprovalsFeatureDialogContent: NewFeatureDialogContent = {
  newFeatureDialogType: NewFeatureDialogType.fdaApprovals,

  Component: FdaApprovalsFeatureDialogContent,

  useShouldShowContent: (disableHooks: boolean) => {
    const { provider } = useCurrentUser();

    // Get the patient count
    const fdaCounts = trpc.getFdaMeasuresSummary.useQuery(
      {
        provider: provider?.provider_empi_id,
      },
      // Only enable the query if the dialog is available
      { enabled: !disableHooks },
    );

    const total = (fdaCounts.data?.unreviewed || 0) + (fdaCounts.data?.partial_match || 0);

    // If the counts are still loading, return true to prevent any other dialog from being shown yet
    // Or, if the fda approvals dialog is available, and the patient count is > 0, then show the dialog.
    if (fdaCounts.isLoading || total) return total ? 'show' : 'delay';

    return 'pass';
  },

  async onCloseDialog() {
    // When this dialog closes, we need to update the user's settings to indicate that they have seen the "RxLinQ Updates" dialog.
    // We don't show that dialog after they have seen this one.
    try {
      await trpcFetch.updateUserSettings.mutate({
        new_feature_dialogs: {
          [NewFeatureDialogType.rxlinqUpdates]: true,
        },
      });
    } catch (e) {
      console.error(e);
      AppToaster.error(e.message);
    }
  },
};

export function FdaApprovalsFeatureDialogContent(props: NewFeatureDialogContentProps) {
  const { provider } = useCurrentUser();

  const fdaCounts = trpc.getFdaMeasuresSummary.useQuery({
    provider: provider?.provider_empi_id,
  });

  const total = (fdaCounts.data?.unreviewed || 0) + (fdaCounts.data?.partial_match || 0);

  if (!total) return null;

  return (
    <$.div flex-center flex-column text-center>
      <$.div
        border="8px solid #E8F7FF"
        background="#C2E6F2"
        width={140}
        height={140}
        border-radius={70}
        flex-center
        mt={25}
      >
        <Icon icon={faPrescriptionBottle} size={64} color="#FFF" />
      </$.div>

      <$.div size={24} mt={15}>
        Actionable Patients: RxLinQ
      </$.div>

      <$.div size={18} line-height={1.5} mt={15}>
        <$.div>
          {/* This extra wrapper span is to mimic next paragraph below, which requires the span to vertically align with the ! icon.
                The span with vertical-align causes the line height to be slightly different, so they both need it in order to be equal. */}
          <$.span vertical-align="middle">
            Actionable Patients: RxLinQ is a precision medicine feature that identifies patients for
            clinician review for newly approved, molecular-targeted therapies that require a genomic
            test. Identification is performed by matching patients to the FDA labeling information.
          </$.span>
        </$.div>
        <$.div>
          <Icon
            icon={faExclamationCircle}
            size={18}
            color={colors.red.primary}
            vertical-align="middle"
            mr={10}
            ml={-28}
          />
          <$.span vertical-align="middle">
            The RxLinQ feature is powered by genomic results and reports flowing into CancerLinQ
            from your primary EHR, document management system, and other data systems. Upgrades to
            your CancerLinQ data integration may be required to realize the full impact of this new
            tool. Contact your account manager to learn more.
          </$.span>
        </$.div>
      </$.div>

      <Button
        variant="blue"
        large
        font-weight={400}
        mt={30}
        onClick={() => {
          props.closeDialog('/patient/actionable/fda-approvals');
        }}
      >
        Launch RxLinQ
      </Button>
    </$.div>
  );
}
