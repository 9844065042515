import { createTRPCProxyClient, httpLink } from '@trpc/client';
import {
  createTRPCReact,
  httpBatchLink,
  type inferReactQueryProcedureOptions,
} from '@trpc/react-query';

import { transformer } from 'api_measure/lib/trpc';
import { type TrpcRouter } from 'api_measure/web/routes/trpcRouter';

import { getAuthHeaders } from 'src/data/axios';

import config from '~/config';

export type ReactQueryOptions = inferReactQueryProcedureOptions<TrpcRouter>;

// WEB API CLIENT

export const trpc = createTRPCReact<TrpcRouter>();

const batchLink = httpBatchLink({
  url: config.apiBase.measure || config.apiBase.default + '/quality',
  headers: () => getAuthHeaders(),
});

const nonBatchLink = httpLink({
  url: config.apiBase.measure || config.apiBase.default + '/quality',
  headers: () => getAuthHeaders(),
});

export const trpcClient = trpc.createClient({
  links: [config.apiBatching ? batchLink : nonBatchLink],
  transformer,
});

/**
 * This client can be used to manually fetch data without a react-query hook.
 */
export const trpcFetch = createTRPCProxyClient<TrpcRouter>({
  links: [config.apiBatching ? batchLink : nonBatchLink],
  transformer,
});

// TASK CLIENT

export const taskTrpc = createTRPCReact<TrpcRouter>();

const taskLink = httpLink({
  url: config.apiBase.task || config.apiBase.default + '/task',
  headers: () => getAuthHeaders(),
});

export const taskTrpcClient = taskTrpc.createClient({
  links: [taskLink],
  transformer,
});

/**
 * This client can be used to manually fetch data without a react-query hook from the
 * task service instead of the main web service.
 * ONLY use this if you need to interact with the task service. Otherwise, use the trpcClient.
 */
export const trpcTaskFetch = createTRPCProxyClient<TrpcRouter>({
  links: [taskLink],
  transformer,
});
