import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import * as React from 'react';

import Icon from 'src/ui/Icon';

interface ClearButtonProps {
  onClick(e: React.MouseEvent<HTMLButtonElement>): void;
}

export default function ClearButton(props: StylixProps<'button', ClearButtonProps>) {
  const { onClick, ...other } = props;
  return (
    <$.button
      type="button"
      flex-center
      alignSelf="center"
      border={0}
      bg="none"
      width={38}
      height={38}
      color="#0003"
      cursor="pointer"
      tabIndex={-1}
      $css={{
        '&:hover, &:focus': { color: '#0008' },
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      {...other}
    >
      <Icon icon={faTimes} size={16} />
    </$.button>
  );
}
