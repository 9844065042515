import { type NewFeatureDialogContent } from 'src/Dashboard/NewFeatureDialog/useNewFeatureDialogContent';

import { biosimilarsReportFeatureDialogContent } from './BiosimilarsReportFeatureDialogContent';
import { fdaApprovalsFeatureDialogContent } from './FdaApprovalsFeatureDialogContent';
import { rxLinqUpdatesFeatureDialogContent } from './RxLinqUpdatesFeatureDialogContent';
import { trialinqFeatureDialogContent } from './TriaLinQFeatureDialogContent';

export const newFeatureDialogContents: NewFeatureDialogContent[] = [
  fdaApprovalsFeatureDialogContent,
  biosimilarsReportFeatureDialogContent,
  trialinqFeatureDialogContent,
  rxLinqUpdatesFeatureDialogContent,
];
