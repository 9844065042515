import createGlobalState from 'src/ui/createGlobalState';

const useLoadingModalGlobalState = createGlobalState({ isOpen: false, message: '' });

export default function useLoadingModal() {
  const [loadingModal, setLoadingModal] = useLoadingModalGlobalState();
  return {
    loadingModal,
    withLoadingModal: async function <T>(
      message: string,
      cb: () => Promise<T>,
      enable = true,
    ): Promise<T> {
      try {
        if (enable) setLoadingModal({ isOpen: true, message });
        return await cb();
      } finally {
        setLoadingModal((m) => ({ ...m, isOpen: false }));
      }
    },
    showLoadingModal: (message: string) => setLoadingModal({ isOpen: true, message }),
    hideLoadingModal: () => setLoadingModal((m) => ({ ...m, isOpen: false })),
  };
}
