import { Dialog, type DialogProps as BPDialogProps } from '@blueprintjs/core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import $, { useClassifyProps, type StylixProps } from '@stylix/core';
import React from 'react';

import Button from './Button';

interface ScrollableModalProps extends BPDialogProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  maxWidth?: number;
  modalWrapperProps?: any;
  scrollWrapperProps?: any;
  disableScroll?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

export default function ScrollableDialog(props: StylixProps<typeof Dialog, ScrollableModalProps>) {
  const {
    header,
    footer,
    maxWidth,
    children,
    onClose,
    modalWrapperProps = {},
    scrollWrapperProps = {},
    disableScroll = false,
    scrollRef,
    transitionDuration, // From blueprint Dialog props; conflicts with css prop
    ...other
  } = props;

  const [styles, dialogProps] = useClassifyProps(other);

  return (
    <$
      $el={<Dialog transitionDuration={transitionDuration} {...dialogProps} />}
      {...styles}
      onClose={onClose}
    >
      <$
        $el="div"
        $flex-center
        relative
        z-index={0}
        column
        alignItems="stretch"
        overflow="hidden"
        maxHeight={disableScroll ? '' : 'calc(100vh - 60px)'}
        maxWidth={maxWidth}
        {...modalWrapperProps}
        onClick={(e) => {
          // Prevents clicks from taking action on anything behind the modal. This can happen if
          // the modal is triggered by an element (like a button) inside another element that has a
          // click handler (like a table row).
          e.stopPropagation();
          modalWrapperProps?.onClick?.(e);
        }}
      >
        <$.div flex="0 0 auto" relative z-index={2}>
          {onClose && (
            <Button
              variant="round-ghost"
              absolute
              top={15}
              right={20}
              onClick={onClose as any}
              data-test="scrollable-dialog-close-btn"
              faIcon={faTimes}
            />
          )}
          {header}
        </$.div>
        <$.div
          flex="1 1 auto"
          overflowY="auto"
          relative
          z-index={1}
          ref={scrollRef}
          {...scrollWrapperProps}
        >
          {children}
        </$.div>
        <$.div flex="0 0 auto">{footer}</$.div>
      </$>
    </$>
  );
}
