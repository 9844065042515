import { faBan } from '@fortawesome/pro-regular-svg-icons';
import $ from '@stylix/core';
import React from 'react';

import MessageText from 'src/ui/MessageText';

export default function PageForbidden() {
  return (
    <MessageText flex="1 1 auto" alignCenter icon={faBan} iconProps={{ size: 50 }}>
      <$.div mt={20} size={24}>
        Unauthorized
      </$.div>
      <$.div mt={20} size={18}>
        Sorry, you are not authorized to view this page.
      </$.div>
    </MessageText>
  );
}
