import { Popover } from '@blueprintjs/core';
import { faCommentAltExclamation, faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { useClickOutside } from '@react-hookz/web';
import $, { type StylixProps } from '@stylix/core';
import React, { useRef, useState } from 'react';

import { trpc } from 'src/data/trpc';
import { useCurrentUser } from 'src/data/user/useCurrentUser';
import Button from 'src/ui/Button';
import colors from 'src/ui/colors';
import Icon from 'src/ui/Icon';

export default function PlatformStatusMenuIcon(props: StylixProps) {
  const { internal } = useCurrentUser();
  const { data: platformStatus } = trpc.getPlatformStatus.useQuery();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  useClickOutside(popoverRef, () => {
    setPopoverOpen(false);
  });

  if (!platformStatus?.status || !internal) return null;

  return (
    <$.flex flex-center cursor="pointer" ref={popoverRef} {...props}>
      <Popover
        usePortal={false}
        isOpen={popoverOpen}
        content={
          <$.div padding="25px 30px" width={600}>
            <$.div color={colors.red.dark} fontSize={20} mb={30}>
              Platform status message is enabled.
            </$.div>

            <$.div
              mb={20}
              fontSize={16}
              border="1px solid #EEE"
              borderRadius={4}
              padding="10px 14px"
              lineHeight={1.4}
              whiteSpace="pre-line"
              alignCenter
            >
              {platformStatus.status.message}
            </$.div>

            <$.div
              bold
              mb={30}
              color={platformStatus.status.disable_login ? colors.red.medium : colors.blue.primary}
            >
              <Icon icon={faLockAlt} mr={10} fontSize={14} />
              Login is currently {platformStatus.status.disable_login ? 'disabled' : 'enabled'}.
            </$.div>

            <Button
              variant="ghost"
              linkTo="/admin/platform-status"
              onClick={() => setPopoverOpen(false)}
            >
              Update Platform Status Message...
            </Button>
          </$.div>
        }
      >
        <Icon
          fontSize={20}
          color={platformStatus.status.disable_login ? colors.red.primary : colors.orange.primary}
          icon={faCommentAltExclamation}
          onClick={() => setPopoverOpen((v) => !v)}
        />
      </Popover>
    </$.flex>
  );
}
