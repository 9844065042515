import { faUserSlash } from '@fortawesome/pro-light-svg-icons';
import { faRedoAlt, faSignOut, faUser } from '@fortawesome/pro-regular-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import React from 'react';

import { useEndOrgSwitchMutation } from 'src/Admin/OrgSwitcher/data/orgSwitcherQueries';
import useLogOut from 'src/App/useLogOut';
import Button from 'src/ui/Button';
import Loader from 'src/ui/Loader';
import MessageText from 'src/ui/MessageText';

export interface LoginLoaderError {
  message: React.ReactNode;
  data?: any;
}

interface LoginProps {
  error?: LoginLoaderError;
}

/**
 * A full-screen loading page that displays while the user is being authenticated.
 */
export default function LoginLoader({ error, ...styles }: StylixProps<'div', LoginProps>) {
  const logOut = useLogOut();

  const endOrgSwitcherMutation = useEndOrgSwitchMutation();

  return (
    <$.flex flex-center column height="100vh" {...styles}>
      {error ? (
        <>
          <MessageText
            error
            icon={faUserSlash}
            iconProps={{ size: 40 }}
            alignCenter
            maxWidth={800}
            p="0 20px"
          >
            <$.div size={20} color="#0008">
              Unable to load user. Please contact your administrator.
            </$.div>
            <$.div mt={10} size={16} lineHeight={1.5} color="#0004">
              {error.message}
            </$.div>
          </MessageText>
          <$.div mt={30}>
            <Button
              faIcon={faRedoAlt}
              variant="ghost"
              onClick={() => window.location.reload()}
              mr={10}
            >
              Reload
            </Button>

            {error.data?.orgSwitcherMismatch ? (
              <Button
                faIcon={faSignOut}
                variant="gold"
                onClick={() => endOrgSwitcherMutation.mutateAsync()}
              >
                End Org Switcher
              </Button>
            ) : (
              <Button
                faIcon={faSignOut}
                variant="ghost"
                onClick={(e) => logOut({ e, track: false })}
              >
                Log out
              </Button>
            )}
          </$.div>
        </>
      ) : (
        <Loader icon={faUser}>Logging in...</Loader>
      )}
    </$.flex>
  );
}
