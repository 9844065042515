import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import $, { type StylixProps } from '@stylix/core';
import React from 'react';

import colors from 'src/ui/colors';
import Icon, { type IconProps } from 'src/ui/Icon';

interface MessageTextProps {
  icon?: IconDefinition | false; // `false` to hide the default error icon if `error` is provided.
  iconProps?: Partial<IconProps>;
  error?: boolean;
  ghost?: boolean;
  small?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

/**
 * A standard message text with optional icon.
 */
export default function MessageText({
  icon,
  iconProps,
  error,
  ghost,
  small,
  children,
  ...styles
}: StylixProps<'div', MessageTextProps>) {
  let color = colors.grey.neutral;
  if (ghost) color = '#0004';
  if (error) color = colors.red.dark;

  return (
    <$.flex flex-center min-height={100} flexDirection="column" text-center {...styles}>
      {icon && <Icon icon={icon} size={small ? 18 : 26} color={color} {...iconProps} />}
      {children && (
        <$.span
          mt={icon ? (small ? 10 : 15) : 0}
          color="#0008"
          fontSize={small ? 15 : 18}
          fontWeight={300}
          line-height={1.5}
        >
          {children}
        </$.span>
      )}
    </$.flex>
  );
}
