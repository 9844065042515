import { faCommentAltExclamation } from '@fortawesome/pro-regular-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import React from 'react';

import { trpc } from 'src/data/trpc';
import colors from 'src/ui/colors';
import Icon from 'src/ui/Icon';

export default function PlatformStatusBanner(props: StylixProps) {
  const { data: platformStatus } = trpc.getPlatformStatus.useQuery();

  if (!platformStatus?.status || platformStatus.status.disable_login) return null;

  return (
    <$.flex
      flex-center
      justifyContent="flex-start"
      background={colors.orange.medium}
      padding="15px 100px 15px 50px"
      color="white"
      {...props}
    >
      <Icon icon={faCommentAltExclamation} flex="0 0 auto" color="white" size={28} mr={25} />
      <$.div flex="1 1 auto" fontSize={16} whiteSpace="pre-line" lineHeight={1.3}>
        {platformStatus.status.message}
      </$.div>
    </$.flex>
  );
}
