import $, { useGlobalStyles, type StylixProps } from '@stylix/core';
import React from 'react';

interface SkeletonProps {
  enabled?: boolean;
  type?: 'lighten' | 'darken';
  lines?: number;
  children?: (() => React.ReactNode) | React.ReactNode | React.ReactNode[];
}

/**
 * A Skeleton renders a gray pulsating box that indicates content is loading when `enabled` is true.
 * When `enabled` is false, only the child content is rendered (no wrapper element or styles are
 * rendered).
 *
 * The box must provide its own dimensions (width or height, flex, etc) because it does not render
 * any child content when `enabled` is true.
 *
 * The `lines` prop sets the height of the box to match a number of lines of text at the current
 * font-size. Note that the font size must be set ON the Skeleton or above it, as it does not know
 * the font size of the child elements.
 */
export default function Skeleton({
  enabled,
  type = 'darken',
  lines,
  children,
  ...styles
}: StylixProps<'div', SkeletonProps>) {
  if (enabled === undefined) enabled = true;
  if (!enabled) return <>{typeof children === 'function' ? children() : children}</>;

  return (
    <>
      <$.div
        borderRadius={4}
        animation={`SkeletonBg-${type} infinite alternate 0.7s ease-in-out`}
        {...styles}
      >
        {lines
          ? Array(lines)
              .fill(1)
              .map((_, i) => <$.div key={i}>&nbsp;</$.div>)
          : null}
      </$.div>
    </>
  );
}

export function SkeletonGlobalCss() {
  useGlobalStyles({
    '@keyframes SkeletonBg-lighten': {
      from: {
        backgroundColor: 'hsla(0, 0%, 100%, 0.15)',
      },
      to: {
        backgroundColor: 'hsla(0, 0%, 100%, 0.05)',
      },
    },
    '@keyframes SkeletonBg-darken': {
      from: {
        backgroundColor: 'hsla(0, 0%, 0%, 0.15)',
      },
      to: {
        backgroundColor: 'hsla(0, 0%, 0%, 0.05)',
      },
    },
  });
  return null;
}
