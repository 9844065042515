import { merge } from 'lodash-es';

let config: any;

export interface ClientConfig {
  okta: {
    issuer: string;
    clientId: string;
    disabled: boolean;
    redirectUri: string;
  };

  apiBatching: boolean;
  apiBase: {
    default: string;
    measure: string;
    task: string;
    job: string;
    batch: string;
    d1: string;
    looker: string;
  };

  aws: {
    universe: string;
    environment: string;
  };

  qopiDemo: boolean;

  dil: {
    mode: 'production' | 'onboarding';
    DB1Load: boolean;
    DB2Load: boolean;
    PdxToD2: boolean;
    ProdD1ToOnboarding: boolean;
    ProdToPreview: boolean;
    importRulesFromCodeCommit: boolean;
  };

  features: {};

  mixpanel: {
    token: string;
    debug: boolean;
  };

  trialinq: {
    redirectUri: (
      accessToken: string | undefined,
      redirect: string | undefined,
    ) => string | undefined;
  };

  stylixDevMode: boolean;

  // Indicates whether the app can be debugged using the DevMenu
  canDebug: boolean;
}

export function mergeConfig(base: PartialClientConfig, config: PartialClientConfig): ClientConfig {
  return merge(base, config) as ClientConfig;
}

// Create deep partial type of ClientConfig
export type PartialClientConfig = {
  [P in keyof ClientConfig]?: Partial<ClientConfig[P]>;
};

/**
 * The verbose syntax here is so that parcel can include only the necessary config file in the final bundle.
 */

if (process.env.REACT_CONFIG === 'local') {
  config = require(`~/config/local`).default;
} else if (process.env.REACT_CONFIG === 'sandbox') {
  config = require(`~/config/sandbox`).default;
} else if (process.env.REACT_CONFIG === 'sandbox2') {
  config = require(`~/config/sandbox2`).default;
} else if (process.env.REACT_CONFIG === 'dev') {
  config = require(`~/config/dev`).default;
} else if (process.env.REACT_CONFIG === 'qa') {
  config = require(`~/config/qa`).default;
} else if (process.env.REACT_CONFIG === 'qa2') {
  config = require(`~/config/qa2`).default;
} else if (process.env.REACT_CONFIG === 'onboarding') {
  config = require(`~/config/onboarding`).default;
} else if (process.env.REACT_CONFIG === 'demo') {
  config = require(`~/config/demo`).default;
} else if (process.env.REACT_CONFIG === 'preview') {
  config = require(`~/config/preview`).default;
} else if (process.env.REACT_CONFIG === 'preview2') {
  config = require(`~/config/preview2`).default;
} else if (process.env.REACT_CONFIG === 'prod') {
  config = require(`~/config/prod`).default;
} else if (process.env.REACT_CONFIG === 'dil') {
  config = require(`~/config/dil`).default;
}

(window as any).config = config;

export default config as ClientConfig;
