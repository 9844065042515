import { faSyringe } from '@fortawesome/pro-solid-svg-icons';
import $ from '@stylix/core';
import React from 'react';

import { Role } from 'api_measure/lib/roles';
import { OrgFeature } from 'api_measure/web/routes/practice/practice.shared';
import { NewFeatureDialogType } from 'api_measure/web/routes/users/lib/users.types';

import {
  type NewFeatureDialogContent,
  type NewFeatureDialogContentProps,
} from 'src/Dashboard/NewFeatureDialog/useNewFeatureDialogContent';
import { useHasOrgFeature, useUserHasRoles } from 'src/data/user/roles';
import Button from 'src/ui/Button';
import Icon from 'src/ui/Icon';

export const trialinqFeatureDialogContent: NewFeatureDialogContent = {
  newFeatureDialogType: NewFeatureDialogType.triaLinQ,

  Component: TriaLinQFeatureDialogContent,

  useShouldShowContent: () => {
    const hasOrgFeature = useHasOrgFeature();
    const hasRoles = useUserHasRoles();

    // User must have TriaLinQ role, but also be a provider or org admin
    if (
      hasRoles(Role.trialinq) &&
      hasRoles(Role.provider, Role.orgAdmin) &&
      hasOrgFeature(OrgFeature.TriaLinQ)
    )
      return 'show';

    return 'pass';
  },
};

export function TriaLinQFeatureDialogContent(props: NewFeatureDialogContentProps) {
  return (
    <$.div flex-center flex-column text-center>
      <$.div
        border="8px solid #E8F7FF"
        background="#C2E6F2"
        width={140}
        height={140}
        border-radius={70}
        flex-center
        mt={25}
      >
        <Icon icon={faSyringe} size={64} color="#FFF" />
      </$.div>

      <$.div size={24} mt={15}>
        TriaLinQ
      </$.div>

      <$.div size={18} line-height={1.5} mt={15}>
        <$.div>
          TriaLinQ utilizes artificial intelligence (AI) to identify patients who may be eligible
          for clinical trials at your practice by analyzing structured and unstructured data from
          your EHR. This technology can save your practice time in identifying patients for trials
          and broaden the eligible population.
        </$.div>

        <$.img src={require('./trialinq-graphic.png')} width={1182 / 2 + 'px'} mt={25} />

        <$.div m="25px auto 0" max-width={675}>
          Simply select your desired patient count from the SmartLinQ Home Dashboard, and you'll
          seamlessly access a comprehensive view of these patients within the full suite of the
          TriaLinQ feature.
        </$.div>
      </$.div>

      <Button
        variant="blue"
        large
        font-weight={400}
        mt={30}
        width={200}
        onClick={() => props.closeDialog()}
      >
        Okay
      </Button>
    </$.div>
  );
}
