import { mergeConfig } from '.';
import { baseProd } from './base';

export default mergeConfig(baseProd, {
  aws: {
    universe: 'prod',
    environment: 'prod',
  },
  okta: {
    redirectUri: 'https://app.cancerlinq.org/implicit/callback',
    clientId: '0oa8gv90c2dG60Tld1t7',
  },
  dil: {
    DB1Load: false, // onboarding
    DB2Load: false, // onboarding
    PdxToD2: true, // prod
    ProdD1ToOnboarding: false, // onboarding
    ProdToPreview: false, // preview
  },
});
