import { Popover2 } from '@blueprintjs/popover2';
import { useClassifyProps, useStyles, type StylixProps } from '@stylix/core';

export interface PopoverProps {
  arrow?: boolean;
  offset?: any;
  targetStyles?: StylixProps<'span'>;
  children?: any;
}

/**
 * Blueprint's Popover with defaults for our normal usage
 */
export const Popover = ({
  arrow,
  offset,
  position,
  content,
  transitionDuration,
  fill,
  targetStyles,
  borderRadius = 6,
  background,
  $css,
  ...rest
}: StylixProps<typeof Popover2, PopoverProps>) => {
  const [styles, other] = useClassifyProps(rest);
  const className = useStyles({
    borderRadius,
    boxShadow: '0 15px 30px hsla(0, 0%, 0%, 0.2), 0 1px 5px hsla(0, 0%, 0%, 0.1)',
    ...styles,
    $css: {
      '.bp4-popover2-content': {
        borderRadius,
        overflow: 'hidden',
        background,
      },
      '.bp4-popover2-arrow-fill': {
        fill: background || '#FFF',
      },
      $css,
    },
  });
  const targetClassName = useStyles(targetStyles || {});
  return (
    <Popover2
      position={position}
      content={content}
      transitionDuration={transitionDuration}
      fill={fill}
      popoverClassName={className}
      targetProps={rest.renderTarget ? undefined : { className: targetClassName }}
      modifiers={{
        arrow: { enabled: !!arrow },
        offset: offset ? { enabled: true, options: { offset: offset || [0, 8] } } : undefined,
      }}
      {...other}
    />
  );
};
