import $ from '@stylix/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useNewFeatureDialogContent } from 'src/Dashboard/NewFeatureDialog/useNewFeatureDialogContent';
import { trpc } from 'src/data/trpc';
import colors from 'src/ui/colors';
import Dialog from 'src/ui/Dialog';
import { openLinkTo } from 'src/ui/Link';
import Super from 'src/ui/Super';

export function NewFeatureDialog() {
  const updateUserSettings = trpc.updateUserSettings.useMutation();

  const [open, setOpen] = useState(true);

  const history = useHistory();

  const featureDialog = useNewFeatureDialogContent(
    // Using the value of `open` to determine whether the user has already seen a new feature dialog in this session.
    // Only the user closing the dialog will set `open` to false, so this will only be true once per session.
    !open,
  );

  /**
   * Close the dialog, and opens a link, if specified.
   * This is used by the dialog's X button to close without opening a link,
   * and by the dialog content component's "Go to feature" button to close the dialog and open a link.
   */
  async function handleClose(linkTo?: string) {
    if (!featureDialog) return;

    // Close the dialog
    setOpen(false);

    await featureDialog.onCloseDialog?.();

    // Update the user's settings to indicate that they have seen this dialog.
    updateUserSettings.mutate({
      new_feature_dialogs: {
        [featureDialog.newFeatureDialogType]: true,
      },
    });

    // Link to the specified location, if provided.
    if (linkTo) {
      openLinkTo({ linkTo, history });
    }
  }

  return (
    <Dialog isOpen={!!featureDialog && open} onClose={() => handleClose()}>
      <$.div flex-center flex-column text-center width="100vw" max-width={980} p="70px 75px 60px">
        <$.div size={30} color={colors.blue.primary}>
          What's New in CancerLinQ<Super>®</Super>
        </$.div>

        {/**
         * Render the component that was determined to show.
         * The `closeDialog` callback is called by the component when the user clicks the main button,
         * and is passed the optional link to open.
         */}
        {featureDialog && <featureDialog.Component closeDialog={handleClose} />}
      </$.div>
    </Dialog>
  );
}
