import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const scrollToTopSettings = {
  disableNext: false,
};

export default function useAutoScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    if (!scrollToTopSettings.disableNext) window.scrollTo(0, 0);
    scrollToTopSettings.disableNext = false;
  }, [location.pathname]);
}
