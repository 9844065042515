export function useResetGlobalFilters() {
  return (...whichKeys: string[]) => {
    for (let i = sessionStorage.length - 1; i >= 0; i--) {
      const key = sessionStorage.key(i);
      if (
        key?.startsWith('global-filter:') &&
        (!whichKeys?.length || whichKeys.includes('global-filter:' + key))
      )
        sessionStorage.removeItem(key);
    }
  };
}
