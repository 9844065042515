import $ from '@stylix/core';
import React, { useEffect, useState } from 'react';

import OrgSwitcherWarning from 'src/Admin/OrgSwitcher/OrgSwitcherWarning';
import PlatformStatusBanner from 'src/Admin/Platform/PlatformStatusBanner';
import PlatformStatusLoginDisabled from 'src/Admin/Platform/PlatformStatusLoginDisabled';
import { NewFeatureDialog } from 'src/Dashboard/NewFeatureDialog/NewFeatureDialog';
import { canDebug, useDebugUser } from 'src/data/debugUser';
import { isOktaEnv } from 'src/data/oktaAuth';
import { trpc } from 'src/data/trpc';
import { useCurrentUser } from 'src/data/user/useCurrentUser';
import ScrollToTopButton from 'src/ui/ScrollToTopButton';
import useAutoScrollToTop from 'src/ui/useAutoScrollToTop';

import AttestationModal from './AttestationModal';
import EULA from './eula/EULA';
import Footer from './Footer';
import LoginLoader, { type LoginLoaderError } from './LoginLoader';
import LogoutTimer from './LogoutTimer';
import DevMenu from './navBar/DevMenu';
import NavBar from './navBar/NavBar';
import Routes from './Routes';
import { useAppMarginState } from './useAppMargin';

export default function SecureApp() {
  const [userError, setUserError] = useState<LoginLoaderError>();

  const [appMargin] = useAppMarginState();

  const platformStatus = trpc.getPlatformStatus.useQuery();

  const disableUserLoad = platformStatus.isError;
  const currentUser = useCurrentUser(disableUserLoad);

  // true means login is disabled, false means login is not disabled, 'undefined' means we don't know yet (data is loading)
  let disableLogin: boolean | undefined = undefined;
  if (platformStatus.isError) {
    // Disable login on platform status load error
    disableLogin = true;
  } else if (!!platformStatus.data?.status?.disable_login && !currentUser.internal) {
    // Disable login if disable_login flag is true, and current user is not internal
    disableLogin = true;
  } else if (!platformStatus.isFetching) {
    // Enable login if user & platform status data is loaded
    disableLogin = false;
  }

  const { resetDebugRoles } = useDebugUser();

  useEffect(() => {
    // Reset the debug roles to the user's server roles when the profile loads
    if (currentUser.profile) {
      resetDebugRoles(currentUser.serverRoles);
    }
  }, [currentUser.profile]);

  useEffect(() => {
    if (currentUser.error) {
      setUserError({
        message: currentUser.error.message,
        data: (currentUser.error.data as any)?.debug,
      });
    }
  }, [currentUser.error]);

  useAutoScrollToTop();

  if (disableLogin === true) {
    return (
      <>
        <PlatformStatusLoginDisabled
          type={platformStatus.isError ? 'error' : 'warning'}
          message={
            platformStatus.data?.status?.message || (
              <>
                Sorry, an unexpected error occurred.
                <br />
                Please try again or contact a system administrator.
                <$.div fontSize={16} opacity={0.5} mt={20}>
                  {platformStatus.error?.message}
                </$.div>
              </>
            )
          }
        />
        {canDebug() && <DevMenu position="absolute" top={30} right={20} />}
      </>
    );
  }

  if (disableLogin === undefined || !currentUser.profile) {
    return (
      <>
        <LoginLoader error={userError} />
        {canDebug() && <DevMenu position="absolute" top={30} right={20} />}
      </>
    );
  }

  return (
    <$.flex flex-center column align="stretch" minHeight="100vh">
      {isOktaEnv() && <LogoutTimer />}
      <PlatformStatusBanner />
      <NavBar />
      <$.flex column justify="flex-start" align="stretch" margin={appMargin} flex="1 1 auto">
        <Routes />
      </$.flex>
      <Footer zIndex={10} />
      <ScrollToTopButton zIndex={11} />
      <AttestationModal />
      <EULA />
      <OrgSwitcherWarning />
      <NewFeatureDialog />
    </$.flex>
  );
}
