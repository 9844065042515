import type express from 'express';
import { type Knex } from 'knex';

import { type ClqRequestUser } from 'api_measure/lib/auth';
import { type UserRow } from 'api_measure/web/routes/users/lib/users.types';
import { type TrpcMeta } from 'api_measure/web/trpcApp';

import 'lodash-es';

declare module 'lodash-es' {
  export function thru<T, R>(value: T, interceptor: (value: T) => R): R;
}

declare module 'knex' {
  interface Knex {
    // The user-specific Redshift Knex connection object has an added property
    // 'req' that references the express Request object.
    // Assigned in app/db/userDb.ts
    req?: express.Request;
  }
}

// Define 'db' property on express.Request object
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Express {
    export interface Request {
      // Assigned in app/lib/requestLogger
      originalPath: string;
      uuid: string;
      startTime: number;

      // Assigned in web/trpcMiddleware.ts
      trpcMeta?: TrpcMeta;

      /**
       * A user-specific database connection, used for querying Redshift org-security views that
       * limit the available data to the current user.
       * Assigned in app/db/userDb.ts
       */
      db: Knex<any, any[]>;

      /**
       * Indicates that the request is an anonymous internal request, and not from a user.
       * If this is true, no `req.requestUser` or `req.currentUser` will be available, and you
       * cannot use the `req.db` connection.
       * Assigned in app/lib/auth.ts.
       */
      isAnonymousInternalRequest: boolean;

      /**
       * Indicates that the request is internal; i.e. either an anonymous internal request (same as
       * isAnonymousInternalRequest) OR that the current user is an internal user.
       * Assigned in app/lib/auth.ts.
       */
      isInternalRequest: boolean;

      /**
       * The email address and auth headers for the request.
       */
      requestUser: ClqRequestUser;

      /**
       * The current user object from the RDS shared.user table. This object will have org switcher
       * and local debug overrides applied.
       */
      currentUser: UserRow;

      /**
       * This is the current user row directly from the db. This will not contain org switcher or
       * local debug overrides, so don't use this unless you mean it.
       */
      currentUserRaw: UserRow;
      requestIP: string;
    }
  }
}
