import $, { type StylixProps } from '@stylix/core';
import { useCombobox } from 'downshift';
import { get, omit } from 'lodash-es';
import * as React from 'react';
import { forwardRef, useEffect, useState } from 'react';
import { Controller, useFormState, type ControllerProps } from 'react-hook-form';
import { FixedSizeList } from 'react-window';

import track from 'src/data/mixpanel';
import DropdownWrapper from 'src/ui/Dropdown/ui/DropdownWrapper';
import Spinner from 'src/ui/Spinner';
import { useKeckField } from 'src/util/keck-forms';

import ClearButton from './ui/ClearButton';
import DownArrow from './ui/DownArrow';
import MenuItem from './ui/MenuItem';
import MenuWrapper from './ui/MenuWrapper';

export type DropdownProps<TOption> = {
  options: readonly TOption[] | undefined;
  optionsDeps?: any[];
  getOptionValue?: (option: TOption) => Primitive;
  renderOption?: (option: TOption) => React.ReactNode;
  renderSelectedOption?: (option: TOption) => React.ReactNode;
  getSearchValue?: (option: TOption) => string;
  isOptionDisabled?: (option: TOption) => boolean;
  /**
   * The size of each item in the dropdown menu. Defaults to 36.
   * Pass null to make the height variable based on the content (not recommended for large lists).
   */
  itemSize?: number | null;
  getMenuItemProps?: (props: { item: TOption; index: number }) => Record<string, any>;
  /**
   * This is what is rendered when `value` is undefined, or when `value` is null and not one of the options
   * (i.e. if `clearValue` is null but not one of the options, it will not be passed to renderOption
   * or renderSelectedOption)
   */
  placeholder?: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  noOptionsLabel?: React.ReactNode;
  value: TOption | null | undefined;
  onChange: (option: TOption) => void;
  /**
   * If set, the dropdown will be clearable with an "X" button. This value will be passed to
   * onChange when the "X" button is clicked.
   * If unset, the dropdown will not be clearable.
   */
  clearValue?: TOption | null;
  trackingLabel?: string;
  trackingContext?: string;
};

type Primitive = string | number | boolean | symbol | null | undefined;
const isPrimitive = (value: any): value is Primitive => {
  return value === null || (typeof value !== 'function' && typeof value !== 'object');
};

export function Dropdown<TOption>(props: StylixProps<'div', DropdownProps<TOption>>) {
  const {
    options,
    optionsDeps,
    value,
    onChange,
    getOptionValue = (option) => (isPrimitive(option) ? option : option?.toString() || ''),
    renderOption = (option) => option?.toString() || '',
    renderSelectedOption = renderOption,
    getSearchValue = (option) => renderOption(option)?.toString?.() || '',
    isOptionDisabled = () => false,
    itemSize = 36,
    getMenuItemProps,
    placeholder = 'Select...',
    isLoading = false,
    isDisabled = false,
    hasError = false,
    noOptionsLabel = 'No options',
    clearValue,
    onBlur,
    tabIndex,
    trackingLabel,
    trackingContext,
    ...other
  } = props;

  const isOptionVisible = (option: TOption, inputValue: string | undefined) => {
    return !inputValue || getSearchValue(option).toLowerCase().includes(inputValue.toLowerCase());
  };

  const [filteredOptions, setFilteredOptions] = useState<readonly TOption[]>(options || []);

  useEffect(() => {
    setFilteredOptions(
      options?.filter((option) => option !== undefined && isOptionVisible(option, inputValue)) ||
        [],
    );
  }, [options?.length, ...(optionsDeps || [])]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    selectedItem,
    inputValue,
    highlightedIndex,
    selectItem,
    toggleMenu,
  } = useCombobox<TOption | null>({
    onInputValueChange({ inputValue }) {
      setFilteredOptions(
        options?.filter((option) => option !== undefined && isOptionVisible(option, inputValue)) ||
          [],
      );
    },
    items: filteredOptions as TOption[],
    itemToString(item) {
      return item ? getSearchValue(item) : '';
    },
    selectedItem: value ?? null,
    onSelectedItemChange({ selectedItem }) {
      if (trackingLabel)
        track(
          'Dropdown',
          {
            Label: trackingLabel,
            Context: trackingContext,
            Value: selectedItem ? renderOption(selectedItem)?.toString() : undefined,
            InternalValue: selectedItem ? getOptionValue(selectedItem)?.toString() : undefined,
          },
          { send_immediately: true },
        );
      onChange(selectedItem!);
    },
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          return { ...changes, highlightedIndex: 0 };

        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        case useCombobox.stateChangeTypes.FunctionSelectItem:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputBlur:
          return { ...changes, inputValue: '' };

        case useCombobox.stateChangeTypes.InputFocus:
          return { ...changes, isOpen: false };

        default:
          return changes;
      }
    },
  });

  const [controlRef, setControlRef] = useState<HTMLElement | null>(null);

  const menuProps = getMenuProps();
  const toggleButtonProps = getToggleButtonProps();
  const inputProps = getInputProps();

  const isDefaultSelected =
    (!value && !clearValue) ||
    (value && clearValue && getOptionValue(value) === getOptionValue(clearValue));

  // Indicates that the current value is one of the options.
  // The value can be `null` even when it's not one of the options, due to it being controlled
  // by the parent component, or set by the `clearValue`. In this case, we don't want to pass null
  // to `renderOption` or `renderSelectedOption` because it would be an unexpected type.
  const selectedItemIsOption = selectedItem !== null || !!options?.includes(null as TOption);

  return (
    <>
      <DropdownWrapper
        isOpen={isOpen}
        isDisabled={isDisabled || isLoading}
        hasError={hasError}
        onClick={toggleMenu}
        ref={setControlRef}
        onBlur={(e) => {
          // There is no way to accurately determine when the component loses focus because the inner input will lose focus
          // even when, for example, the user clicks the dropdown arrow (even though the input still has focus). This timeout
          // is a hack to ensure that the component loses focus only when the user clicks outside of the component.
          setTimeout(() => {
            const focus = document.querySelector(':focus');
            if (!focus || !controlRef?.contains(focus)) {
              props.onBlur?.(e);
            }
          }, 1);
        }}
        tabIndex={-1}
        {...other}
      >
        <$.flex
          flex-center
          flex="1 1 auto"
          relative
          justifyContent="flex-start"
          pl={10}
          overflow="hidden"
        >
          <$.input
            absolute
            pl={10}
            left={0}
            right={0}
            top="50%"
            transform="translateY(-50%)"
            border={0}
            background="transparent"
            font="inherit"
            cursor="pointer"
            tabIndex={tabIndex || 0}
            {...inputProps}
            ref={inputProps.ref}
          />
          {!inputValue ? (
            <$.div ellipsis>
              {selectedItemIsOption ? (
                renderSelectedOption(selectedItem as any)
              ) : (
                <$.div color="#0008">{placeholder}</$.div>
              )}
            </$.div>
          ) : null}
        </$.flex>

        {!isDisabled &&
        !isLoading &&
        clearValue !== undefined &&
        selectedItemIsOption &&
        !isDefaultSelected ? (
          <ClearButton flex="0 0 auto" onClick={() => selectItem(clearValue)} />
        ) : null}

        {isLoading ? (
          <$.flex flex-center flex="0 0 auto" width={40}>
            <Spinner flex="0 0 auto" size={20} color="#0009" />
          </$.flex>
        ) : null}

        <DownArrow flex="0 0 auto" {...toggleButtonProps} />
      </DropdownWrapper>

      <MenuWrapper
        menuProps={menuProps}
        controlElement={controlRef}
        isOpen={isOpen}
        overflow="hidden"
        height={
          itemSize !== null && filteredOptions.length
            ? Math.min(filteredOptions.length * itemSize + 10, 300)
            : undefined
        }
      >
        {(height) => {
          if (!filteredOptions.length) {
            // Render the "no options" menu item
            return (
              <$.div p="5px 0">
                <MenuItem
                  isHighlighted={false}
                  isSelected={false}
                  fontStyle="italic"
                  isDisabled
                  p="10px"
                >
                  {noOptionsLabel}
                </MenuItem>
              </$.div>
            );
          } else if (itemSize !== null) {
            // Render a fixed sized window list if itemSize is not null
            return (
              <FixedSizeList
                height={height}
                width="auto"
                itemSize={itemSize}
                itemCount={filteredOptions.length}
                innerElementType={forwardRef(function DropdownElement({ style, ...rest }, ref) {
                  return (
                    <div
                      ref={ref}
                      style={{ ...style, height: `${parseFloat(style.height) + 5 * 2}px` }}
                      {...rest}
                    />
                  );
                })}
              >
                {({ style, index }) => {
                  const option = filteredOptions[index];
                  return (
                    <MenuItem
                      key={`${getOptionValue(option!)?.toString()}-${index}`}
                      isHighlighted={highlightedIndex === index}
                      isSelected={
                        selectedItemIsOption &&
                        getOptionValue(selectedItem as TOption) === getOptionValue(option!)
                      }
                      isDisabled={isOptionDisabled(option!)}
                      {...(getItemProps({ item: option, index }) as Record<string, any>)}
                      style={{ ...style, top: `${+style.top! + 5}px` }}
                    >
                      {renderOption(option!)}
                    </MenuItem>
                  );
                }}
              </FixedSizeList>
            );
          } else {
            // Render a regular list of menu items if itemSize is null
            return (
              <$.div max-height={height} overflow-y="scroll" p="5px 0">
                {filteredOptions.map((option, index) => (
                  <MenuItem
                    key={`${getOptionValue(option!)?.toString()}-${index}`}
                    isHighlighted={highlightedIndex === index}
                    isSelected={
                      selectedItemIsOption &&
                      getOptionValue(selectedItem as TOption) === getOptionValue(option!)
                    }
                    isDisabled={isOptionDisabled(option!)}
                    line-height={1.5}
                    pt={8}
                    pb={8}
                    {...getMenuItemProps?.({ item: option, index })}
                    {...(getItemProps({ item: option, index }) as Record<string, any>)}
                  >
                    {renderOption(option!)}
                  </MenuItem>
                ))}
              </$.div>
            );
          }
        }}
      </MenuWrapper>
    </>
  );
}

export function DropdownPortal() {
  return <div id="DropdownPortal" />;
}

type RHFDropdownProps<OptionType> = Omit<DropdownProps<OptionType>, 'value' | 'onChange'> & {
  field: string;
  controllerProps?: Partial<ControllerProps<any, any>>;
  onChange?: DropdownProps<OptionType>['onChange'];
  deps?: any[];
};

export function RHFDropdown<OptionType>(props: StylixProps<'div', RHFDropdownProps<OptionType>>) {
  const { field, controllerProps, deps, ...other } = props;
  const formState = useFormState({ name: field });

  return (
    <Controller
      name={field}
      rules={{ deps }}
      {...controllerProps}
      render={(f) => {
        return (
          <Dropdown<OptionType>
            {...omit(f.field, 'ref')}
            value={f.field.value ?? null} // don't allow undefined
            hasError={!!get(formState.errors, field)}
            {...(other as any)}
            onChange={(value) => {
              f.field.onChange(value);
              props.onChange?.(value);
            }}
            onBlur={(e) => {
              f.field.onBlur();
              props.onBlur?.(e);
            }}
          />
        );
      }}
    />
  );
}

type VFDropdownProps<OptionType> = Omit<DropdownProps<OptionType>, 'value' | 'onChange'> & {
  field: string;
  onChange?: DropdownProps<OptionType>['onChange'];
};

export function KDropdown<OptionType>(props: StylixProps<'div', VFDropdownProps<OptionType>>) {
  const { field, onBlur, onChange, ...other } = props;
  const f = useKeckField<OptionType[]>(field, { transform: 'raw', onBlur, onChange });

  return (
    <Dropdown<OptionType>
      hasError={!!f.isTouched && !!f.isError}
      {...(other as any)}
      {...f.props}
    />
  );
}
