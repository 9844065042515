import 'normalize.css';
import 'react-day-picker/dist/style.css';
import '../fonts/helvetica-neue.css';
import './App.css';
import 'api_measure/global.d';

import { StylixProvider } from '@stylix/core';
import tinyprops from '@stylix/tinyprops';
import { QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as zod from 'zod';

import megaJson from 'api_measure/lib/megaJson';
import tryJSONParse from 'api_measure/lib/tryJSONParse';

import { MixpanelNavigationTracker } from 'src/data/mixpanel';
import { OktaAuthWrap, SecureRoute } from 'src/data/oktaAuth';
import { queryClient } from 'src/data/react-query';
import { trpc, trpcClient } from 'src/data/trpc';
import { UseConfirmModalRenderer } from 'src/ui/ConfirmModal';
import { ContextMenuRenderer } from 'src/ui/ContextMenu';
import { LoadingModal } from 'src/ui/LoadingModal';
import { SkeletonGlobalCss } from 'src/ui/Skeleton';
import useLoadingModal from 'src/ui/useLoadingModal';
import { customStylixProps } from 'src/util/stylix';

import config from '~/config';

import OktaLoginRedirect from './OktaLoginRedirect';
import SecureApp from './SecureApp';

(window as any).zod = zod;
(window as any).dayjs = dayjs;

/**
 * The following is a transitionary process to convert old users with localStorage and sessionStorage values that used superjson.
 * This can be removed after a few weeks.
 */
function updateSuperjsonStorage() {
  const convertLocalKeys = [
    'dashboard-visited',
    'qopi-deadline-warning',
    'welcome-popup-visited',
    'measure-details-referrer',
  ];

  const deleteSessionKeys = [
    'dropdown:providers',
    'dropdown:ous',
    `dropdown:sort-providers`,
    'dropdown:sort-measures',
    'global-filter:benchmarks',
    'global-filter:measure-categories',
    'global-filter:measures',
    'global-filter:measure-period',
    'global-filter:ou',
    'global-filter:provider',
  ];

  try {
    for (const key of convertLocalKeys) {
      const value = localStorage.getItem(key);
      const jsonValue = tryJSONParse(value || 'null');
      if (jsonValue?.json) {
        localStorage.setItem(key, megaJson.stringify(jsonValue.json) || 'null');
      }
    }

    for (const key of deleteSessionKeys) {
      const value = sessionStorage.getItem(key);
      const jsonValue = tryJSONParse(value || 'null');
      if (jsonValue?.json) {
        sessionStorage.removeItem(key);
      }
    }
  } catch (e) {
    // We absolute do not want this to throw exceptions because there is no way to recover.
    // As a fallback, we'll just delete all the keys, and continue ignoring errors
    try {
      for (const key of convertLocalKeys) localStorage.removeItem(key);
      for (const key of deleteSessionKeys) sessionStorage.removeItem(key);
    } catch (e2) {}
  }
}
updateSuperjsonStorage();

export default function App() {
  const { loadingModal } = useLoadingModal();

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <BrowserRouter>
        <StylixProvider devMode={config.stylixDevMode} plugins={[tinyprops, customStylixProps]}>
          <OktaAuthWrap>
            <QueryClientProvider client={queryClient}>
              <SkeletonGlobalCss />
              <Switch>
                <Route exact path="/implicit/callback" component={OktaLoginRedirect} />
                <SecureRoute path="/" component={SecureApp} />
              </Switch>
              <ContextMenuRenderer />
              <MixpanelNavigationTracker />
              <LoadingModal isOpen={loadingModal.isOpen} text={loadingModal.message} />
              <UseConfirmModalRenderer />
            </QueryClientProvider>
          </OktaAuthWrap>
        </StylixProvider>
      </BrowserRouter>
    </trpc.Provider>
  );
}
