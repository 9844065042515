import { Intent, Position, Toaster, type IToastProps } from '@blueprintjs/core';
import $ from '@stylix/core';

const toaster = Toaster.create(
  { position: Position.TOP, maxToasts: 3 },
  document.getElementById('toast-container')!,
);

const AppToaster = {
  success: (message: React.ReactNode, props?: Partial<IToastProps>) => {
    return toaster!.show({ intent: Intent.SUCCESS, icon: 'tick', message, ...props });
  },
  error: (title, message?, props?: Partial<IToastProps>) => {
    return toaster!.show({
      className: 'data-test-toaster-error',
      intent: Intent.DANGER,
      icon: 'warning-sign',
      message: (
        <$.div>
          {title}
          {message && (
            <$.div whiteSpace="pre-wrap" size={12} maxHeight={150} mt={20} overflowY="auto">
              {message}
            </$.div>
          )}
        </$.div>
      ),
      ...props,
    });
  },
  warning: (message, props?: Partial<IToastProps>) => {
    return toaster!.show({ intent: Intent.WARNING, icon: 'warning-sign', message, ...props });
  },
  dismiss: (key: string) => toaster!.dismiss(key),
};

export default AppToaster;
