import type React from 'react';

import { hasRoles, type Role } from 'api_measure/lib/roles';

import { useCurrentUser } from 'src/data/user/useCurrentUser';

interface RequireRolesProps {
  any?: Role | Role[]; // Content will render if user has ANY of these roles
  all?: Role | Role[]; // Content will render only if user has ALL of these roles
  exclude?: Role | Role[]; // Content will NOT render only if user has ANY of these roles
  internalOnly?: boolean; // Content will render only if user has 'internal' flag true
  children: React.ReactNode | React.ReactNode[] | ((roles: Role[]) => any);
}

export default function RequireRoles(props: RequireRolesProps) {
  let { any, all, exclude } = props;
  const { internalOnly } = props;

  let allowed = true;
  const { roles, internal } = useCurrentUser();

  if (internalOnly) allowed = allowed && internal;

  if (any && !Array.isArray(any)) any = [any];
  if (all && !Array.isArray(all)) all = [all];
  if (exclude && !Array.isArray(exclude)) exclude = [exclude];

  if (any) allowed = allowed && hasRoles(roles, ...any);
  if (all) allowed = allowed && all.every((role) => hasRoles(roles, role));
  if (exclude) allowed = allowed && !hasRoles(roles, ...exclude);

  if (!allowed) return null;

  return typeof props.children === 'function' ? props.children(roles) : props.children;
}
