import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import * as React from 'react';

import Icon from 'src/ui/Icon';

interface DownArrowProps {
  onClick(e: React.MouseEvent<HTMLButtonElement>): void;
}

export default React.forwardRef<HTMLDivElement, StylixProps<'div', DownArrowProps>>(
  function DownArrow(props, ref) {
    const { onClick, ...other } = props;
    return (
      <$.flex flex-center {...other} ref={ref}>
        <$.button
          type="button"
          flex-center
          border="none"
          bg="none"
          borderLeft="1px solid #0003"
          width={34}
          height={24}
          tabIndex={-1}
          onClick={(e) => {
            e.stopPropagation();
            onClick(e);
          }}
        >
          <Icon icon={faChevronDown} color="#0005" size={12} />
        </$.button>
      </$.flex>
    );
  },
);
