import { type Dayjs } from 'dayjs';

import { hasRoles, Role } from 'api_measure/lib/roles';
import { type UserSettings } from 'api_measure/web/routes/users/updateUserSettings';

export interface OrgSwitcher {
  org: string;
  ous: number[];
  roles: Role[];
}

/**
 * User's Okta status.
 * See https://developer.okta.com/docs/reference/api/users/#user-status for more info.
 */
export enum UserOktaStatus {
  active = 'ACTIVE', // Normal user status; icon = none
  deprovisioned = 'DEPROVISIONED', // User has been de-provisioned; icon = circle slash
  lockedOut = 'LOCKED_OUT', // User has locked themselves out by entering incorrect password; icon = lock
  passwordExpired = 'PASSWORD_EXPIRED', // Password is expired; icon = asterisk / key
  provisioned = 'PROVISIONED', // User has received activation email but password not set; icon = letter
  recovery = 'RECOVERY', // User has been sent password recovery email; icon = asterisk / key
  staged = 'STAGED', // User is created but not activated; icon = clock
  suspended = 'SUSPENDED', // User has been suspended; icon = User X
}

// User object as it appears in the database
export interface UserRow {
  id: number;
  org_owner: string; // real org membership (never changes)
  org_assignment: string; // current org assignment; may be changed by org switcher
  national_provider_id: string;
  okta_user_id: string;
  status: UserOktaStatus;
  email: string;
  first_name: string;
  last_name: string;
  created_at: Dayjs;
  updated_at: Dayjs;
  roles: Role[];
  ous: number[];
  settings: UserSettings;
  org_switcher: OrgSwitcher | null;
  internal: boolean;

  /**
   * The last time the user logged in. Since login is handled by Okta and there is not actually any API that
   * is called during login, this is updated in the getMe() API call.
   */
  last_login: Dayjs | null;

  /**
   * The previous time the user logged in. This is also updated in the getMe() API call.
   * This is needed because last_login is immediately updated to the current time as soon as the user logs in,
   * so we copy the value of last_login to previous_login before updating last_login.
   */
  previous_login: Dayjs | null;
}

export const userMinimalRefFields = ['id', 'email', 'first_name', 'last_name'] as const;
export type UserMinimalRef = Pick<UserRow, (typeof userMinimalRefFields)[number]>;

export function isUserProvider(user: UserRow) {
  return hasRoles(user.roles, Role.provider) && !!user.national_provider_id;
}

export enum NewFeatureDialogType {
  fdaApprovals = 'fda_approvals',
  biosimilarsReport = 'biosimilars_report',
  triaLinQ = 'trialinq',
  rxlinqUpdates = 'rxlinq_updates',
}

export enum UserStatusAction {
  Activate = 'activate',
  Deactivate = 'deactivate',
  Unlock = 'unlock',
  ResetPassword = 'reset_password',
}
