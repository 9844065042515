import createGlobalState from 'src/ui/createGlobalState';

/**
 * Global state that indicates whether the current page load came from the login redirect
 * (OktaLoginRedirect component).
 */

const useIsLoginSessionState = createGlobalState(false);

/**
 * This is the setter function used by OktaLoginRedirect (you probably don't need to use this).
 */
export const useIsLoginSessionSetter = () => useIsLoginSessionState()[1];

export const useIsLoginSession = () => useIsLoginSessionState()[0];
