import { Dialog as BPDialog, type DialogProps as BPDialogProps } from '@blueprintjs/core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import { useObserver } from 'keck';
import React, { useEffect } from 'react';

import track, { timeEvent } from 'src/data/mixpanel';

import Button from './Button';

export interface DialogProps extends BPDialogProps {
  maxWidth?: number;
  modalWrapperProps?: any;
  trackingLabel?: string;
  trackingDetail?: any;
}

export type DialogControl<TExtra> = TExtra & {
  isOpen: boolean;
  open(extra: TExtra): void;
  close(): void;
};

export function useDialogControl<TExtra>(
  initialOpen = false,
  extra?: TExtra,
): DialogControl<TExtra> {
  return useObserver({
    ...extra,
    isOpen: initialOpen,
    open(extra: TExtra) {
      this.isOpen = true;
      Object.assign(this, extra);
    },
    close() {
      this.isOpen = false;
    },
  } as DialogControl<TExtra>);
}

export default function Dialog(props: StylixProps<typeof BPDialog, DialogProps>) {
  const {
    maxWidth,
    trackingLabel,
    trackingDetail,
    children,
    isOpen,
    onClose,
    modalWrapperProps = {},
    transitionDuration, // From blueprint Dialog props; conflicts with css prop
    ...other
  } = props;

  useEffect(() => {
    if (!trackingLabel) return;
    if (isOpen) {
      track('Modal:Open', { Label: trackingLabel, Detail: trackingDetail });
      timeEvent('Modal:Close');
    }
  }, [isOpen]);

  const handleClose = (e: any) => {
    if (trackingLabel) {
      track('Modal:Close', { Label: trackingLabel, Detail: trackingDetail });
    }
    onClose?.(e);
  };

  return (
    <$
      $el={<BPDialog transitionDuration={transitionDuration} />}
      isOpen={isOpen}
      p={0}
      {...other}
      onClose={handleClose}
    >
      <$.flex
        flex-center
        relative
        column
        alignItems="stretch"
        maxWidth={maxWidth}
        {...modalWrapperProps}
        onClick={(e) => {
          // Prevents clicks from taking action on anything behind the modal. This can happen if
          // the modal is triggered by an element (like a button) inside another element that has a
          // click handler (like a table row).
          e.stopPropagation();
          modalWrapperProps?.onClick?.(e);
        }}
      >
        {onClose && (
          <Button
            variant="round-ghost"
            absolute
            top={10}
            right={15}
            type="button"
            onClick={handleClose}
            faIcon={faTimes}
          />
        )}

        {children}
      </$.flex>
    </$>
  );
}
