import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { hasRoles, Role } from 'api_measure/lib/roles';

import { useOrgSwitcherAccepted } from 'src/Admin/OrgSwitcher/OrgSwitcherWarning';
import CodeSplitComponent from 'src/App/CodeSplitComponent';
import PageForbidden from 'src/App/PageForbidden';
import PageNotFound from 'src/App/PageNotFound';
import { useCurrentUser } from 'src/data/user/useCurrentUser';

type CodeSplitComponentData =
  | {
      path: string;
      exact?: boolean;
      redirect?: never;
      userIsPermitted?(roles: Role[], internal: boolean): boolean;
      componentLoader(): Promise<any>;
    }
  | {
      path: string;
      exact?: boolean;
      redirect: string;
      userIsPermitted?: never;
      componentLoader?: never;
    };

const codeSplitComponents: CodeSplitComponentData[] = [
  // This is where Okta sends the user after logging out. It's meaningless (Okta just needs to send the user somewhere)
  // so we just redirect back to the root path which sends them to the login page.
  // TODO: We should either implement a "you have been logged out" message page, or just update Okta to send the user to the
  //  root path directly and remove this.
  { path: '/logout', redirect: '/' },

  // This is an old landing page path that some users may still have bookmarked
  { path: '/home', redirect: '/' },

  {
    path: '/my-org',
    userIsPermitted: (roles) => hasRoles(roles, Role.orgManager),
    componentLoader: () => import('src/Admin/OrgMgmt/Org/OrgPage'),
  },
  {
    path: '/admin',
    userIsPermitted: (_roles, internal) => internal,
    componentLoader: () => import('src/Admin/Admin'),
  },

  {
    path: '/measures',
    userIsPermitted: (roles) => hasRoles(roles, Role.orgAdmin, Role.provider),
    componentLoader: () => import('src/Measures/MeasuresRoutes'),
  },
  {
    path: '/patient/actionable/:bucket1?/:bucket2?',
    userIsPermitted: (roles) => hasRoles(roles, Role.orgAdmin, Role.provider),
    componentLoader: () => import('src/Patients/Actionable/ActionablePatients'),
  },
  {
    path: '/patient/journey',
    userIsPermitted: (roles) => hasRoles(roles, Role.patientJourney),
    componentLoader: () => import('src/Patients/PatientsList/PatientJourneyListPage'),
  },
  {
    path: '/patient/count-me-in',
    userIsPermitted: (roles) => hasRoles(roles, Role.orgAdmin, Role.provider),
    componentLoader: () => import('src/CountMeIn/CountMeIn'),
  },
  {
    path: '/patient',
    userIsPermitted: (roles) => hasRoles(roles, Role.orgAdmin, Role.provider),
    componentLoader: () => import('src/Patients/Patients'),
  },
  {
    path: '/qopi',
    userIsPermitted: (roles) => hasRoles(roles, Role.orgAdmin, Role.provider),
    componentLoader: () => import('src/Qcp/Qcp'),
  },
  {
    path: '/reports',
    userIsPermitted: (roles) => hasRoles(roles, Role.orgAdmin, Role.provider, Role.trialinq),
    componentLoader: () => import('src/Looker/Looker'),
  },
  {
    path: '/providers',
    userIsPermitted: (roles) => hasRoles(roles, Role.orgAdmin, Role.provider),
    componentLoader: () => import('src/Providers/ProvidersRoutes'),
  },
  {
    path: '/asco-certified',
    userIsPermitted: (roles) => hasRoles(roles, Role.orgAdmin, Role.provider),
    componentLoader: () => import('src/AscoCertified/AscoCertified'),
  },
];

/**
 * Default routes that the user will be redirected to when they visit the root path (https://cancerlinq.org/).
 * Org admins and providers are allowed to visit the root path (the SmartLinQ dashboard) so they use explicit logic
 * in the Routes below.
 * Otherwise, user will be directed to the first route that matches at least one of the user's roles.
 * Note that the user will see a page forbidden error message if they have no matching roles.
 */
const defaultRoutes = [
  {
    internalOnly: true,
    roles: [Role.dilOperator],
    path: '/admin/dil',
  },
  {
    internalOnly: true,
    path: '/admin/orgs',
  },
  {
    roles: [Role.orgManager],
    path: '/my-org',
  },
];

export default function Routes() {
  const { roles, internal } = useCurrentUser();

  const [orgSwitcherAccepted] = useOrgSwitcherAccepted();

  if (!orgSwitcherAccepted) return null;

  return (
    <Switch>
      {codeSplitComponents.map((componentData) => (
        <Route
          key={componentData.path}
          path={componentData.path}
          exact={componentData.exact}
          render={() => {
            if (componentData.redirect) return <Redirect to={componentData.redirect} />;
            if (!componentData.userIsPermitted || componentData.userIsPermitted(roles, internal)) {
              return <CodeSplitComponent componentLoader={componentData.componentLoader} />;
            } else return <PageForbidden />;
          }}
        />
      ))}
      <Route
        path="/"
        exact
        render={() => {
          // Org admins and providers are allowed to visit the root path (the SmartLinQ dashboard)
          if (hasRoles(roles, Role.orgAdmin, Role.provider, Role.trialinq)) {
            return <CodeSplitComponent componentLoader={() => import('src/Dashboard/Dashboard')} />;
          }

          // If the user has a default route based on their roles, redirect them there.
          const defaultRoute = defaultRoutes.find((route) => {
            if (route.internalOnly && !internal) return false;
            if (route.roles) return hasRoles(roles, ...route.roles);
            return true;
          });
          if (defaultRoute) {
            return <Redirect to={defaultRoute.path} />;
          }

          // Otherwise, show page forbidden.
          return <PageForbidden />;
        }}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
}
