import { faRandom, faUserCog } from '@fortawesome/pro-solid-svg-icons';
import $ from '@stylix/core';
import React, { useLayoutEffect, useState } from 'react';

import { useIsLoginSession } from 'src/data/useIsLoginSession';
import { useCurrentUser } from 'src/data/user/useCurrentUser';
import Button from 'src/ui/Button';
import ConfirmModal from 'src/ui/ConfirmModal';
import createGlobalState from 'src/ui/createGlobalState';
import Icon from 'src/ui/Icon';

import { useEndOrgSwitchMutation } from './data/orgSwitcherQueries';

export const useOrgSwitcherAccepted = createGlobalState(false);

export default function OrgSwitcherWarning() {
  const { profile, userOrg } = useCurrentUser();

  const [orgSwitcherAccepted, setOrgSwitcherAccepted] = useOrgSwitcherAccepted();

  // This is just a local state to control the visibility of the dialog
  const [orgSwitcherRejected, setOrgSwitcherRejected] = useState(false);

  const isLoginSession = useIsLoginSession();

  useLayoutEffect(() => {
    if (!profile.org_switcher || !isLoginSession) setOrgSwitcherAccepted(true);
  }, []);

  const endOrgSwitchMutation = useEndOrgSwitchMutation();

  if (!profile.org_switcher) return null;

  return (
    <ConfirmModal
      isOpen={!orgSwitcherAccepted && !orgSwitcherRejected}
      color="warning"
      icon={faRandom}
      title="Confirm Org Switch"
      maxWidth={600}
      renderButtons={() => (
        <>
          <Button
            variant="ghost"
            onClick={async () => {
              setOrgSwitcherRejected(true);
              await endOrgSwitchMutation.mutateAsync();
            }}
          >
            End Org Switcher Session
          </Button>
          <Button variant="gold" onClick={() => setOrgSwitcherAccepted(true)}>
            Continue
          </Button>
        </>
      )}
    >
      <$.div mb={20}>You are currently org switching to:</$.div>
      <$.div bold mb={20} fontSize={20} alignCenter>
        {userOrg.name} ({userOrg.sender_code})
      </$.div>
      <$.div mb={20} alignLeft>
        Please confirm that you wish to view data for this practice. If you do not wish to continue,
        click <b>End Org Switcher Session</b>, and your account will be returned to its original
        practice assignment and user roles.
      </$.div>

      <$.div mb={30} alignLeft>
        You may also end your org switcher session at any time using the{' '}
        <Icon icon={faUserCog} fontSize={16} m="0 4px" /> icon in the navigation menu.
      </$.div>
    </ConfirmModal>
  );
}
