import { QueryClient, useQueryClient, type UseQueryOptions } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import dayjs from 'dayjs';
import { pickBy, sortBy } from 'lodash-es';
import qs from 'qs';

import type ApiError from 'api_measure/shared/ApiError';

import axios from 'src/data/axios';
import { currentEnv } from 'src/data/env';
import AppToaster from 'src/ui/AppToaster';

export interface AxiosApiError extends AxiosError<{ error: ApiError }> {}

async function defaultQueryFn({ queryKey }: any) {
  const [url, query, method = 'GET'] = queryKey;
  const serializedQuery = !!query && method === 'GET' && serializeQueryString(query);
  const { data } = await axios.request({
    url:
      url +
      (serializedQuery && Object.keys(serializedQuery).length
        ? '?' + qs.stringify(serializedQuery)
        : ''),
    method,
    data: method !== 'GET' ? query : undefined,
  });
  return data;
}

export const defaultErrorHandler = (e: any) => {
  if (typeof e === 'string') {
    AppToaster.error(e);
  } else if (e.name === 'TRPCClientError') {
    const debug = (e.data as any)?.debug;
    const debugStr = typeof debug === 'string' ? debug : debug && JSON.stringify(debug, null, 2);
    AppToaster.error(e.message, currentEnv().universe !== 'prod' ? debugStr : undefined);
  } else {
    AppToaster.error(
      e?.response?.data?.error?.message ||
        'Sorry, an error occurred. Please try again or contact a system administrator.',
    );
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000, // 1 hr
      refetchOnWindowFocus: false,
      queryFn: defaultQueryFn,
      retry: (retryCount, err: any) => {
        // Don't retry when status is 4XX API error
        if (err?.response?.status >= 400 && err?.response?.status <= 499) return false;
        return retryCount < 3;
      },
      retryDelay: 2000,
    },
    mutations: {
      onError: defaultErrorHandler,
    },
  },
});

export const buildUrl = (path: string, params?: any) =>
  path + (params ? '?' + qs.stringify(serializeQueryString(params)) : '');

export function serializeQueryString(params: any): any {
  const result = { ...params };
  for (const key in result) {
    if (
      Array.isArray(result[key]) &&
      result[key].filter(Boolean).every((v) => ['string', 'number'].includes(typeof v))
    )
      result[key] = result[key].length ? sortBy(result[key].filter(Boolean)).join(',') : undefined;
    else if (result[key] instanceof Date || dayjs.isDayjs(result[key]))
      result[key] = result[key].toISOString();
    else if (!!result[key] && typeof result[key] === 'object')
      result[key] = JSON.stringify(result[key]);
  }
  return pickBy(result, (val) => val !== undefined);
}

/**
 * Returns a function that invalidates the query for the given queryOpts.
 * queryOpts can either be a UseQueryOptions object or a function that returns one.
 * If the function accepts parameters, they will be required by the returned function.
 */
export function useQueryInvalidator(queryOpts: UseQueryOptions<any>): () => void;
export function useQueryInvalidator(queryOpts: () => UseQueryOptions<any>): () => void;
export function useQueryInvalidator<P>(
  queryOpts: (params: P) => UseQueryOptions<any>,
): (params: P) => void;

export function useQueryInvalidator(queryOpts: any): any {
  const queryClient = useQueryClient();
  return (params: any) => {
    queryClient.invalidateQueries(
      typeof queryOpts === 'function' ? queryOpts(params).queryKey : queryOpts.queryKey,
    );
  };
}
