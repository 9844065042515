import { useCallback } from 'react';

import { hasRoles, roleInfo, type Role } from 'api_measure/lib/roles';
import { type OrgFeature } from 'api_measure/web/routes/practice/practice.shared';

import { useCurrentUser } from 'src/data/user/useCurrentUser';

/**
 * Hook that returns a function that you can use to check the current user's roles.
 */
export function useUserHasRoles() {
  const { roles } = useCurrentUser();

  return useCallback((...requiredRoles: Role[]) => hasRoles(roles, ...requiredRoles), [roles]);
}

export const getRoleLabels = (userRoles: Role[] = []) => {
  return (
    userRoles
      .map((r) => roleInfo[r]?.label)
      .filter(Boolean)
      .join(', ') || '(No Roles)'
  );
};

export function useHasOrgFeature() {
  const { userOrg } = useCurrentUser();
  return (...features: OrgFeature[]) =>
    features.some((feature) => userOrg?.features.includes(feature));
}
