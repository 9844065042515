import type express from 'express';

import ApiError from 'api_measure/shared/ApiError';
import { OrgFeature } from 'api_measure/web/routes/practice/practice.shared';

export enum Role {
  // Persona roles
  orgManager = 'orgManager',
  provider = 'provider',
  orgAdmin = 'orgAdmin',
  trialinq = 'trialinq',

  // Feature roles
  ascoCertified = 'ascoCertified',
  dataReviewer = 'dataReviewer',
  dilOperator = 'dilOperator',
  insightsDataExporter = 'insightsDataExporter',
  insightsRegistry = 'registrar',
  patientJourney = 'patientJourney',
  qcp = 'qcp',
}

export const allRoles = Object.values(Role) as [Role, ...Role[]];

interface RoleInfo {
  /**
   * Human-friendly label for role
   */
  label: string;

  /**
   * Human-friendly description for role
   */
  description: string;

  /**
   * Category for user admin screen grouping
   */
  category: string;

  /**
   * Sort order for user admin screen grouping (within category)
   */
  categorySort: number;

  /**
   * 1-3 letter code for role; used for compact display purposes.
   * When creating a new role, priority should be given for 1-letter codes to roles that
   * are visible to external users.
   */
  shortcode: string;

  /**
   * Whether the role is only assignable to internal or external users.
   * - internal = only assignable to internal users, only assignable by internal users
   * - private = only assignable to external users, only assignable by internal users
   * - public = only assignable to external users, assignable by anyone
   */
  type: 'internal' | 'private' | 'public';

  /**
   * Role can only be assigned if the given roles are also assigned.
   */
  requiredRoles?: Role[];

  requiredOrgFeatures?: OrgFeature[];
}

export const roleInfo: Record<Role, RoleInfo> = {
  // Persona roles
  [Role.orgManager]: {
    label: 'Practice Account Manager',
    description: 'Access to organization details and user management.',
    type: 'private',
    shortcode: 'PM',
    category: 'Persona',
    categorySort: 0,
  },

  [Role.orgAdmin]: {
    label: 'Practice Administrator',
    description:
      'Access to all quality and clinical features for the entire practice patient population.',
    shortcode: 'PA',
    type: 'public',
    category: 'Persona',
    categorySort: 1,
  },

  [Role.provider]: {
    label: 'Provider',
    description: 'Access to quality and patient data for a specific provider.',
    shortcode: 'P',
    type: 'public',
    category: 'Persona',
    categorySort: 2,
  },

  [Role.trialinq]: {
    label: 'TriaLinQ',
    description: 'Access to TriaLinQ.',
    shortcode: 'TL',
    type: 'public',
    category: 'Persona',
    categorySort: 3,
    requiredOrgFeatures: [OrgFeature.TriaLinQ],
  },

  // Feature roles

  [Role.ascoCertified]: {
    label: 'ASCO Certified',
    description: 'Access to the ASCO certified dashboard.',
    type: 'private',
    shortcode: 'AC',
    category: 'Features',
    categorySort: 0,
    requiredRoles: [Role.orgAdmin, Role.provider],
  },

  [Role.dataReviewer]: {
    label: 'Patient Events & Interactions',
    description: 'Access to the patient events and interactions data on the patient viewer.',
    type: 'private',
    shortcode: 'PI',
    category: 'Features',
    categorySort: 1,
    requiredRoles: [Role.orgAdmin],
  },

  [Role.insightsDataExporter]: {
    label: 'Insights Data Export',
    description: 'Ability to export data from Looker Insights dashboards.',
    type: 'public',
    shortcode: 'IX',
    category: 'Features',
    categorySort: 2,
    requiredRoles: [Role.orgAdmin, Role.provider],
  },

  [Role.insightsRegistry]: {
    label: 'Insights Registry Dashboards',
    description: 'Access to additional Looker Insights registry dashboards.',
    type: 'public',
    shortcode: 'IR',
    category: 'Features',
    categorySort: 3,
    requiredRoles: [Role.orgAdmin, Role.provider],
  },

  [Role.patientJourney]: {
    label: 'Patient Journey',
    description: 'Access to patient journey lists and patient details.',
    type: 'private',
    shortcode: 'PJ',
    category: 'Features',
    categorySort: 4,
    requiredRoles: [Role.orgAdmin, Role.provider],
  },

  [Role.qcp]: {
    label: 'QCP Submissions',
    description: 'Ability to perform QCP submissions.',
    shortcode: 'QC',
    type: 'public',
    category: 'Features',
    categorySort: 5,
    requiredRoles: [Role.orgAdmin, Role.provider],
  },

  [Role.dilOperator]: {
    label: 'DIL Operator',
    description: 'Access to DIL management features.',
    shortcode: 'DO',
    type: 'internal',
    category: 'Features',
    categorySort: 6,
  },
};

/**
 * Returns a list of available (allowed or disabled) roles for a given user, based on their current
 * roles, org features, and internal status of the target and admin user.
 *
 * Given a list of current assignee user roles, and a flag indicating whether the admin and target
 * users are internal, returns an array of objects for each role that should be visible (i.e. the
 * role is at least available to the target user, though potentially not assignable)
 * and enabled (the role can actually be assigned).
 */
export function getEligibleRoles(
  targetRoles: Role[],
  targetOrgFeatures: OrgFeature[],
  isTargetInternal: boolean,
  isAdminInternal: boolean,
): { role: Role; enabled: boolean }[] {
  return allRoles
    .map((role) => ({
      role,
      status: getRoleStatus(
        role,
        targetRoles,
        targetOrgFeatures,
        isTargetInternal,
        isAdminInternal,
      ),
    }))
    .filter(({ status }) => status !== RoleStatus.hidden)
    .map(({ role, status }) => ({
      role,
      enabled: status === RoleStatus.allowed,
    }));
}

export enum RoleStatus {
  /**
   * The role is allowed.
   */
  allowed = 'allowed',

  /**
   * The role is visible but not allowed.
   */
  disabled = 'disabled',

  /**
   * The role is hidden.
   */
  hidden = 'hidden',
}

/**
 * Returns the availability (allowed, disabled, hidden) of a role for a target user, based on the
 * user's current roles, org features, and internal status of the target and admin user.
 */
export function getRoleStatus(
  role: Role,
  targetRoles: Role[],
  targetOrgFeatures: OrgFeature[],
  isTargetInternal: boolean,
  isAdminInternal: boolean,
): RoleStatus {
  const info = roleInfo[role];

  // Hidden if role is internal and target user external
  if (info.type === 'internal' && !isTargetInternal) return RoleStatus.hidden;

  // Hidden if role is external and target user is internal
  if (info.type !== 'internal' && isTargetInternal) return RoleStatus.hidden;

  // Hidden if role is private and admin user is not internal
  if (info.type === 'private' && !isAdminInternal) return RoleStatus.hidden;

  // Hidden if role requires org features and target org does not have them
  if (
    info.requiredOrgFeatures &&
    !info.requiredOrgFeatures.every((feature) => targetOrgFeatures?.includes(feature))
  )
    return RoleStatus.hidden;

  if (
    info.requiredRoles?.length &&
    !targetRoles.some((targetRole) => info.requiredRoles!.includes(targetRole))
  )
    return RoleStatus.disabled;

  return RoleStatus.allowed;
}

/**
 * Converts a CLQ role to the Okta group name by adding the universe and ROLE prefixes
 * (e.g. "internalAdmin" -> "dev_ROLE_internalAdmin")
 */
export function addOktaRolePrefix(clqRoleName: string, universe: string): string {
  return `${universe}_ROLE_${clqRoleName}`;
}

/**
 * Converts a CLQ org to the Okta group name by adding the universe and ORG prefixes
 * (e.g. "CLQ" -> "dev_ORG_CLQ")
 */
export function addOktaOrgPrefix(org: string, universe: string): string {
  return `${universe}_ORG_${org}`;
}

/**
 * Removes the universe and type prefix for an Okta group name.
 * (e.g. "dev_ROLE_internalAdmin" -> "internalAdmin")
 * (e.g. "dev_ORG_CLQ" -> "CLQ")
 */
export function removeOktaGroupPrefix(oktaRoleName: string): Role {
  return oktaRoleName.replace(/^.*?_(ROLE|ORG)_/, '') as Role;
}

/**
 * Returns true if `oktaRoleName` has the given universe prefix.
 */
export function isOktaGroupInUniverse(oktaRoleName: string, universe: string): boolean {
  return oktaRoleName.startsWith(`${universe}_`);
}

/**
 * Returns true if `role` is a valid member of the `Role` enum.
 */
export function isRole(role: string): role is Role {
  return allRoles.includes(role as Role);
}

/**
 * Returns true if `userRoles` contains at least one of the given `requiredRoles`.
 */
export function hasRoles(userRoles: Role[], ...requiredRoles: Role[]) {
  return userRoles?.some((role) => requiredRoles?.includes(role));
}

/**
 * Express handler that requires the given roles to access the endpoint.
 * If the user is internal, the check will pass.
 *
 * e.g.
 *   app.use(
 *     '/some/path',
 *     requireRoles(Role.orgAdmin),
 *     (req, res) => {...}
 *   )
 */
export function requireRoles(...roles: Role[]) {
  return function requireRolesMiddleware(
    req: express.Request,
    _res: express.Response,
    next: express.NextFunction,
  ) {
    if (req.isInternalRequest || hasRoles(req.currentUser.roles, ...roles)) next();
    else next(new ApiError('User does not have the necessary roles to access this endpoint.', 403));
  };
}

export function requireInternalUser(allowOrgSwitcher = false) {
  return function requireRolesMiddleware(
    req: express.Request,
    _res: express.Response,
    next: express.NextFunction,
  ) {
    if (req.isInternalRequest) next();
    else if (allowOrgSwitcher && req.currentUser.org_switcher) next();
    else next(new ApiError('User must be internal to access this endpoint.', 403));
  };
}
