import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import * as React from 'react';

import Icon from 'src/ui/Icon';

interface MultiItemProps {
  label: React.ReactNode;
  onRemove?(): void;
}

export default React.forwardRef<HTMLDivElement, StylixProps<'div', MultiItemProps>>(
  function MultiItem(props, ref) {
    const { label, onRemove, ...other } = props;

    return (
      <$.flex
        flex-center
        maxWidth="100%"
        bg="#0001"
        fontSize={13}
        height={20}
        lineHeight="20px"
        borderRadius={10}
        pl={6}
        overflow="hidden"
        ref={ref}
        {...other}
      >
        <$.div flex="1 1 auto" ellipsis pr={onRemove ? 2 : 6}>
          {label}
        </$.div>
        {onRemove && (
          <$.flex
            flex-center
            flex="0 0 auto"
            height={18}
            p="0 4px 0 2px"
            cursor="pointer"
            $css={{ '&:hover': { background: '#0002' } }}
            onClick={(e) => {
              e?.stopPropagation();
              onRemove();
            }}
          >
            <Icon icon={faTimes} size={14} />
          </$.flex>
        )}
      </$.flex>
    );
  },
);
