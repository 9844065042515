import $, { type StylixProps } from '@stylix/core';
import { type History, type To } from 'history';
import React from 'react';
import { useHistory } from 'react-router-dom';

import colors from './colors';

interface LinkProps {
  underline?: boolean;
  to?: To;
  replace?: boolean;
  href?: never;
}

export default function Link(props: StylixProps<'a', LinkProps>) {
  const { underline, to, replace, ...other } = props;

  const onClick = useClickLinkTo({
    linkTo: props.to,
    replace: props.replace,
    onClick: props.onClick as any,
    target: props.target,
  });

  const history = useHistory();
  const href = to ? (typeof to === 'string' ? to : history.createHref(to)) : undefined;

  if (href || props.onClick) {
    return (
      <$.a
        inlineBlock
        color={colors.blue.primary}
        cursor="pointer"
        tabIndex={0}
        href={href}
        {...other}
        onClick={onClick}
        $css={[
          {
            '&:hover': {
              color: colors.blue.primaryHover,
              textDecoration: props.underline && (href || props.onClick) ? 'underline' : 'none',
            },
            '&:focus': {
              outline: `1px dotted ${colors.blue.primary} !important`,
            },
          },
          props.$css,
        ]}
      />
    );
  } else {
    return <$.div inlineBlock {...(other as any)} />;
  }
}

/**
 * Hook that creates an onClick handler that will navigate to the linkTo location if it is provided.
 */
export function useClickLinkTo(
  props: {
    linkTo?: To;
    replace?: boolean;
    target?: string;
    onClick?: (e: React.MouseEvent) => void;
  } = {},
) {
  const { linkTo, onClick } = props;
  const history = useHistory();
  return (e: React.MouseEvent) => {
    onClick?.(e);
    if (linkTo && !e.defaultPrevented) {
      openLinkTo({
        event: e,
        target: props.target || (e.metaKey ? '_blank' : undefined),
        linkTo,
        replace: props.replace,
        history,
      });
    }
  };
}

/**
 * Hook that creates a function that will navigate to a location, optionally in a new window if the ctrl key is pressed.
 */
export function useLinkTo() {
  const history = useHistory();
  return (
    linkTo?: To,
    options: { replace?: boolean; e?: React.MouseEvent; target?: string } = {},
  ) => {
    if (!linkTo) return;
    openLinkTo({
      target: options.target || (options.e?.metaKey ? '_blank' : undefined),
      linkTo,
      replace: options.replace,
      history,
    });
  };
}

/**
 * Navigates to a linkTo location. It will be opened in a new tab if newTab is true or if the event.metaKey is set.
 */
export function openLinkTo(props: {
  event?: React.MouseEvent;
  target?: string; // '_blank' or other window target name
  history: History;
  linkTo: To;
  replace?: boolean;
}) {
  const { event, target, linkTo, replace, history } = props;

  event?.preventDefault();
  event?.stopPropagation();

  // If the link is a string; and a target (like _blank) is provided or the metaKey is pressed or
  // the link starts with http:// (or any schema prefix), then use window.open instead of history.
  if (typeof linkTo === 'string' && (target || event?.metaKey || linkTo.match(/^([a-z]+:)?\/\//))) {
    window.open(linkTo, target);
  } else {
    if (replace) history.replace(linkTo);
    else history.push(linkTo);
  }
}
