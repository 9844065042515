import $, { type StylixProps } from '@stylix/core';
import * as React from 'react';

import colors from 'src/ui/colors';

interface MenuItemProps {
  isSelected: boolean;
  isHighlighted: boolean;
  isDisabled: boolean;
  children: React.ReactNode;
}

export default React.forwardRef<HTMLDivElement, StylixProps<'div', MenuItemProps>>(
  function MenuItem(props, ref) {
    const { isSelected, isHighlighted, isDisabled, ...other } = props;
    return (
      <$.flex
        justify-content="flex-start"
        align-items="center"
        background={
          isSelected
            ? isHighlighted
              ? colors.blue.primaryHover
              : colors.blue.primaryDark
            : isHighlighted
            ? '#0001'
            : undefined
        }
        color={isSelected ? '#FFF' : undefined}
        opacity={isDisabled ? 0.5 : undefined}
        pointerEvents={isDisabled ? 'none' : undefined}
        p="0 10px"
        cursor="pointer"
        ref={ref}
        $css={{
          '&:hover': {
            background: isSelected
              ? isHighlighted
                ? colors.blue.primaryHover
                : colors.blue.primaryDark
              : '#0001',
          },
        }}
        {...other}
      />
    );
  },
);
