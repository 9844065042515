import config from '~/config';

/**
 * NOTE!
 * This file is deprecated. We are trying to move to tRPC. Please do not add any additional routes
 * to this file.
 */

const hosts = {
  measure: config.apiBase.measure || config.apiBase.default + '/quality',
  task: config.apiBase.task || config.apiBase.default + '/task',
  looker: config.apiBase.looker || config.apiBase.default + '/looker',
};

const maps = {
  dil: {
    saveRulesPackage: () => `${hosts.measure}/saveRulesPackage`,
  },
  measureActions: {
    fetchQualityCSV: () => `${hosts.measure}/csv/dashboard`,
    fetchQualityHistoryCSV: () => `${hosts.measure}/csv/dashboardHistory`,

    downloadMeasureDashboardPdf: () => `${hosts.measure}/pdf/dashboard`,
    downloadMeasureScoreCardPdf: () => `${hosts.measure}/pdf/measureScoreCard`,
    downloadProviderScoreCardPdf: () => `${hosts.measure}/pdf/providerScoreCard`,

    fetchActionableBuckets: () => `${hosts.measure}/patients/actionableBuckets`,

    fetchMeasureParentIntermediates: (measureId: string) =>
      `${hosts.measure}/measure/${measureId}/intermediate`,
    fetchMeasureIntermediate: (measureId: string, intermediateUuid: string) =>
      `${hosts.measure}/measure/${measureId}/intermediate/${intermediateUuid}`,
    fetchIncremental: () => `${hosts.measure}/incremental/recent`,

    fetchPatientJourney: (patient_empi_id: string) =>
      `${hosts.measure}/patients/journey/${patient_empi_id}`,
    fetchPatientJourneyCompareRegimens: () => `${hosts.measure}/patients/journey/cohort/regimens`,
    fetchPatientJourneyCohortCounts: () => `${hosts.measure}/patients/journey/cohort/counts`,
    fetchPatientJourneyCohortDetails: () => `${hosts.measure}/patients/journey/cohort/details`,
    fetchPatientJourneyCohortOutcomes: () => `${hosts.measure}/patients/journey/cohort/outcomes`,
    fetchPatientInteractionEvents: () => `${hosts.measure}/patients/interaction/events`,
    fetchPatientInteractionTypes: () => `${hosts.measure}/patients/interaction/types`,
  },
  fileActions: {
    download: () => `${hosts.measure}/download`,
  },
  looker: {
    fetchDashboards: () => `${hosts.looker}/dashboardQuery`,
    fetchResource: (resourceType: string) => `${hosts.looker}/${resourceType}`,
  },
};

export default maps;
