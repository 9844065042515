import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import $, { type StylixProps } from '@stylix/core';
import React from 'react';

import colors from 'src/ui/colors';
import Icon, { type IconProps } from 'src/ui/Icon';
import Spinner from 'src/ui/Spinner';

interface LoaderProps {
  icon?: IconDefinition;
  spinnerProps?: Partial<React.ComponentProps<typeof Spinner>>;
  iconProps?: Partial<IconProps>;
  children?: React.ReactNode | React.ReactNode[];
}

/**
 * A standard loading spinner and message text.
 */
export default function Loader({
  icon,
  spinnerProps,
  iconProps,
  children,
  ...styles
}: StylixProps<'div', LoaderProps>) {
  const spinnerSize = icon ? 60 : 50;

  return (
    <$.flex flex-center padding="40px 0" flexDirection="column" {...styles}>
      <$.div position="relative" width={spinnerSize} height={spinnerSize}>
        <Spinner
          absolute
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          size={spinnerSize}
          {...spinnerProps}
        />
        {icon && (
          <Icon
            absolute
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            icon={icon}
            fontSize={26}
            color="#999"
            {...iconProps}
          />
        )}
      </$.div>
      {children && (
        <$.span inlineBlock fontSize={20} mt={20} color={colors.grey.neutral}>
          {children}
        </$.span>
      )}
    </$.flex>
  );
}
