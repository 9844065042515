import { OktaAuth } from '@okta/okta-auth-js';
import {
  SecureRoute as OktaSecureRoute,
  useOktaAuth as oktaUseOktaAuth,
  Security,
} from '@okta/okta-react';
import React from 'react';
import { Route, useHistory } from 'react-router-dom';

import config from '~/config';

export let OktaAuthWrap: React.FC<{ children: any }>;
export let oktaAuth: OktaAuth;
export let SecureRoute: typeof Route | typeof OktaSecureRoute;
export let useOktaAuth: typeof oktaUseOktaAuth;

export const isOktaEnv = () => !config.okta.disabled;

if (isOktaEnv()) {
  /*
    An instantiated Okta Auth object (rather than using HOC React components)
    Mostly used accessing the library outside of React components (i.e. access token insertion in all headers via Axios)
  */

  oktaAuth = new OktaAuth({
    issuer: config.okta.issuer,
    redirectUri: config.okta.redirectUri,
    clientId: config.okta.clientId,
    pkce: false,
    responseType: ['id_token', 'token'],
    scopes: ['openid', 'email', 'profile'],
    state: JSON.stringify({ originalUrl: window.location.href }),
  });

  OktaAuthWrap = function OktaAuthWrap(props: any) {
    const history = useHistory();
    return (
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={(oktaAuth, originalUri) => {
          history.push('/' + (originalUri?.replace(/(^\/+|\/+$)/g, '') || ''));
        }}
      >
        {props.children}
      </Security>
    );
  };

  useOktaAuth = oktaUseOktaAuth;
  SecureRoute = OktaSecureRoute;
} else {
  OktaAuthWrap = (props: any) => props.children;
  oktaAuth = {
    isAuthenticated: () => Promise.resolve(true),
    getAccessToken: () => '',
  } as OktaAuth;
  SecureRoute = Route;
  useOktaAuth = () =>
    ({
      oktaAuth: { getUser: () => ({ given_name: 'Test', family_name: 'User' }) },
      authState: {},
    } as any);
}
