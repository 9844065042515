export type Resolver<T> = {
  promise: Promise<T>;
  resolve: (value: T) => void;
  reject: (reason?: any) => void;
  id: number;
  resolved: boolean;
};

/**
 * Returns an object containing a Promise that can be resolved externally.
 * e.g.
 *
 * // create a resolver:
 * const r = createResolver();
 * await r.promise;
 *
 * // elsewhere, resolve or reject the promise:
 * r.resolve(); // resolves the promise
 * r.reject(); // rejects the promise
 */
export function createResolver<T>(): Resolver<T> {
  const resolverObj = {
    id: Math.random(),
    resolved: false,
  } as Resolver<T>;

  resolverObj.promise = new Promise<T>((resolve, reject) => {
    resolverObj.resolve = (arg: T) => {
      resolverObj.resolved = true;
      resolve(arg);
    };
    resolverObj.reject = (arg: any) => {
      resolverObj.resolved = true;
      reject(arg);
    };
  });

  return resolverObj;
}

export class Deferred<T> extends Promise<T> {
  resolved = false;
  rejected = false;
  settled = false;

  private resolver: (value: T | PromiseLike<T>) => void;
  private rejecter: (reason: any) => void;

  constructor() {
    let res, rej;
    super((resolve, reject) => {
      res = resolve;
      rej = reject;
    });
    this.resolver = res;
    this.rejecter = rej;
    Object.setPrototypeOf(this, Promise.prototype);
  }

  resolve = (value: T | PromiseLike<T>) => {
    this.resolved = true;
    this.settled = true;
    return this.resolver(value);
  };

  reject = (reason: any) => {
    this.rejected = true;
    this.settled = true;
    return this.rejecter(reason);
  };
}
