import { type TRPC_ERROR_CODES_BY_KEY } from '@trpc/server/dist/rpc';

export const JSONRPC2_TO_HTTP_CODE: Record<keyof typeof TRPC_ERROR_CODES_BY_KEY, number> = {
  PARSE_ERROR: 400,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  TIMEOUT: 408,
  CONFLICT: 409,
  CLIENT_CLOSED_REQUEST: 499,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  METHOD_NOT_SUPPORTED: 405,
  TOO_MANY_REQUESTS: 429,
  UNPROCESSABLE_CONTENT: 422,
  NOT_IMPLEMENTED: 501,
};

export const HTTP_CODE_TO_JSONRPC2: Record<number, keyof typeof TRPC_ERROR_CODES_BY_KEY> = {
  400: 'BAD_REQUEST',
  401: 'UNAUTHORIZED',
  403: 'FORBIDDEN',
  404: 'NOT_FOUND',
  408: 'TIMEOUT',
  409: 'CONFLICT',
  412: 'PRECONDITION_FAILED',
  413: 'PAYLOAD_TOO_LARGE',
  429: 'TOO_MANY_REQUESTS',
  499: 'CLIENT_CLOSED_REQUEST',
  422: 'UNPROCESSABLE_CONTENT',
  500: 'INTERNAL_SERVER_ERROR',
};

/**
 * An Error object that includes an HTTP status code and optional additional data.
 * Throw an ApiError to set the http status code and include the additional error
 * details in the response body.
 *
 * Note that an ApiError 'message' property is considered safe and will be returned to the client
 * even if the DEBUG env var is false. DO NOT include sensitive information such as SQL queries
 * or PHI in a message!
 *
 * If you want to log sensitive information to Cloudwatch, log it in a separate statement using log.error().
 *
 * ```
 * import ApiError from 'api_measure/shared/ApiError';
 *
 * // In a request handler:
 * throw new ApiError('Something went wrong', 401, { additional: 'data' });
 * ```
 *
 * Note that you must use `asyncHandler` and `handleErrors` for this to work.
 */
export default class ApiError extends Error {
  constructor(public message: string, public httpStatus = 401, public data: any = null) {
    super(message);
    this.name = 'ApiError';

    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

/**
 * Utility function to convert an error object to JSON.
 * (Error object properties don't appear in simple JSON.stringify)
 * If the DEBUG env var or `keepDebugInfo` is set, the raw error message, stack trace, and .data property are included.
 * Otherwise, the stack is omitted and the message is only included if the error is an ApiError object.
 */
export function jsonifyError(err: any, keepDebugInfo = false): any {
  if (typeof err !== 'object' || !err) {
    return err;
  }

  return keepDebugInfo
    ? {
        ...err,
        name: err.name,
        message: err.message,
        data: err.data instanceof Error ? jsonifyError(err.data, keepDebugInfo) : err.data,
        stack: err.stack,
      }
    : {
        name: err.name,
        message: err instanceof ApiError ? err.message : 'An unexpected error occurred.',
        httpStatus: err.httpStatus,
        data: err.data instanceof Error ? jsonifyError(err.data, keepDebugInfo) : err.data,
      };
}
