import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { isOktaEnv, useOktaAuth } from 'src/data/oktaAuth';

import { useIsLoginSessionSetter } from '../data/useIsLoginSession';
import LoginLoader from './LoginLoader';

/**
 * This component handles the Okta redirect flow (see App.tsx for the route that directs here)
 */
export default function OktaLoginRedirect() {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();

  const [error, setError] = useState<Error>();

  const setIsLoginSession = useIsLoginSessionSetter();

  useEffect(() => {
    setIsLoginSession(true);

    if (!isOktaEnv()) {
      history.replace('/');
      return;
    }

    (async () => {
      try {
        const previousPath = window.location.pathname;
        await oktaAuth.handleLoginRedirect();

        // If Okta didn't error or redirect, this page was accessed by mistake so just redirect to home
        if (!error && window.location.pathname === previousPath) {
          history.push('/');
        }
      } catch (e) {
        console.error(e);
        setError(e);
      }
    })();
  }, []);

  return <LoginLoader error={error} />;
}
