import { Role } from 'api_measure/lib/roles';

import useLogOut from 'src/App/useLogOut';
import { useDebugUser } from 'src/data/debugUser';
import { trpc } from 'src/data/trpc';
import useLoadingModal from 'src/ui/useLoadingModal';

// These are the only roles that can be assigned to a user when org switching
export const orgSwitcherRoles: Array<{
  group: string;
  roles: Array<{
    role: Role;
    defaultSelected: boolean;
  }>;
}> = [
  {
    group: 'Persona roles',
    roles: [
      {
        role: Role.orgAdmin,
        defaultSelected: true,
      },
      {
        role: Role.trialinq,
        defaultSelected: false,
      },
    ],
  },
  {
    group: 'Feature roles',
    roles: [
      {
        role: Role.ascoCertified,
        defaultSelected: true,
      },
      {
        role: Role.dataReviewer,
        defaultSelected: true,
      },
      {
        role: Role.insightsDataExporter,
        defaultSelected: true,
      },
      {
        role: Role.insightsRegistry,
        defaultSelected: true,
      },
      {
        role: Role.patientJourney,
        defaultSelected: false,
      },
      {
        role: Role.qcp,
        defaultSelected: true,
      },
    ],
  },
];

export const fastOrgSwitcherRoles: Role[] = [
  Role.orgAdmin,
  Role.ascoCertified,
  Role.dataReviewer,
  Role.insightsDataExporter,
  Role.insightsRegistry,
  Role.qcp,
];

export function useEndOrgSwitchMutation() {
  const { withLoadingModal } = useLoadingModal();
  const logOut = useLogOut();
  const { resetDebugRoles } = useDebugUser();
  const mutation = trpc.endOrgSwitcher.useMutation({});

  return {
    ...mutation,
    mutateAsync: async () => {
      withLoadingModal('Ending org switcher session...', async () => {
        try {
          // This will fail if user is not org switching, but we don't really care.
          await mutation.mutateAsync();
        } catch (e) {}
        resetDebugRoles([]);
        await logOut({ track: false, overlay: false });
      });
    },
  };

  // return useMutation(async () => {
  //   withLoadingModal('Ending org switcher session...', async () => {
  //     try {
  //       // This will fail if user is not org switching, but we don't really care.
  //       await axios.delete(routeMaps.userActions.updateOrgSwitcher());
  //     } catch (e) {}
  //     resetDebugRoles([]);
  //     await logOut({ track: false, overlay: false });
  //   });
  // });
}
