import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useDebugUser } from 'src/data/debugUser';
import { isOktaEnv, oktaAuth } from 'src/data/oktaAuth';
import { trpc } from 'src/data/trpc';

/**
 * Hook that returns the current user.
 *
 * The `enabled` param is used on application launch to conditionally prevent the user from being
 * loaded before the app is ready (since you can't put hooks in if statements).
 */
export function useCurrentUser(enabled = true) {
  const { debugUser } = useDebugUser();
  const userDate = debugUser?.date || dayjs();

  const utils = trpc.useContext();

  const userQuery = trpc.getMe.useQuery(
    { attestationYear: userDate.year() },
    {
      enabled: enabled && (isOktaEnv() ? !!oktaAuth.getAccessToken() : true),
      onError() {
        // Override error toast; error is displayed on screen
      },
    },
  );

  const roles = debugUser?.roles || userQuery.data?.roles || [];
  const provider = debugUser?.provider || userQuery.data?.provider || undefined;

  return {
    reloadUser() {
      utils.getMe.invalidate();
    },

    userDate,

    /**
     * This value is marked as not null because for 99% of use cases we know it will be present. In rare special cases
     * (e.g. logging in screens), the value may be null but we will handle that case in the code.
     */
    ...userQuery.data!,

    roles: useMemo(() => roles, [JSON.stringify(roles)]),
    provider,

    serverRoles: userQuery.data?.roles || [],

    error: userQuery.error,
    isLoading: userQuery.isLoading,
    isFetching: userQuery.isFetching,
  };
}
