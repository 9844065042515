import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useState } from 'react';

import useLogOut from 'src/App/useLogOut';
import track from 'src/data/mixpanel';
import Button from 'src/ui/Button';
import ConfirmModal from 'src/ui/ConfirmModal';

// For testing purposes, users can set debug-idle-warning and debug-idle-logout in the
// localStorage console to the number of seconds to warn and log out at, respectively.
const DEBUG_IDLE_LOGOUT = parseFloat(localStorage.getItem('debug-idle-logout') || '0') || 0;
const DEBUG_IDLE_WARNING = parseFloat(localStorage.getItem('debug-idle-warning') || '0') || 0;
const DEBUG_IDLE_CONSOLE =
  DEBUG_IDLE_LOGOUT || DEBUG_IDLE_WARNING || !!localStorage.getItem('debug-idle-console');

// Number of seconds to display idle warning (12 min default)
const IDLE_WARNING = DEBUG_IDLE_WARNING || 12 * 60;
// Number of seconds after displaying idle warning to log out at (3 min default)
const IDLE_LOGOUT = DEBUG_IDLE_LOGOUT || 3 * 60;

function debug(...args: any[]) {
  if (DEBUG_IDLE_CONSOLE) console.debug('LogoutTimer:', ...args);
}

debug({ IDLE_LOGOUT, IDLE_WARNING });

function triggerCrossTabLogoutTimerReset() {
  // Triggers a window 'storage' event in other open tabs to indicate that the user dismissed the modal
  localStorage.setItem('idle-warning-dismiss', '1');
  localStorage.removeItem('idle-warning-dismiss');
}

/**
 * - If the user is idle (no mouse movement) for IDLE_WARNING seconds, an alert is displayed to the
 *   user asking if they want to continue their session.
 * - If the user clicks "Log out" or is still idle for IDLE_LOGOUT more seconds, the user is logged out.
 * - If the user clicks "Continue", the user's session is renewed and this component continues
 *   operation normally.
 */
export default function LogoutTimer() {
  const [showAlert, setShowAlert] = useState(false);
  const [debugWarningTimestamp, setDebugWarningTimestamp] = useState(0);

  // Calling setResetTrigger({}) will cause all the timers to reset
  const [resetTrigger, setResetTrigger] = useState({});

  // On user click, reset everything if the warning dialog is not displaying
  useEffect(() => {
    function listener() {
      if (showAlert) return;
      setResetTrigger({});
      triggerCrossTabLogoutTimerReset();
    }

    window.document.addEventListener('click', listener);
    window.document.addEventListener('keydown', listener);
    window.document.addEventListener('scroll', listener);
    return () => {
      window.document.removeEventListener('click', listener);
      window.document.removeEventListener('keydown', listener);
      window.document.removeEventListener('scroll', listener);
    };
  }, [showAlert]);

  // On reset, hide the warning dialog and start the timer to show it
  useEffect(() => {
    setShowAlert(false);

    setDebugWarningTimestamp(Date.now() + IDLE_WARNING * 1000);

    // Show timeout alert after IDLE_WARNING seconds
    const alertTimeout = setTimeout(() => {
      debug(`user has been idle ${IDLE_WARNING} seconds; showing alert`);
      setShowAlert(true);
    }, IDLE_WARNING * 1000);

    return () => clearTimeout(alertTimeout);
  }, [resetTrigger]);

  // When the alert shows, wait for IDLE_LOGOUT seconds and then log out the user.
  useEffect(() => {
    if (!showAlert) return;

    const logoutTimeout = setTimeout(async () => {
      await track('Session Timeout', null, { send_immediately: true });
      logOut({ track: false });
    }, IDLE_LOGOUT * 1000);

    // When the dialog closes, clear the timeout
    return () => clearTimeout(logoutTimeout);
  }, [showAlert]);

  // For debug purposes only; if DEBUG_IDLE_LOGOUT is set, a status will be logged every second.
  useEffect(() => {
    if (!DEBUG_IDLE_CONSOLE) return;

    const debugInterval = setInterval(async () => {
      debug(
        showAlert
          ? `User is idle (logout in ${Math.round(
              (debugWarningTimestamp + IDLE_LOGOUT * 1000 - Date.now()) / 1000,
            )} seconds)`
          : `User is active (logout warning in ${Math.round(
              (debugWarningTimestamp - Date.now()) / 1000,
            )} seconds)`,
      );
    }, 1000);

    return () => clearInterval(debugInterval);
  }, [showAlert, debugWarningTimestamp]);

  function closeDialog() {
    setResetTrigger({});
    triggerCrossTabLogoutTimerReset();
  }

  // Respond to window 'storage' event to reset timeout from other tabs
  useEffect(() => {
    function storageListener(e: StorageEvent) {
      if (e.key === 'idle-warning-dismiss' && e.newValue === '1') {
        debug('Received cross-tab idle reset');
        setResetTrigger({});
      }
    }
    window.addEventListener('storage', storageListener);
    return () => window.removeEventListener('storage', storageListener);
  }, []);

  const logOut = useLogOut();

  return (
    <ConfirmModal
      isOpen={showAlert}
      color="error"
      icon={faExclamation}
      dialogProps={{
        onClose: closeDialog,
      }}
      renderButtons={() => (
        <>
          <Button variant="ghost" onClick={logOut} mr={10}>
            Log Out
          </Button>
          <Button onClick={closeDialog}>Keep Working</Button>
        </>
      )}
    >
      <div>
        Your session will expire within 3 minutes.
        <br />
        Would you like to keep working?
      </div>
    </ConfirmModal>
  );
}
