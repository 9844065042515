import { PartialClientConfig } from './index';

// Contains base defaults for development environments
// See local.sample.ts for all possible config properties

export const baseDev: PartialClientConfig = {
  okta: {
    issuer: 'https://okta.cancerlinq.io/oauth2/aus11ji6xs2wewSvF0h8',
  },
  apiBatching: true,
  apiBase: {
    default: '/api',
  },
  aws: {
    universe: '',
    environment: '',
  },
  dil: {
    mode: 'onboarding',
  },
  mixpanel: {
    token: '2580a83189a7024c751c9cdf8d2d1539', // "SmartLinQ Non-prod" mixpanel project
    debug: true,
  },
  trialinq: {
    redirectUri: (accessToken, redirect) =>
      accessToken && redirect
        ? 'https://dts.qa.cancerlinq.io/population_explorer/api/login_via_access_token?' +
          new URLSearchParams({ cancerlinq_access_token: accessToken, redirect })
        : undefined,
  },
  features: {},
  stylixDevMode: true,
  canDebug: true,
};

// Contains base defaults for production environments
export const baseProd: PartialClientConfig = {
  okta: {
    issuer: 'https://okta.cancerlinq.org/oauth2/ausk1pdckzF3CMhz51t7',
  },
  apiBatching: true,
  apiBase: {
    default: '/api',
  },
  aws: {
    universe: '',
    environment: '',
  },
  dil: {
    mode: 'production',
  },
  mixpanel: {
    token: 'febc57546f5b05a2f6a3d4009952fa02', // "SmartLinQ Prod" mixpanel project
    debug: false,
  },
  trialinq: {
    redirectUri: (accessToken, redirect) =>
      accessToken && redirect
        ? 'https://trialinq.cancerlinq.io/population_explorer/api/login_via_access_token?' +
          new URLSearchParams({ cancerlinq_access_token: accessToken, redirect })
        : undefined,
  },
  features: {},
  stylixDevMode: false,
  canDebug: false,
};
