import { useLayoutEffect } from 'react';

import createGlobalState from 'src/ui/createGlobalState';

export type MarginType = string | number;
export const DEFAULT_APP_MARGIN = '35px 55px 100px' as MarginType;

export const useAppMarginState = createGlobalState(DEFAULT_APP_MARGIN);

/**
 * A React hook that sets the margin for the main app content area.
 * Call this in your component to set the current margin. You can pass any valid margin css value
 * (eg. 10, "10px", "10px 20px", etc...)
 * It will be automatically restored to its previous value when the component unmounts.
 * Use DEFAULT_APP_MARGIN to reset to the default margin (although you won't have to do this in normal circumstances
 * because it reverts to this value after a component unmounts).
 */
export default function useAppMargin(margin: MarginType) {
  const [appMargin, setAppMargin] = useAppMarginState();
  // useLayoutEffect prevents the content from jumping when the margin is changed.
  useLayoutEffect(() => {
    setAppMargin(margin);
    // appMargin is purposely not in the useEffect dependency list because we want to restore the value
    // that was present on mount.
    return () => setAppMargin(appMargin);
  }, []);
}
