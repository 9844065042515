import config from '~/config';

type UniverseName = 'prod' | 'stage' | 'dev' | 'qa' | 'sandbox';

type EnvironmentName =
  | 'demo'
  | 'onboarding'
  | 'preview'
  | 'preview2'
  | 'prod'
  | 'dev'
  | 'qa'
  | 'qa2'
  | 'sandbox'
  | 'sandbox2';

export interface Environment {
  name: EnvironmentName;
  universe: UniverseName;
  label: string;
  baseUrl: string;
}

/**
 * This data structure contains information about the different environments that needs to be available
 * to the client regardless of what environment it is running in (for example, the org import/export
 * feature needs to know about different environments to control where the export goes to/from).
 */
export const environmentMap: Record<EnvironmentName, Environment> = {
  prod: {
    name: 'prod',
    universe: 'prod',
    label: 'Prod',
    baseUrl: 'https://app.cancerlinq.org',
  },
  preview: {
    name: 'preview',
    universe: 'prod',
    label: 'Preview',
    baseUrl: 'https://app.preview.prod.cancerlinq.io',
  },
  preview2: {
    name: 'preview2',
    universe: 'prod',
    label: 'Preview2',
    baseUrl: 'https://app.preview2.prod.cancerlinq.io',
  },
  demo: {
    name: 'demo',
    universe: 'stage',
    label: 'Demo',
    baseUrl: 'https://app.demo.stage.cancerlinq.io',
  },
  onboarding: {
    name: 'onboarding',
    universe: 'prod',
    label: 'Onboarding',
    baseUrl: 'https://app.onboarding.prod.cancerlinq.io',
  },
  qa: {
    name: 'qa',
    universe: 'qa',
    label: 'QA',
    baseUrl: 'https://app.qa.qa.cancerlinq.io',
  },
  qa2: {
    name: 'qa2',
    universe: 'qa',
    label: 'QA 2',
    baseUrl: 'https://app.qa2.qa.cancerlinq.io',
  },
  dev: {
    name: 'dev',
    universe: 'dev',
    label: 'Dev',
    baseUrl: 'https://app.dev.dev.cancerlinq.io',
  },
  sandbox: {
    name: 'sandbox',
    universe: 'sandbox',
    label: 'Sandbox',
    baseUrl: 'https://app.sandbox.sandbox.cancerlinq.io',
  },
  sandbox2: {
    name: 'sandbox2',
    universe: 'sandbox',
    label: 'Sandbox 2',
    baseUrl: 'https://app.sandbox2.sandbox.cancerlinq.io',
  },
};

export const environments = Object.values(environmentMap) as Environment[];

/**
 * Returns the current Environment object (the environment that matches the
 * config.aws.environment variable).
 */
export function currentEnv(): Environment {
  return environmentMap[config.aws.environment as EnvironmentName];
}

export function isLocal() {
  return process.env.REACT_CONFIG === 'local';
}
