import { type Dayjs } from 'dayjs';

// IMPORTANT
// Constants and types in this file are shared with the client. Don't add any code (or import anything) here that relies
// on server-only modules.

/**
 * Organization type in database
 */
export interface Org {
  sender_code: string;
  name: string;
  address_one: string;
  address_two: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  region: string;
  primary_phone: string;
  type: OrgType;
  status: OrgStatus;
  tin_assignment_type: OrgTINAssignmentType;
  tin: string;
  offshore_allowed: boolean;
  created_at: Dayjs;
  created_by: number;
  updated_at: Dayjs;
  updated_by: number;
  features: OrgFeature[];
}

export interface OrgUnitAttestation {
  id: number;
  org_sender_code: string;
  ou_id: number;
  year: number;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  created_at: Dayjs;
}

export const orgStatuses = [
  'Pre-Production',
  'Production',
  'Retired',
  'Internal',
  'Dropped',
] as const;

export type OrgStatus = (typeof orgStatuses)[number];

export const orgTypes = [
  'Academic',
  'Government',
  'Hospital/Health System',
  'Large Private/Independent',
  'Private/Independent',
] as const;

export type OrgType = (typeof orgTypes)[number];

export const orgTINAssignmentTypes = ['Practice', 'Organization Unit'] as const;
export type OrgTINAssignmentType = (typeof orgTINAssignmentTypes)[number];

export const qcpSignupTypes = ['', 'org', 'ou'] as const;
export type QcpSignupType = (typeof qcpSignupTypes)[number];

export enum OrgFeature {
  Atropos = 'atropos',
  TriaLinQ = 'trialinq',
  AscoCertified = 'ascoCertified',
}

export const orgFeatures = Object.values(OrgFeature) as [OrgFeature, ...OrgFeature[]];

export interface OrgFeatureInfo {
  label: string;
  description: string;
}

export const orgFeatureInfo: Record<OrgFeature, OrgFeatureInfo> = {
  atropos: {
    label: 'Atropos',
    description: 'Enable Atropos SSO login link.',
  },
  trialinq: {
    label: 'TriaLinQ',
    description: 'Enable access to TriaLinQ.',
  },
  ascoCertified: {
    label: 'ASCO Certified',
    description: 'Enable ASCO Certified measures.',
  },
};

export function hasOrgFeature(userOrgFeatures: OrgFeature[], ...hasFeatures: OrgFeature[]) {
  return hasFeatures.some((feature) => userOrgFeatures?.includes(feature));
}

// OU types

export const orgUnitStatuses = ['Onboarding', 'Inactive', 'Active'] as const;

export type OrgUnitStatus = (typeof orgUnitStatuses)[number];

// Full OU type (includes all table columns).
export interface OrgUnit {
  id: number;
  org_sender_code: string;
  code: string;
  name: string;
  address_one: string;
  address_two: string;
  city: string;
  state: string;
  zip: string;
  primary_phone: string;
  country: string;
  region: string;
  status: OrgUnitStatus;
  tin: string;
  created_at: Dayjs;
  created_by: number;
  updated_at: Dayjs;
  updated_by: number;
}

// SFTP types

export interface Sftp {
  id: number;
  org_sender_code: string;
  ehr_type: string;
  ehr_mapping_version: string;
  instance_type: 'push' | 'pull';
  instance_id: string;
  instance_desc: string;
  status: string;
  created_at: Dayjs;
  updated_at: Dayjs;
}

export const sftpStatuses = ['Active', 'Pending'] as const;
export type SftpStatus = (typeof sftpStatuses)[number];
