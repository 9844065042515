import { faPrescriptionBottle } from '@fortawesome/pro-solid-svg-icons';
import $ from '@stylix/core';
import React from 'react';

import { NewFeatureDialogType } from 'api_measure/web/routes/users/lib/users.types';

import {
  type NewFeatureDialogContent,
  type NewFeatureDialogContentProps,
} from 'src/Dashboard/NewFeatureDialog/useNewFeatureDialogContent';
import Button from 'src/ui/Button';
import Icon from 'src/ui/Icon';

export const rxLinqUpdatesFeatureDialogContent: NewFeatureDialogContent = {
  newFeatureDialogType: NewFeatureDialogType.rxlinqUpdates,

  Component: RxLinqUpdatesFeatureDialogContent,

  useShouldShowContent: () => 'show',
};

export function RxLinqUpdatesFeatureDialogContent(props: NewFeatureDialogContentProps) {
  return (
    <$.div flex-center flex-column text-center>
      <$.div
        border="8px solid #E8F7FF"
        background="#C2E6F2"
        width={140}
        height={140}
        border-radius={70}
        flex-center
        mt={25}
      >
        <Icon icon={faPrescriptionBottle} size={64} color="#FFF" />
      </$.div>

      <$.div size={24} mt={15}>
        New RxLinQ Dashboard
      </$.div>

      <$.div size={18} line-height={1.5} mt={15}>
        We are excited to announce our new and improved RxLinQ Dashboard! <br />
        Our latest update features a sleek and intuitive design, making it easier than ever to
        navigate and manage your therapeutic options.
      </$.div>

      <Button
        variant="blue"
        large
        font-weight={400}
        mt={30}
        onClick={() => {
          props.closeDialog('/patient/actionable/fda-approvals');
        }}
      >
        Launch RxLinQ
      </Button>
    </$.div>
  );
}
