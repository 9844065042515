import { useLocalStorageValue, useSessionStorageValue } from '@react-hookz/web';
import {
  type UseStorageValueOptions,
  type UseStorageValueResult,
} from '@react-hookz/web/esm/useStorageValue';

import megaJson from 'api_measure/lib/megaJson';
import tryJSONParse from 'api_measure/lib/tryJSONParse';

export const storageParser = {
  parse: <T>(value, fallback?): T => {
    let res: any;
    if (value) {
      try {
        res = megaJson.parse(value);
      } catch (e) {}
      if (typeof res === 'undefined') {
        res = tryJSONParse(value);
      }
    }
    return res === undefined ? fallback : res;
  },
  stringify: (value) => {
    return megaJson.stringify(value) || 'null';
  },
};

export function useLocalStorageState<
  Type,
  Initialize extends boolean | undefined = boolean | undefined,
>(key: string, options?: UseStorageValueOptions<Type, Initialize>) {
  const value = useLocalStorageValue(key, { ...options, ...storageParser });
  return [value.value, (v) => (v === undefined ? value.remove() : value.set(v))] as [
    Type,
    UseStorageValueResult<Type, Type, Initialize>['set'],
  ];
}

export function useSessionStorageState<
  Type,
  Initialize extends boolean | undefined = boolean | undefined,
>(key: string, options?: UseStorageValueOptions<Type, Initialize>) {
  const value = useSessionStorageValue(key, { ...options, ...storageParser });
  return [value.value, (v) => (v === undefined ? value.remove() : value.set(v))] as [
    Type,
    UseStorageValueResult<Type, Type, Initialize>['set'],
  ];
}
