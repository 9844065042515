import { type IDialogProps } from '@blueprintjs/core';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import $, { type StylixProps } from '@stylix/core';
import { ref, useObserver } from 'keck';
import React, { useEffect, useRef, useState } from 'react';

import { createResolver } from 'api_measure/lib/resolver';

import track from 'src/data/mixpanel';
import Button, { type ButtonProps } from 'src/ui/Button';
import colors from 'src/ui/colors';
import Icon, { type IconProps } from 'src/ui/Icon';
import ScrollableDialog from 'src/ui/ScrollableDialog';

type ModalColorScheme = 'default' | 'warning' | 'error' | 'success';

const colorSchemeMap = {
  warning: colors.orange.primary,
  error: colors.red.primary,
  default: colors.blue.primary,
  success: colors.green.primary,
};

interface ConfirmModalProps {
  isOpen: boolean;
  dialogProps?: Partial<IDialogProps>;
  modalWrapperProps?: any;
  contentWrapperProps?: any;
  color?: ModalColorScheme;
  customColor?: string;
  maxWidth?: number | string;
  icon?: IconProp;
  renderIcon?: () => React.ReactNode;
  iconProps?: Partial<IconProps>;
  title?: React.ReactNode;
  scrollable?: boolean;
  centerContent?: boolean;
  children: React.ReactNode | React.ReactNodeArray;
  renderButtons: () => React.ReactNode | React.ReactNodeArray;
  buttonWrapperProps?: StylixProps;
  stackButtons?: boolean;
  trackLabel?: string;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const {
    isOpen,
    dialogProps,
    modalWrapperProps,
    contentWrapperProps,
    color,
    customColor,
    maxWidth,
    title,
    icon,
    iconProps,
    renderIcon,
    renderButtons,
    buttonWrapperProps,
    scrollable = false,
    centerContent,
    children,
    trackLabel,
  } = props;

  useEffect(() => {
    if (!isOpen || !trackLabel) return;
    track('Modal:Open', { Label: trackLabel });
    return () => {
      track('Modal:Close', { Label: trackLabel });
    };
  }, [isOpen]);

  return (
    <ScrollableDialog
      isOpen={isOpen}
      p={0}
      overflow="hidden"
      max-width={typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth || '700px'}
      disableScroll={!scrollable}
      shouldReturnFocusOnClose={false}
      header={
        <$.flex
          flex-center
          column
          color={colors.grey.neutral}
          padding="30px 50px"
          borderTop={`14px solid ${customColor || colorSchemeMap[color || 'default']}`}
          rowGap={20}
          {...modalWrapperProps}
        >
          {renderIcon?.() ??
            (icon && (
              <$.flex
                flex-center
                width={50}
                height={50}
                color="white"
                size={25}
                borderRadius="50%"
                background={customColor || colorSchemeMap[color || 'default']}
              >
                <Icon icon={icon!} {...iconProps} />
              </$.flex>
            ))}
          {title && (
            <$.span inlineBlock alignCenter size={22} data-test="confirm-modal-title">
              {title}
            </$.span>
          )}
        </$.flex>
      }
      footer={
        <$.flex
          flex-center
          padding="40px 50px 30px"
          columnGap={20}
          rowGap={10}
          {...buttonWrapperProps}
        >
          {renderButtons()}
        </$.flex>
      }
      modalWrapperProps={modalWrapperProps}
      {...dialogProps}
    >
      <$.div
        relative
        color={colors.grey.neutral}
        padding="0 50px"
        size={15}
        lineHeight={1.6}
        textAlign={centerContent ? 'center' : 'left'}
        {...contentWrapperProps}
      >
        {children}
      </$.div>
    </ScrollableDialog>
  );
}

const confirmModalStore = {
  isOpen: false,
  confirmModalProps: {} as Omit<ConfirmModalProps, 'isOpen'> & {
    content: React.ReactNode | React.FC;
  },
};

export type ShowConfirmModalOptions = Omit<
  Partial<ConfirmModalProps>,
  'isOpen' | 'children' | 'renderButtons'
> & {
  // Replaces renderButtons
  buttons: Array<
    ButtonProps & {
      label: string;
    }
  >;
  // Replaces children
  content: React.ReactNode | React.FC;
};

/**
 * Usage:
 *
 * const showConfirmModal = useConfirmModal();
 *
 * // In a click handler, useEffect, etc.
 * const result = await showConfirmModal({
 *   title: 'Are you sure?',
 *   content: 'This action cannot be undone.',
 *   buttons: [
 *     { label: 'Cancel', variant: 'ghost' },
 *     { label: 'Okay', variant: 'blue' },
 *   ]
 * });
 *
 * if (result === 'Okay') {
 *   // do something
 * }
 */
export function useConfirmModal() {
  const state = useObserver(confirmModalStore);

  async function showConfirmModal(modalOptions: ShowConfirmModalOptions) {
    const r = createResolver<string>();
    state.confirmModalProps = ref({
      ...modalOptions,
      buttons: undefined,
      children: null,
      renderButtons: () => (
        <$.flex
          flex-center
          {...(modalOptions.buttons.length > 2 || modalOptions.stackButtons
            ? { column: true, rowGap: 10, alignItems: 'stretch' }
            : { columnGap: 20 })}
        >
          {modalOptions.buttons.map((button, i) => (
            <Button
              key={i}
              minWidth={150}
              {...button}
              onClick={async (e) => {
                await button.onClick?.(e);
                r.resolve(button.label);
                state.isOpen = false;
              }}
            >
              {button.label}
            </Button>
          ))}
        </$.flex>
      ),
    });
    state.isOpen = true;

    return r.promise;
  }

  return showConfirmModal;
}

/**
 * Only needs to be rendered once at the app root
 */
export function UseConfirmModalRenderer() {
  const state = useObserver(confirmModalStore);

  const [render, setRender] = useState(false);
  const renderTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (state.isOpen) {
      clearTimeout(renderTimeoutRef.current);
      setRender(true);
    } else {
      renderTimeoutRef.current = setTimeout(() => setRender(false), 500);
    }
  }, [state.isOpen]);

  return render ? (
    <ConfirmModal {...state.confirmModalProps} isOpen={state.isOpen}>
      {typeof state.confirmModalProps.content === 'function'
        ? React.createElement(state.confirmModalProps.content)
        : state.confirmModalProps.content}
    </ConfirmModal>
  ) : null;
}
