/**
 * Like Object.entries, but returns type-safe keys-value pairs.
 */
export function entries<K extends string | number | symbol, V>(obj: Record<K, V>): [K, V][] {
  return Object.entries(obj) as any;
}

/**
 * Like Object.keys, but returns type-safe keys.
 */
export function keys<K extends string | number | symbol, V>(obj: Record<K, V>): K[] {
  return Object.keys(obj) as any;
}

/**
 * This is like the reverse of lodash _.get. It returns all available object paths as strings.
 * E.g.
 *   {
 *     a: {
 *       b: 1,
 *       c: 2
 *     },
 *     d: 3
 *   }
 *   would return:
 *   ['a.b', 'a.c', 'd']
 */
export function getObjectPaths(obj: any) {
  const paths: string[] = [];
  function _walk(obj: any, path = '') {
    path = path ? path + '.' : '';
    for (const n in obj) {
      if (!Object.prototype.hasOwnProperty.call(obj, n)) continue;
      if (typeof obj[n] === 'object' || Array.isArray(obj[n])) {
        if (typeof obj[n] === 'object' && !Object.keys(obj[n]).length) paths.push(path + n);
        else if (Array.isArray(obj[n]) && !obj[n].length) paths.push(path + n);
        else _walk(obj[n], path + n);
      } else {
        paths.push(path + n);
      }
    }
  }
  _walk(obj);
  return paths;
}

export function shallowEqual(a: object, b: object) {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length && a.every((v, i) => Object.is(v, b[i]));
  } else if (a && b && typeof a === 'object' && typeof b === 'object') {
    return (
      Object.keys(a).length === Object.keys(b).length &&
      Object.keys(a).every((k) => Object.is(a[k], b[k]))
    );
  } else {
    return a === b;
  }
}

export function allObjectPaths(obj: any, filter?: (value: any) => boolean): string[] {
  const paths: string[] = [];
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      paths.push(...allObjectPaths(obj[key], filter).map((p) => `${key}.${p}`));
    } else {
      if (!filter || filter(obj[key])) paths.push(key);
    }
  }
  return paths;
}

export function mapRecursive(obj: any, fn: (value: any) => any, mapEmptyContainers = false) {
  if (typeof obj !== 'object' || !obj) return fn(obj);
  if (mapEmptyContainers && ((Array.isArray(obj) && !obj.length) || !Object.keys(obj).length))
    return fn(obj);

  const result = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    result[key] = mapRecursive(obj[key], fn);
  }
  return result;
}

export function flatMapRecursive(
  obj: any,
  fn: (value: any, key: (string | number | symbol)[]) => any = (v) => v,
  _key: (string | number | symbol)[] = [],
) {
  const result: any[] = [];

  for (const key in obj) {
    const value = obj[key];
    if (value && typeof value === 'object') {
      result.push(...flatMapRecursive(value, fn, [..._key, key]));
    } else {
      result.push(fn(value, [..._key, key]));
    }
  }

  return result;
}

(window as any).flatMapRecursive = flatMapRecursive;
