import { Overlay, Portal, type IOverlayProps } from '@blueprintjs/core';
import $ from '@stylix/core';
import React from 'react';

import ProgressMeter from 'src/ui/ProgressMeter';
import Spinner from 'src/ui/Spinner';

interface LoadingModalProps {
  isOpen: boolean;
  text: string;
  progress?: number;
  modalProps?: Partial<IOverlayProps>;
}

export function LoadingModal(props: LoadingModalProps) {
  const { isOpen, modalProps, progress, text } = props;
  return (
    <>
      <Overlay
        isOpen={isOpen}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
        {...modalProps}
      >
        &nbsp;
      </Overlay>
      <Portal>
        <$.flex
          flex-center
          position="fixed"
          zIndex={21}
          top={0}
          right={0}
          bottom={0}
          left={0}
          flexDirection="column"
          opacity={isOpen ? 1 : 0}
          transition="opacity 100ms linear"
          pointerEvents="none"
        >
          {progress !== undefined ? (
            progress >= 0 && <ProgressMeter height={5} percent={progress} width={300} animate />
          ) : (
            <Spinner />
          )}
          <$.span inlineBlock size={20} bold mt={20} color="white">
            {text}
          </$.span>
        </$.flex>
      </Portal>
    </>
  );
}
