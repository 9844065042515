import { faCommentAltExclamation, faSignOut } from '@fortawesome/pro-regular-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import React from 'react';

import useLogOut from 'src/App/useLogOut';
import Button from 'src/ui/Button';
import colors from 'src/ui/colors';
import Icon from 'src/ui/Icon';

interface PlatformStatusLoginDisabledProps {
  type?: 'warning' | 'error';
  message?: React.ReactNode;
}

export default function PlatformStatusLoginDisabled({
  type,
  message,
  ...styles
}: StylixProps<'div', PlatformStatusLoginDisabledProps>) {
  const logOut = useLogOut();

  return (
    <$.flex flex-center column height="100vh" {...styles}>
      <$.flex
        flex-center
        width={50}
        height={50}
        color="white"
        borderRadius="50%"
        background={type === 'warning' ? colors.orange.primary : colors.red.primary}
        mb={50}
        pt={2}
      >
        <Icon icon={faCommentAltExclamation} size={25} />
      </$.flex>
      <$.div
        fontSize={20}
        mb={70}
        maxWidth={700}
        alignCenter
        whiteSpace="pre-line"
        lineHeight={1.6}
      >
        {message}
      </$.div>
      <Button faIcon={faSignOut} variant="ghost" onClick={logOut}>
        Log out
      </Button>
    </$.flex>
  );
}
