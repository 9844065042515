import type React from 'react';

import { type NewFeatureDialogType } from 'api_measure/web/routes/users/lib/users.types';

import { useOrgSwitcherAccepted } from 'src/Admin/OrgSwitcher/OrgSwitcherWarning';
import { newFeatureDialogContents } from 'src/Dashboard/NewFeatureDialog/features/newFeatureDialogContentIndex';
import { useCurrentUser } from 'src/data/user/useCurrentUser';

/**
 * Interface that must be implemented by each dialog content object. This interface provides the information
 * necessary for the system to coordinate which dialog to show.
 */
export interface NewFeatureDialogContent {
  /**
   * Indicates the type of dialog to show, which controls the flag set in the user's settings object to prevent
   * the dialog from showing again.
   */
  newFeatureDialogType: NewFeatureDialogType;

  /**
   * The component to render for the dialog content.
   */
  Component: React.ComponentType<NewFeatureDialogContentProps>;

  /**
   * A React hook that returns whether the dialog content should be shown. Return values are as follows:
   * - 'pass' if the dialog should not be shown.
   * - 'show' if the dialog should be shown.
   * - 'delay' if the dialog might be shown, but is awaiting additional data. No dialogs will be shown until
   *   this dialog is ready.
   *
   *  Because this is a hook, React forbids it from being called conditionally, so when the system is determining
   *  which dialog to show, it must call this hook for every dialog in the same order every time. Even after a dialog
   *  has determined that it is going to be displayed, the remaining dialog hooks will still be called.
   *  To avoid making unnecessary API calls during this hook, the `disableHooks` parameter will be true if it is already
   *  known that this dialog should not be shown. You can use this to disable API calls or other expensive operations.
   */
  useShouldShowContent(disableHooks: boolean): 'pass' | 'delay' | 'show';

  /**
   * Called when the dialog is closed. This is optional and can perform any additional task that should happen when
   * the dialog closes.
   */
  onCloseDialog?(): Promise<void>;
}

/**
 * The props that the dialog content component will receive.
 */
export interface NewFeatureDialogContentProps {
  /**
   * Callback to close the dialog and optionally navigate to a new page.
   */
  closeDialog: (
    /**
     * The link to navigate to after closing the dialog.
     */
    linkTo?: string,
  ) => void;
}

export function useNewFeatureDialogContent(dialogAlreadyDisplayed: boolean) {
  /**
   * This method works by initializing showNewFeatureDialog to `undefined`, and then checking the logic for each dialog
   * in the order of the business's preference. If the logic for any dialog is satisfied, then the value of
   * showNewFeatureDialog is set to the name of that dialog, and the remaining dialogs are not checked. Because React
   * hooks do not permit returning early, and hooks cannot be called conditionally, each dialog's logic needs to
   * carefully check if the value has already been set, while also ensuring that hooks are always called.
   *
   * Some checks require waiting for data to load, so the value of showNewFeatureDialog
   * may remain undefined until the data is loaded.
   */

  const [orgSwitcherAccepted] = useOrgSwitcherAccepted();

  const { profile } = useCurrentUser();

  // Indicates which dialog to show, if any.
  // The value is `undefined` until the code below determines which dialog to show.
  // `false` indicates that no dialog should be shown.
  let showNewFeatureDialog: NewFeatureDialogContent | false | undefined = undefined;

  // If the user has not accepted the org switcher warning, then do not show any dialogs.
  if (!orgSwitcherAccepted) showNewFeatureDialog = false;

  // Loop over each dialog content component and check if it should be shown.
  // Note that React requires that hooks are always called in the same order, so we MUST NOT break out of this loop at
  // any time.
  for (const dialogContent of newFeatureDialogContents) {
    // Determine whether a dialog has already been displayed or selected
    const dialogAlreadySelected = dialogAlreadyDisplayed || showNewFeatureDialog !== undefined;

    // Check if the user has already seen the dialog.
    const hasSeenDialog =
      !!profile.settings.new_feature_dialogs?.[dialogContent.newFeatureDialogType];

    // React forbids conditionally calling this hook, so we pass `hasSeenDialog`
    // to allow the hook to avoid making unnecessary api calls.
    const shouldShowDialog = dialogContent.useShouldShowContent(
      dialogAlreadySelected || hasSeenDialog,
    );

    // If the dialog is not already selected, and the user has not seen this dialog yet, we can check the result of
    // `useShouldShowContent`. Otherwise the result is ignored.
    if (!dialogAlreadySelected && !hasSeenDialog) {
      if (shouldShowDialog === 'show') {
        showNewFeatureDialog = dialogContent;
      } else if (shouldShowDialog === 'delay') {
        // If the dialog is not ready to be shown, then do not show any dialogs.
        showNewFeatureDialog = false;
      }
    }
    // Result was 'pass', or user has seen this dialog already, so continue to next dialog
  }

  return showNewFeatureDialog ?? false;
}
