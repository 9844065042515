import $, { type StylixProps } from '@stylix/core';
import * as React from 'react';

import colors from 'src/ui/colors';

interface DropdownWrapperProps {
  isOpen: boolean;
  isDisabled: boolean;
  hasError: boolean;
}

export default React.forwardRef<HTMLDivElement, StylixProps<'div', DropdownWrapperProps>>(
  function DropdownWrapper(props, ref) {
    const { isOpen, isDisabled, hasError, ...other } = props;

    return (
      <$.flex
        flex-center
        alignItems="stretch"
        width={250}
        minHeight={40}
        border="1px solid #0003"
        borderRadius={4}
        borderColor={hasError ? colors.red.medium : undefined}
        background="#FFF"
        opacity={isDisabled ? 0.5 : 1}
        pointerEvents={isDisabled ? 'none' : 'auto'}
        cursor="pointer"
        overflow="hidden"
        $css={
          !hasError && {
            '&:hover': {
              borderColor: isOpen ? colors.blue.primary : '#0006',
            },
            '&:focus, &:focus-within': {
              borderColor: colors.blue.primary,
            },
          }
        }
        ref={ref}
        {...other}
      />
    );
  },
);
