import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faMinusSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import { derive } from 'keck';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';

import { useKeckField } from 'src/util/keck-forms';

import colors from './colors';
import Icon, { type IconProps } from './Icon';

export type CheckboxClickHandler = (e: any) => void;

interface CheckboxProps {
  label?: React.ReactNode;
  partial?: boolean;
  checked?: boolean;
  disabled?: boolean;
  inputProps?: StylixProps<'input'>;
  iconProps?: Partial<IconProps>;
  labelProps?: StylixProps<'span'>;
  onChange?(e: React.MouseEvent, checked: boolean): void;
  stopPropagation?: boolean;
  partialIcon?: IconDefinition;
  checkedIcon?: IconDefinition;
  uncheckedIcon?: IconDefinition;
}

export default function Checkbox(props: CheckboxProps & Omit<StylixProps<'label'>, 'onChange'>) {
  const {
    label,
    partial,
    checked,
    disabled,
    inputProps,
    labelProps,
    iconProps,
    onChange,
    stopPropagation = true,
    partialIcon = faMinusSquare,
    checkedIcon = faCheckSquare,
    uncheckedIcon = faSquare,
    ...other
  } = props;

  const handleChange = useCallback(
    (e: any) => {
      if (disabled) return;
      onChange?.(e, !checked);
    },
    [onChange, disabled, checked],
  );

  return (
    <$.label
      display="inline-flex"
      justify="flex-start"
      align="flex-start"
      cursor="pointer"
      fontSize={16}
      lineHeight={1.5}
      pointerEvents={disabled ? 'none' : 'auto'}
      opacity={disabled ? 0.4 : 1}
      tabIndex={0}
      onClick={(e) => stopPropagation && e.stopPropagation()}
      onKeyPress={(e: any) => {
        if (e.key === ' ') {
          e.preventDefault();
          e.stopPropagation();
          handleChange(e);
        }
      }}
      $css={{
        '&:focus svg': {
          borderRadius: 3,
          outline: `1px dotted ${colors.blue.primary}`,
          outlineOffset: 1,
        },
      }}
      {...other}
    >
      <Icon
        icon={partial ? partialIcon : checked ? checkedIcon : uncheckedIcon}
        size={19}
        mt={label ? 1 : 0}
        color={disabled && !checked ? colors.grey.primary : other.color || colors.blue.primary}
        {...iconProps}
      />
      {label && (
        <$.span inlineBlock ml={10} mt={-2} userSelect="none" {...labelProps}>
          {label}
        </$.span>
      )}
      <$.input
        type="checkbox"
        display="none"
        checked={checked}
        onChange={handleChange}
        {...inputProps}
      />
    </$.label>
  );
}

interface RHFCheckboxProps extends Omit<CheckboxProps, 'checked'> {
  offValue?: any;
  field: string;
  value?: any;
  /**
   * Controls how the checkbox sets the react-hook-form value
   * flags = sets form.values[field][value] = true
   * array = toggles value in form.values[field] array
   * default = toggles form.values[field] between value & undefined
   */
  behavior?: 'flags' | 'array' | 'default';
  /**
   * This is called with a reference to the handler function used when the user toggles
   * the checkbox. You can use this to programmatically toggle the checkbox.
   * This function may be called several times during a render, so be careful not to cause
   * state changes.
   */
  setChangeHandler?(handler: CheckboxClickHandler): void;
}

export function RHFCheckbox(props: StylixProps<'label', RHFCheckboxProps>) {
  const {
    value = true,
    offValue = value === true ? false : null,
    field,
    behavior = 'default',
    setChangeHandler,
    onChange,
    ...other
  } = props;

  return (
    <Controller
      name={field}
      render={({ field }) => {
        let isChecked = false;

        if (behavior === 'default') {
          isChecked = field.value === value;
        } else if (behavior === 'array') {
          isChecked = Array.isArray(field.value) && field.value.includes(value);
        } else if (behavior === 'flags') {
          isChecked = !!field.value[value];
        }

        const handleChange = (e: any) => {
          const checked = !isChecked;
          let newValue: any;
          if (behavior === 'default') {
            newValue = checked ? value : offValue;
          } else if (behavior === 'array') {
            newValue = [...field.value];
            if (checked) newValue.push(value);
            else newValue = newValue.filter((v: any) => v !== value);
          } else if (behavior === 'flags') {
            newValue = { ...field.value, [value]: checked };
          }
          field.onChange({ target: { value: newValue } });
          onChange?.(e, checked);
        };
        setChangeHandler?.(handleChange);

        return <Checkbox checked={isChecked} onChange={handleChange} {...other} />;
      }}
    />
  );
}

interface KCheckboxProps extends Omit<CheckboxProps, 'checked'> {
  offValue?: any;
  field: string;
  value?: any;
  /**
   * Controls how the checkbox sets the form value.
   * flags = sets form.values[field][value] = true
   * array = toggles value in form.values[field] array
   * default = toggles form.values[field] between value & undefined
   */
  behavior?: 'flags' | 'array' | 'default';
  /**
   * This is called with a reference to the handler function used when the user toggles
   * the checkbox. You can use this to programmatically toggle the checkbox.
   * This function may be called several times during a render, so be careful not to cause
   * state changes.
   */
  setChangeHandler?(handler: CheckboxClickHandler): void;

  onBlur?(e: React.FocusEvent): void;
}

export function KCheckbox(props: StylixProps<'label', KCheckboxProps>) {
  const {
    value = true,
    offValue = value === true ? false : null,
    field,
    behavior = 'default',
    setChangeHandler,
    onChange,
    onBlur,
    ...other
  } = props;

  const f = useKeckField(field, { transform: 'raw', onBlur });
  let isChecked = false;

  if (behavior === 'default') {
    isChecked = f.value === value;
  } else if (behavior === 'array') {
    isChecked = derive(() => Array.isArray(f.value) && f.value.includes(value));
  } else if (behavior === 'flags') {
    isChecked = derive(() => !!(f.value as any)[value]);
  }

  const handleChange = (e: any) => {
    const checked = !isChecked;
    let newValue: any;
    if (behavior === 'default') {
      newValue = checked ? value : offValue;
    } else if (behavior === 'array') {
      newValue = [...(f.value as any[])];
      if (checked) newValue.push(value);
      else newValue = newValue.filter((v: any) => v !== value);
    } else if (behavior === 'flags') {
      newValue = { ...(f.value as any[]), [value]: checked };
    }
    f.props.onChange(newValue);
    onChange?.(e, checked);
  };
  setChangeHandler?.(handleChange);

  return (
    <Checkbox checked={isChecked} onChange={handleChange} onBlur={f.props.onBlur} {...other} />
  );
}
