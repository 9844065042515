import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import { useCombobox, useMultipleSelection } from 'downshift';
import { get, omit, sortBy } from 'lodash-es';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useFormContext, type ControllerProps } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';

import track from 'src/data/mixpanel';
import MultiItem from 'src/ui/Dropdown/ui/MultiItem';
import Spinner from 'src/ui/Spinner';
import { useKeckField } from 'src/util/keck-forms';

import Checkbox from '../Checkbox';
import Icon from '../Icon';
import ClearButton from './ui/ClearButton';
import DownArrow from './ui/DownArrow';
import DropdownWrapper from './ui/DropdownWrapper';
import MenuItem from './ui/MenuItem';
import MenuWrapper from './ui/MenuWrapper';

const allOption = Symbol('all');

export interface MultiDropdownProps<TOption> {
  options: readonly TOption[] | undefined;
  value: TOption[];
  allLabel?: string;
  onChange: (value: TOption[], isAll: boolean) => void;
  getOptionValue?: (option: TOption) => string | number | boolean | symbol | null | undefined;
  renderOption?: (option: TOption) => React.ReactNode;
  renderSelectedOption?: (option: TOption) => React.ReactNode;
  getSearchValue?: (option: TOption) => string;
  isOptionDisabled?: (option: TOption) => boolean;
  placeholder?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  noOptionsLabel?: React.ReactNode;
  /**
   * The defaultValue is used when the user clears the input (if isClearable is true).
   * When the defaultValue is selected, no clear button is shown.
   */
  defaultValue?: TOption[];
  trackingLabel?: string;
  trackingContext?: string;
}

export function MultiDropdown<TOption>(props: StylixProps<'div', MultiDropdownProps<TOption>>) {
  const {
    options,
    value,
    onChange,
    allLabel,
    getOptionValue: _getOptionValue = (option) => option?.toString() || '',
    renderOption = (option) => option?.toString() || '',
    renderSelectedOption = renderOption,
    getSearchValue = (option) => renderOption(option)?.toString?.() || '',
    isOptionDisabled = () => false,
    isClearable = false,
    placeholder = 'Select...',
    isLoading = false,
    isDisabled = false,
    hasError = false,
    noOptionsLabel = 'No options',
    defaultValue = [],
    trackingLabel,
    trackingContext,
    ...other
  } = props;

  const isOptionVisible = (option: TOption, inputValue: string | undefined) => {
    return !inputValue || getSearchValue(option).toLowerCase().includes(inputValue.toLowerCase());
  };

  const getOptionValue = (option: TOption | typeof allOption) => {
    if (option === allOption) return allLabel;
    return _getOptionValue(option);
  };

  const [filteredOptions, setFilteredOptions] = useState<readonly (TOption | typeof allOption)[]>(
    options || [],
  );

  const isAllSelected = !!allLabel && !!options?.length && value?.length === options.length;

  const defaultIsAll =
    options &&
    options.length === defaultValue?.length &&
    options.every((o) => defaultValue.find((v) => getOptionValue(o) === getOptionValue(v)));

  const isDefaultSelected =
    (!value?.length && !defaultValue?.length) ||
    (value?.length === defaultValue?.length &&
      value.every((v) => defaultValue.find((d) => getOptionValue(v) === getOptionValue(d))));

  const {
    getSelectedItemProps,
    getDropdownProps,
    setSelectedItems,
    addSelectedItem,
    removeSelectedItem,
  } = useMultipleSelection<any>({
    selectedItems: isAllSelected ? [allOption] : value,
    itemToString: (item) => getOptionValue(item) as any,
    stateReducer: (state, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem: {
          // Downshift is buggy when typing into the input, so we're forcing removal
          return {
            ...actionAndChanges.changes,
            selectedItems: actionAndChanges.changes.selectedItems?.filter(
              (item) => getOptionValue(item) !== getOptionValue(actionAndChanges.selectedItem),
            ),
          };
        }
      }
      return actionAndChanges.changes;
    },
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
          if (newSelectedItems?.includes(allOption)) {
            onChange((options || []) as TOption[], true);
          } else if (!newSelectedItems?.length) {
            // When nothing is selected, select the defaultValue
            onChange(defaultValue, false);
          } else {
            onChange(
              sortBy(newSelectedItems, (item) =>
                options?.findIndex((o) => getOptionValue(o) === getOptionValue(item)),
              ) || [],
              false,
            );
          }
          break;
      }
    },
  });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    inputValue,
    highlightedIndex,
    setHighlightedIndex,
    setInputValue,
    toggleMenu,
    openMenu,
  } = useCombobox<TOption | typeof allOption | null>({
    onInputValueChange({ inputValue }) {
      setFilteredOptions([
        ...(allLabel && !inputValue ? [allOption] : []),
        ...(options?.filter((option) => isOptionVisible(option, inputValue)) || []),
      ] as (TOption | typeof allOption)[]);
    },
    items: filteredOptions as TOption[],
    itemToString(item) {
      return item === allOption && allLabel
        ? allLabel
        : item
        ? getSearchValue(item as TOption)
        : '';
    },
    onIsOpenChange({ isOpen }) {
      if (isOpen) inputRef?.focus();
    },
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.FunctionToggleMenu:
          return {
            ...changes,
            inputValue: '',
          };

        case useCombobox.stateChangeTypes.InputBlur:
          // Keep the state if the input blurs due to an item being selected
          if (actionAndChanges.changes.selectedItem)
            return {
              ...changes,
              inputValue,
              highlightedIndex: state.highlightedIndex,
              isOpen: true,
            };
          else {
            return {
              ...changes,
              isOpen: false,
            };
          }
          break;

        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          // When item is selected, keep the input value and menu open
          return {
            ...changes,
            // selectedItem: changes.selectedItem === allOption ? null : changes.selectedItem,
            inputValue,
            highlightedIndex: state.highlightedIndex,
            isOpen: true,
          };

        case useCombobox.stateChangeTypes.InputChange:
          // When input value changes, select the first item
          return { ...changes, highlightedIndex: 0 };

        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          return state;
      }
      return changes;
    },
    onStateChange(state) {
      let trackItem: any = null;
      switch (state.type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (state.selectedItem === allOption) {
            if (isAllSelected) {
              setSelectedItems([]);
            } else {
              setSelectedItems([allOption]);
              trackItem = 'All';
            }
          } else if (isAllSelected) {
            // When "all" is selected and another item is chosen, set the selected items to just the chosen item
            setSelectedItems([state.selectedItem]);
            trackItem = state.selectedItem;
          } else if (
            value.find((item) => getOptionValue(item) === getOptionValue(state.selectedItem!))
          ) {
            removeSelectedItem(state.selectedItem!);
          } else {
            addSelectedItem(state.selectedItem!);
            trackItem = state.selectedItem;
          }

          if (trackItem && trackingLabel) {
            track(
              'Dropdown',
              {
                Label: trackingLabel,
                Context: trackingContext,
                Value: trackItem === 'All' ? 'All' : renderOption(trackItem)?.toString(),
                InternalValue: trackItem === 'All' ? 'All' : getOptionValue(trackItem)?.toString(),
              },
              { send_immediately: true },
            );
          }
          break;
      }
    },
  });

  const [controlRef, setControlRef] = useState<HTMLElement | null>(null);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const menuProps = getMenuProps();
  const toggleButtonProps = getToggleButtonProps();
  const inputProps = getInputProps(
    getDropdownProps({ preventKeyAction: isOpen, onBlur: props.onBlur }),
  );

  // When options goes from undefined to non-undefined, select the default value
  useEffect(() => {
    if (options && !value.length) {
      onChange(defaultValue, false);
    }
  }, [options?.length]);

  // When value length changes, redraw the popover to fix positioning
  // useEffect(() => {
  //   setTimeout(() => popperRef.current?.update?.());
  // }, [value.length]);

  // Reset the filtered options when the options change
  useEffect(() => {
    setFilteredOptions([
      ...(allLabel && !inputValue ? [allOption] : []),
      ...(options?.filter((option) => isOptionVisible(option, inputValue)) || []),
    ] as (TOption | typeof allOption)[]);
  }, [options?.length]);

  return (
    <>
      <DropdownWrapper
        isOpen={isOpen}
        isDisabled={isDisabled || isLoading}
        hasError={hasError}
        onKeyDown={(e) => {
          if (isOpen) return;
          switch (e.key) {
            case 'ArrowDown':
            case 'Space':
            case 'Enter':
              e.preventDefault();
              openMenu();
              inputRef?.focus();
              break;

            case 'Delete':
            case 'Backspace':
              e.preventDefault();
              setSelectedItems([]);
              break;

            default:
              if (e.key.length === 1) {
                e.preventDefault();
                openMenu();
                setInputValue(e.key);
                setHighlightedIndex(0);
                inputRef?.focus();
              }
          }
        }}
        onClick={toggleMenu}
        ref={setControlRef}
        {...other}
      >
        <$.flex
          flex-center
          flex="1 1 auto"
          relative
          justifyContent="flex-start"
          p="2px 0 2px 10px"
          overflow="hidden"
          flexWrap="wrap"
          rowGap={1}
          columnGap={2}
        >
          {isAllSelected && allLabel ? (
            <MultiItem
              key="__all__"
              label={allLabel}
              onRemove={defaultIsAll ? undefined : () => removeSelectedItem(allOption)}
            />
          ) : value.length > 0 ? (
            value.map((selectedItem, index) => {
              return (
                <MultiItem
                  key={`${getSearchValue(selectedItem)}-${index}`}
                  {...getSelectedItemProps({ selectedItem, index })}
                  label={renderSelectedOption(selectedItem)}
                  onRemove={() => removeSelectedItem(selectedItem)}
                />
              );
            })
          ) : (
            <$.div color="#0008">{placeholder}</$.div>
          )}
        </$.flex>

        {!isDisabled && !isLoading && isClearable && value.length && !isDefaultSelected ? (
          <ClearButton flex="0 0 auto" onClick={() => setSelectedItems(defaultValue)} />
        ) : null}

        {isLoading ? (
          <$.flex flex-center flex="0 0 auto" width={40}>
            <Spinner flex="0 0 auto" size={20} color="#0009" />
          </$.flex>
        ) : null}

        <DownArrow flex="0 0 auto" {...toggleButtonProps} />
      </DropdownWrapper>

      <MenuWrapper menuProps={menuProps} controlElement={controlRef} isOpen={isOpen}>
        {() => {
          return (
            <>
              {/* search input */}
              <$.flex flex-center borderBottom="1px solid #0002" p="10px 4px 4px 0" m="0 10px 10px">
                <Icon icon={faMagnifyingGlass} color="#0008" flex="0 0 auto" size={14} mr={6} />
                <$.input
                  flex="1 1 auto"
                  border={0}
                  background="transparent"
                  font="inherit"
                  display="block"
                  width="100%"
                  tabIndex={isOpen ? 0 : -1}
                  {...inputProps}
                  ref={mergeRefs([inputProps.ref, setInputRef])}
                />
              </$.flex>

              {filteredOptions.length === 0 ? (
                // no options placeholder
                <MenuItem
                  isHighlighted={false}
                  isSelected={false}
                  isDisabled
                  tabIndex={-1}
                  color="#0006"
                  fontStyle="italic"
                  p="10px"
                >
                  {noOptionsLabel}
                </MenuItem>
              ) : (
                filteredOptions.map((option, index) => {
                  const isSelected =
                    !isAllSelected &&
                    !!value.find((item) => getOptionValue(item) === getOptionValue(option));
                  return option === allOption ? (
                    <MenuItem
                      key="__all__"
                      isHighlighted={highlightedIndex === index}
                      isSelected={isAllSelected}
                      isDisabled={false}
                      {...(getItemProps({ item: option, index }) as Record<string, any>)}
                      tabIndex={-1}
                      p="8px 10px"
                    >
                      <Checkbox
                        checked={isAllSelected}
                        color={isAllSelected ? '#FFF' : undefined}
                        mr={8}
                        iconProps={{ size: 14 }}
                        tabIndex={-1}
                        stopPropagation={false}
                      />
                      {allLabel}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={`${getOptionValue(option)?.toString()}-${index}`}
                      isHighlighted={highlightedIndex === index}
                      isSelected={isSelected}
                      isDisabled={isOptionDisabled(option!)}
                      {...(getItemProps({ item: option, index }) as Record<string, any>)}
                      tabIndex={-1}
                      p="8px 10px"
                    >
                      <Checkbox
                        checked={isAllSelected || isSelected}
                        color={isSelected ? '#FFF' : undefined}
                        mr={8}
                        iconProps={{ size: 14 }}
                        tabIndex={-1}
                        stopPropagation={false}
                      />
                      {renderOption(option)}
                    </MenuItem>
                  );
                })
              )}
            </>
          );
        }}
      </MenuWrapper>
    </>
  );
}

type RHFMultiDropdownProps<OptionType> = Omit<
  MultiDropdownProps<OptionType>,
  'value' | 'onChange'
> & {
  field: string;
  controllerProps?: Partial<ControllerProps<any, any>>;
  onChange?: MultiDropdownProps<OptionType>['onChange'];
  deps?: any[];
};

export function RHFMultiDropdown<OptionType>(
  props: StylixProps<'div', RHFMultiDropdownProps<OptionType>>,
) {
  const { field, controllerProps, deps, ...other } = props;
  const { formState } = useFormContext();

  return (
    <Controller
      name={field}
      rules={{ deps }}
      {...controllerProps}
      render={(f) => (
        <MultiDropdown
          {...omit(f.field, 'ref')}
          hasError={!!get(formState.errors, field)}
          {...other}
          onChange={(value, isAll) => {
            f.field.onChange(value);
            props.onChange?.(value, isAll);
          }}
        />
      )}
    />
  );
}

type VFMultiDropdownProps<OptionType> = Omit<
  MultiDropdownProps<OptionType>,
  'value' | 'onChange'
> & {
  field: string;
  onChange?: MultiDropdownProps<OptionType>['onChange'];
};

export function KMultiDropdown<OptionType>(
  props: StylixProps<'div', VFMultiDropdownProps<OptionType>>,
) {
  const { field, onChange, onBlur, ...other } = props;
  const f = useKeckField<OptionType[]>(field, {
    transform: 'raw',
    onBlur,
  });

  return (
    <MultiDropdown
      hasError={!!f.isTouched && f.isError}
      {...other}
      {...f.props}
      onChange={(value, isAll) => {
        f.props.onChange(value);
        onChange?.(value, isAll);
      }}
    />
  );
}
