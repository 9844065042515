import dayjs from 'dayjs';

type JsonValue = string | number | boolean | null | object | any[];

export interface Serializer {
  shouldSerialize: (value: any) => boolean;
  serialize: (value: any) => JsonValue;
  shouldDeserialize: (value: JsonValue) => boolean;
  deserialize: (value: any) => any;
}

const serializers: Array<{
  shouldSerialize: (value: any, options?: SerializeOptions) => boolean;
  serialize: (value: any, options?: SerializeOptions) => JsonValue;
  shouldDeserialize: (value: JsonValue) => boolean;
  deserialize: (value: any) => any;
}> = [
  // Primitives (not strings)
  {
    shouldSerialize: (value) =>
      typeof value === 'number' || typeof value === 'boolean' || value === null,
    serialize: (value) => value,
    shouldDeserialize: (value) =>
      typeof value === 'number' || typeof value === 'boolean' || value === null,
    deserialize: (value) => value,
  },
  // Dates
  {
    shouldSerialize: (value) => value instanceof Date,
    serialize: (value) => value.toISOString(),
    shouldDeserialize: (value) =>
      typeof value === 'string' && !!value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/),
    deserialize: (value) => new Date(value),
  },
  // undefined
  {
    shouldSerialize: (value) => value === undefined,
    serialize: () => '!u',
    shouldDeserialize: (value) => typeof value === 'string' && !!value.match(/^!+u/),
    deserialize: (value: string) => (value === '!u' ? undefined : value.slice(1)),
  },
  // undefined-looking string
  {
    shouldSerialize: (value) => typeof value === 'string' && /^!+u/.test(value),
    serialize: (value) => `!${value}`,
    shouldDeserialize: (value) => typeof value === 'string' && /^!{2,}u/.test(value),
    deserialize: (value: string) => value.slice(1),
  },
  // Regex
  {
    shouldSerialize: (value) => value instanceof RegExp,
    serialize: (value) => `!r:${value.toString()}`,
    shouldDeserialize: (value) => typeof value === 'string' && value.startsWith('!r:/'),
    deserialize: (value: string) =>
      value.startsWith('!r:/') ? new RegExp(value.slice(3)) : value.slice(1),
  },
  // Regex-looking string
  {
    shouldSerialize: (value) => typeof value === 'string' && /^!+r:\//.test(value),
    serialize: (value) => `!${value}`,
    shouldDeserialize: (value) => typeof value === 'string' && /^!{2,}r:\//.test(value),
    deserialize: (value: string) => value.slice(1),
  },
  // Set
  {
    shouldSerialize: (value) => value instanceof Set,
    serialize: (value: Set<any>, options) => [
      '!s',
      ...[...value.values()].map((value) => megajson.serialize(value, options)),
    ],
    shouldDeserialize: (value) => Array.isArray(value) && value[0] === '!s',
    deserialize: (value: any[]) => new Set(value.slice(1)),
  },
  // Map
  {
    shouldSerialize: (value) => value instanceof Map,
    serialize: (value: Map<any, any>, options) => [
      '!m',
      ...[...value.entries()].map(([key, val]) => [
        megajson.serialize(key, options),
        megajson.serialize(val, options),
      ]),
    ],
    shouldDeserialize: (value) =>
      Array.isArray(value) &&
      value[0] === '!m' &&
      value.slice(1).every((v) => Array.isArray(v) && v.length === 2),
    deserialize: (value: any[]) => new Map(value.slice(1)),
  },
  // Set- or Map-looking array
  {
    // If the value is an array and the first element is looks like a Map or Set prefix (!m, !s),
    // then the first item needs an extra ! to distinguish it from a real map or set when serialized.
    shouldSerialize: (value) =>
      Array.isArray(value) &&
      typeof value[0] === 'string' &&
      (/^!+s/.test(value[0]) ||
        (/^!+m/.test(value[0]) && value.slice(1).every((v) => Array.isArray(v) && v.length === 2))),
    serialize: (value: any[], options) => [
      '!' + value[0],
      ...value.slice(1).map((value) => megajson.serialize(value, options)),
    ],
    shouldDeserialize: (value) =>
      Array.isArray(value) &&
      typeof value[0] === 'string' &&
      (/^!!+s/.test(value[0]) ||
        (/^!!+m/.test(value[0]) &&
          value.slice(1).every((v) => Array.isArray(v) && v.length === 2))),
    deserialize: (value: any[]) => [value[0].slice(1), ...value.slice(1).map(megajson.deserialize)],
  },
  // Array
  {
    shouldSerialize: (value) => Array.isArray(value),
    serialize: (value, options) => value.map((value) => megajson.serialize(value, options)),
    shouldDeserialize: (value) => Array.isArray(value),
    deserialize: (value) => value.map(megajson.deserialize),
  },
  // Error
  {
    shouldSerialize: (value) => value instanceof Error,
    serialize: (value) => ({
      '!e': {
        ...value,
        name: value.name,
        message: value.message,
        stack: value.stack,
      },
    }),
    shouldDeserialize: (value: any) => value?.['!e'] && typeof value?.['!e'] === 'object',
    deserialize: (value) => {
      const error = new Error(value['!e'].message);
      Object.assign(error, value['!e']);
      return error;
    },
  },
  // Object
  {
    shouldSerialize: (value) =>
      typeof value === 'object' && value !== null && value.constructor === Object,
    serialize: (value, options) => {
      const result: any = {};
      for (const key in value) {
        if (value[key] === undefined) {
          if (options?.undefinedKeys === 'omit') continue;
          if (options?.undefinedKeys === 'null') {
            result[key] = null;
            continue;
          }
        }
        result[key] = megajson.serialize(value[key], options);
      }
      return result;
    },
    shouldDeserialize: (value) => typeof value === 'object' && value !== null,
    deserialize: (value) => {
      const result: any = {};
      for (const key in value) {
        result[key] = megajson.deserialize(value[key]);
      }
      return result;
    },
  },
  // Function
  {
    shouldSerialize: (value) => typeof value === 'function',
    serialize: (value) => `!f:${value.toString()}`,
    shouldDeserialize: (value) => typeof value === 'string' && value.startsWith('!f:'),
    deserialize: (value: string) => {
      const fn = new Function(`return (${value.slice(3)})`)();
      if (typeof fn !== 'function') throw new Error(`Could not deserialize function "${value}"`);
      return fn;
    },
  },
  // Function-looking string
  {
    shouldSerialize: (value) => typeof value === 'string' && /^!+f/.test(value),
    serialize: (value) => `!${value}`,
    shouldDeserialize: (value) => typeof value === 'string' && /^!{2,}f/.test(value),
    deserialize: (value: string) => value.slice(1),
  },
  // Strings
  {
    shouldSerialize: (value) => typeof value === 'string',
    serialize: (value) => value,
    shouldDeserialize: (value) => typeof value === 'string',
    deserialize: (value) => value,
  },
  // Everything else
  {
    shouldSerialize: () => false,
    serialize: () => undefined, // This will never be called; results in an exception
    shouldDeserialize: () => true,
    deserialize: (value) => value,
  },
];

const dontSerializeTypes = ['number', 'boolean', 'bigint', 'symbol'];

export interface SerializeOptions {
  undefinedRoot?: 'omit' | 'null' | 'preserve';
  undefinedKeys?: 'omit' | 'null' | 'preserve';
}

const megajson = {
  serialize(value: any, options?: SerializeOptions) {
    if (value === undefined) {
      if (options?.undefinedRoot === 'omit') return;
      if (options?.undefinedRoot === 'null') return null;
    }
    if (dontSerializeTypes.includes(typeof value) || value === null) return value;
    for (const serializer of serializers) {
      if (serializer.shouldSerialize(value, options)) return serializer.serialize(value, options);
    }
    if (value?.toJSON) return value.toJSON();
    try {
      return JSON.parse(JSON.stringify(value));
    } catch (e: any) {
      throw new Error(`Could not serialize ${value}`);
    }
  },
  stringify(value: any, options?: SerializeOptions) {
    if (value === undefined && options?.undefinedRoot === 'omit') return;
    return JSON.stringify(megajson.serialize(value, options));
  },

  deserialize(value: any) {
    if (dontSerializeTypes.includes(typeof value) || value === null) return value;
    for (const { shouldDeserialize, deserialize } of serializers) {
      if (shouldDeserialize(value)) return deserialize(value);
    }
  },
  parse<T = any>(value: string, fallback?: T): T | undefined {
    try {
      return megajson.deserialize(JSON.parse(value));
    } catch (e) {
      if (fallback !== undefined) return fallback;
      throw e;
    }
  },

  extend: (serializer: Serializer[]) => {
    serializers.unshift(...serializer);
  },

  withOptions: (options: SerializeOptions) => {
    return {
      serialize: (value: any) => megajson.serialize(value, options),
      stringify: (value: any) => megajson.stringify(value, options),
      deserialize: megajson.deserialize,
      parse: megajson.parse,
    };
  },
};

export default megajson;

megajson.extend([
  {
    shouldSerialize: (value) => dayjs.isDayjs(value),
    serialize: (value) => (value.isValid() ? value.toISOString() : value.toString()),
    shouldDeserialize: (value) =>
      typeof value === 'string' && !!value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/),
    deserialize: (value) => dayjs(value),
  },
]);
