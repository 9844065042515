import $, { type Stylix$elPropOptional, type StylixProps } from '@stylix/core';
import React from 'react';

interface LabelProps {
  label: React.ReactNode;
  disableIndent?: boolean; // True to disable the default 8px left padding on label text
  labelTextProps?: StylixProps<'label'>;
  small?: boolean;
  footer?: React.ReactNode;
  children?: any;
}

export default function LabelWrapper<T extends React.ElementType = 'div'>(
  props: Stylix$elPropOptional<T> & StylixProps<'div', LabelProps>,
) {
  const { label, disableIndent, labelTextProps, footer, small, children, ...other } = props;
  return (
    <$.div display="inline-block" line-height={1.4} {...other}>
      <$.label
        display="block"
        m={disableIndent ? '0 0 4px' : '0 8px 4px 8px'}
        color="#0007"
        font-weight={400}
        font-size={small ? 13 : 15}
        letter-spacing={small ? '0.04em' : '0.02em'}
        {...labelTextProps}
      >
        {label}
      </$.label>
      {children}
      {footer && (
        <$.div color="#888" size={13} m="4px 0 0 8px">
          {footer}
        </$.div>
      )}
    </$.div>
  );
}
