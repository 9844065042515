import { Position } from '@blueprintjs/core';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useClickOutside } from '@react-hookz/web';
import $ from '@stylix/core';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import Icon from 'src/ui/Icon';
import { Popover } from 'src/ui/Popover';

import colors from './colors';
import createGlobalState from './createGlobalState';

const useContextMenuGlobalState = createGlobalState<{
  element: React.ReactNode | undefined;
  e: React.MouseEvent | undefined;
}>({ element: undefined, e: undefined });

export function useShowContextMenu() {
  const [, setCtxMenu] = useContextMenuGlobalState();

  return (e: React.MouseEvent, content: React.ReactNode) => {
    setCtxMenu({
      e,
      element: content,
    });
  };
}

export function ContextMenu(props: { children: any }) {
  return (
    <$.div width={200} p="4px 0">
      {props.children}
    </$.div>
  );
}

interface ContextMenuItemProps {
  icon: IconDefinition;
  title: React.ReactNode;
  onClick(): void;
}

export function ContextMenuItem(props: ContextMenuItemProps) {
  const [, setCtxMenu] = useContextMenuGlobalState();

  return (
    <$.flex
      flex-center
      justifyContent="flex-start"
      cursor="pointer"
      padding="10px 10px 10px 4px"
      $css={{ '&:hover': { background: colors.blue.light } }}
      onClick={() => {
        props.onClick();
        setCtxMenu({ element: undefined, e: undefined });
      }}
    >
      <$.flex flex-center flex="0 0 auto" width={36}>
        <Icon icon={props.icon} fontSize={16} />
      </$.flex>
      <$.div>{props.title}</$.div>
    </$.flex>
  );
}

export function ContextMenuRenderer() {
  const [ctxMenu, setCtxMenu] = useContextMenuGlobalState();

  const ref = useRef<HTMLDivElement | null>(null);
  useClickOutside(ref, () => {
    setCtxMenu({ element: undefined, e: undefined });
  });

  return ReactDOM.createPortal(
    <$.div
      position="fixed"
      top={ctxMenu.e?.clientY || 0}
      left={ctxMenu.e?.clientX || 0}
      width={1}
      height={1}
      data-test="context-menu-wrapper"
      $css={{
        '.bp4-popover2-target': {
          display: 'block',
          width: 1,
          height: 1,
        },
      }}
    >
      <Popover
        content={
          <$.div data-test="context-menu-content-wrapper" ref={ref}>
            {ctxMenu.element}
          </$.div>
        }
        position={Position.BOTTOM_LEFT}
        isOpen={!!ctxMenu.element}
        offset={[0, 0]}
        minimal
        transformOrigin="0 0 !important"
      >
        <$.div width={1} height={1} data-test="context-menu-position" />
      </Popover>
    </$.div>,
    document.body,
    'ContextMenu',
  );
}
