import axiosLib from 'axios';

import { getDebugUser } from 'src/data/debugUser';
import { isOktaEnv, oktaAuth } from 'src/data/oktaAuth';

import config from '~/config';

export function getAuthHeaders(url?: string) {
  const headers: any = {};

  const isCancerLinQUrl = Object.values(config.apiBase).some(
    (apiUrl) => !!apiUrl && url?.startsWith(apiUrl),
  );

  if (isOktaEnv()) {
    if (!url || isCancerLinQUrl) headers['Authorization'] = `Bearer ${oktaAuth.getAccessToken()}`;
  } else {
    // Add headers for local development normally added by AWS's custom_authorizer
    const debugUser = getDebugUser();
    headers['clq-auth-localroles'] = debugUser?.roles.join(',');
    headers['clq-auth-user'] = debugUser?.email;
  }

  return headers;
}

/*
  Instantiated Axios implementation to let us control the inteceptors of all request made through Axios
  Primarily to add Okta Auth tokens to all outbound headers automatically
*/
const axios = axiosLib.create();

axios.interceptors.request.use(
  (axiosConfig) => {
    return {
      ...axiosConfig,
      headers: axiosConfig.headers.concat(getAuthHeaders(axiosConfig.url)),
    };
  },
  (error) => Promise.reject(error),
);

export default axios;
