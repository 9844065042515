const colors = {
  red: {
    neutral: '#ee7c73', // 1
    primary: '#F2584C', // 16
    medium: '#c33840', // 19
    dark: '#901a1e', // 5
  },
  blue: {
    lighter: '#f2f6f9', // 1
    light: '#eaf2f8', // 1
    lightActive: '#dbe7f1', // 1
    neutral: '#00447c', // 13
    primary: '#359be0', // 77
    primaryLightBg: '#359be00f',
    primaryDark: '#2489CD', // 2
    primaryHover: '#2a7eb6', // 9
    mid_dark: '#4c9cd2', // 2
    lineGraph: '#7cc2f1', // 7
    darkButLight: '#2c4f61', // 1
    summerSky: '#359be0', // 3
    mayaBlue: '#65cbf2', // 3
  },
  grey: {
    light: '#e0e1e1', // 1
    neutral: '#4a4a4a', // 25
    primary: '#9b9b9b', // 54
    disabled: '#cecdcf', // 16
    disabledBorder: '#e8e9eb', // 14
    disabledBackground: '#f6f6f7', // 5
    hoverBg: '#0000000a', // 5
    dark: '#2c4051', // 2
    blue: '#e2eaf0',
    soMany: '#bbb9bb',
  },
  white: {
    primary: '#fff',
  },
  black: {
    neutral: '#444',
    primary: '#000',
  },
  green: {
    primary: '#72C49C',
    dark: '#54A27C',
  },
  orange: {
    primary: '#EEB24E', // Not contrasty enough for white text
    medium: '#C88614', // Contrasty enough for white text
    brand: '#C17130',
    dark: '#BD862A',
  },
  yellow: {
    gold: '#f4cc68',
    primary: '#ffcf80',
  },
};

export const primaryOrDisabledColor = (
  isEnabled,
  primaryColor = colors.blue.primary,
  disabledColor = colors.grey.disabled,
) => {
  return isEnabled ? primaryColor : disabledColor;
};

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const MAX_COLOR = 255;
const MIN_COLOR = 0;

const changeColorToMinimumOrMaximum = (color) => {
  if (color > MAX_COLOR) {
    color = MAX_COLOR;
  }
  if (color < MIN_COLOR) {
    color = MIN_COLOR;
  }
  return color;
};
/**
 * Uses hex values. Positive values to lighten, negative values to darken.
 * @param {string} color
 * @param {string|number} amount
 * @param {boolean} toRGBA
 */
export const lightenOrDarkenColor = (color, amount, toRGBA = false) => {
  if (Number(amount) === 0) {
    return color;
  }
  let usePound = false;

  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);
  const red = changeColorToMinimumOrMaximum((num >> 16) + amount);
  const blue = changeColorToMinimumOrMaximum(((num >> 8) & 0x00ff) + amount);
  const green = changeColorToMinimumOrMaximum((num & 0x0000ff) + amount);
  const returnValue = (usePound ? '#' : '') + (green | (blue << 8) | (red << 16)).toString(16);
  if (toRGBA) {
    return hex2rgba(returnValue);
  }
  return returnValue;
};

export default colors;
