import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import $, { type StylixProps } from '@stylix/core';
import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';

import Button from 'src/ui/Button';
import Icon from 'src/ui/Icon';
import { useKeckField } from 'src/util/keck-forms';

import colors from './colors';

type TextInputProps = StylixProps<
  'div',
  {
    value?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    textarea?: boolean;
    error?: boolean;
    disabled?: boolean;
    placeholder?: string;
    prefix?: React.ReactNode;
    icon?: IconDefinition;
    iconProps?: Omit<StylixProps<typeof Icon>, 'icon'>;
    actionIcon?: IconDefinition;
    actionButtonProps?: StylixProps<typeof Button>;
    onActionClick?: (e: React.UIEvent, input: HTMLInputElement | HTMLTextAreaElement) => void;
    readOnly?: boolean;
    maxLength?: number;
    inputProps?: StylixProps<'input'> | StylixProps<'textarea'>;
    inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
    tabIndex?: number;
    children?: any;
  }
>;

export const TextInput = React.forwardRef(function TextInput(
  props: TextInputProps,
  ref: React.ForwardedRef<React.ElementRef<'div'>>,
) {
  const {
    textarea,
    prefix,
    icon,
    iconProps,
    actionIcon,
    actionButtonProps,
    onActionClick,
    error,
    disabled,
    placeholder,
    children,
    value,
    onChange,
    readOnly,
    maxLength,
    inputProps,
    tabIndex,
    inputRef: inputRefProp,
    ...other
  } = props;

  const commonElementProps = {
    'background': 'none',
    '&::placeholder': {
      color: '#0008',
    },
    'flex': '1 1 auto',
    'align-self': 'stretch',
    'border': 0,
    'width': '100%',
    'value': value ?? '',
    onChange,
    readOnly,
    maxLength,
    disabled,
    tabIndex,
  };

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const inputRefsMerged = mergeRefs([inputRefProp, inputRef].filter(Boolean) as any);

  return (
    <$.div
      display="inline-flex"
      alignItems="center"
      justifyContent="flex-start"
      column-gap={7}
      border="1px solid #CCC"
      borderColor={error ? colors.red.medium : '#CCC'}
      background={disabled ? '#f8f8f8' : 'white'}
      borderRadius={4}
      padding={actionIcon ? '0 2px 0 10px' : textarea ? '6px' : '0 10px'}
      height={textarea ? 'auto' : 40}
      minWidth={10}
      width="100%"
      className={disabled ? 'disabled' : undefined}
      cursor={disabled ? 'not-allowed' : 'text'}
      onClick={(e) => {
        props.onClick?.(e);
        (
          e.currentTarget.querySelector('input, textarea') as HTMLInputElement | HTMLTextAreaElement
        )?.focus();
      }}
      transition="border-color 0.1s linear"
      $css={{
        '&:not(.disabled)': {
          '&:focus, &:focus-within': {
            borderColor: error ? colors.red.medium : colors.blue.primary,
          },
          '&:hover:not(:focus, :focus-within)': {
            borderColor: error ? colors.red.medium : '#0006',
          },
        },
        '&.disabled': {
          background: '#f8f8f8',
        },
      }}
      ref={ref}
      {...other}
    >
      {children || (
        <>
          {icon ? <Icon icon={icon} color="#0006" flex="0 0 auto" {...iconProps} /> : null}
          {prefix}

          {textarea ? (
            <$.textarea
              {...commonElementProps}
              {...(inputProps as StylixProps<'textarea'>)}
              ref={inputRefsMerged as React.Ref<HTMLTextAreaElement>}
            />
          ) : (
            <$.input
              type="text"
              placeholder={placeholder}
              {...commonElementProps}
              {...(inputProps as StylixProps<'input'>)}
              ref={inputRefsMerged as React.Ref<HTMLInputElement>}
              onKeyDown={(e) => {
                // If there's an action button, prevent submitting and simulate actionbutton click
                if (actionIcon && e.key === 'Enter') {
                  e.preventDefault();
                  onActionClick?.(e, inputRef!.current!);
                }
                (inputProps as StylixProps<'input'>)?.onKeyDown?.(e);
              }}
            />
          )}

          {actionIcon && (
            <Button
              faIcon={actionIcon}
              variant="round-ghost"
              flex="0 0 auto"
              disabled={disabled}
              {...actionButtonProps}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                actionButtonProps?.onClick?.(e);
                onActionClick?.(e, inputRef!.current!);
              }}
            />
          )}
        </>
      )}
    </$.div>
  );
});

export type RHFTextInputProps = {
  field: string;
  deps?: string[];
};

export function RHFTextInput(props: Omit<TextInputProps, 'value'> & RHFTextInputProps) {
  const { field, deps, ...other } = props;

  return (
    <Controller
      name={field}
      rules={{ deps }}
      render={({ field, fieldState }) => {
        return (
          <TextInput
            error={!!fieldState.error}
            {...field}
            {...(other as any)}
            onBlur={(e) => {
              props.onBlur?.(e);
              field.onBlur();
            }}
          />
        );
      }}
    />
  );
}

interface KTextInputProps {
  field: string;
  transform?: 'text' | 'number' | 'raw' | ((value: string) => string);
}

export function KTextInput(props: Omit<TextInputProps, 'value'> & KTextInputProps) {
  const { field, transform, onBlur, onChange, ...other } = props;
  const f = useKeckField(field, { transform, onBlur, onChange });

  return <TextInput error={!!f.isTouched && !!f.isError} {...(other as any)} {...f.props} />;
}
