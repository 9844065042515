import $, { useKeyframes, type StylixProps } from '@stylix/core';
import React from 'react';

import colors from './colors';

interface SpinnerProps {
  size?: number;
  color?: string;
  gapColor?: string;
}

export default function Spinner({
  size = 50,
  color = colors.blue.primary,
  gapColor = 'transparent',
  ...styles
}: StylixProps<'div', SpinnerProps>) {
  const strokeWidth = 2.5;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  const spinAnimation = useKeyframes({
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  });

  return (
    <$.div
      width={size}
      height={size}
      $css={{
        svg: {
          animation: `${spinAnimation} infinite 0.75s linear`,
        },
      }}
      {...styles}
    >
      <svg width="100%" height="100%" viewBox={`0 0 ${size} ${size}`} style={{ display: 'block' }}>
        {/* background ring */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="transparent"
          stroke={gapColor}
          strokeWidth={strokeWidth}
        />

        {/* segment arc */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="transparent"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference * 0.7} ${circumference * (1 - 0.7)}`}
          strokeDashoffset={circumference * 0.25}
        />
      </svg>
    </$.div>
  );
}
