import create from 'zustand/esm';

export default function createGlobalState<T>(initialValue: T) {
  const state = create<{ value: T; setValue(value: React.SetStateAction<T>): void }>((set) => ({
    value: initialValue,
    setValue: (value: React.SetStateAction<T>) => {
      const valueFunc = value as (prevState: T) => T;
      const valueValue = value as T;
      set((state) => ({
        value: typeof value === 'function' ? valueFunc(state.value) : valueValue,
      }));
    },
  }));
  return function useGlobalState() {
    return state((state) => [state.value, state.setValue] as const);
  };
}
