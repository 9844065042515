import { faRandom, faUserCog } from '@fortawesome/pro-solid-svg-icons';
import $ from '@stylix/core';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import { type Role } from 'api_measure/lib/roles';
import { DateFormat, formatDate } from 'api_measure/shared/time';

import useLogOut from 'src/App/useLogOut';
import { useDebugUser } from 'src/data/debugUser';
import { trpc } from 'src/data/trpc';
import Button from 'src/ui/Button';
import colors from 'src/ui/colors';
import ConfirmModal from 'src/ui/ConfirmModal';
import Icon from 'src/ui/Icon';
import useLoadingModal from 'src/ui/useLoadingModal';

interface OrgSwitcherConfirmDialogProps {
  isOpen: boolean;
  onClose(): void;
  selectedOrg?: { name: string; sender_code: string };
  ous?: number[];
  roles?: Role[];
}

export default function OrgSwitcherConfirmDialog(props: OrgSwitcherConfirmDialogProps) {
  const { isOpen, onClose, selectedOrg, ous, roles } = props;

  const orgSwitch = trpc.startOrgSwitcher.useMutation();

  const { withLoadingModal } = useLoadingModal();
  const logOut = useLogOut();
  const { resetDebugRoles } = useDebugUser();

  const submitOrgSwitcher = async () => {
    onClose();
    withLoadingModal('Starting org switcher session...', async () => {
      try {
        await orgSwitch.mutateAsync({ org: selectedOrg!.sender_code, ous: ous!, roles: roles! });
        resetDebugRoles([]);
        await logOut({ track: false, overlay: false });
      } catch (e) {
        setShowErrorModal(true);
      }
    });
  };

  const [showErrorModal, setShowErrorModal] = useState(false);

  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        color="warning"
        title="Confirm Org Switch"
        maxWidth={600}
        icon={faRandom}
        renderButtons={() => (
          <>
            <Button variant="ghost" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="blue" onClick={submitOrgSwitcher}>
              Start Org Switcher
            </Button>
          </>
        )}
      >
        <$.div alignLeft>You are temporarily switching your account's practice to:</$.div>
        <$.div bold alignCenter p="20px 0" fontSize={18}>
          {selectedOrg?.name} ({selectedOrg?.sender_code})
        </$.div>

        <$.div mb={20} alignLeft>
          You will be logged out now, and your account will be associated with this practice,
          organization units, and user roles until{' '}
          <b>
            {formatDate(
              dayjs().tz('America/New_York').endOf('day'),
              DateFormat.dateTimeSlash,
              'EST',
            )}{' '}
            EST
          </b>
          . You may also end your org switcher session manually using the{' '}
          <Icon icon={faUserCog} fontSize={16} m="0 4px" /> icon in the navigation menu.
        </$.div>

        <$.div mb={20} alignLeft>
          Please note that this action will be logged for auditing purposes.
        </$.div>

        <$.div mb={30} alignLeft fontWeight={500} color={colors.red.medium}>
          If you have the application open in other tabs, please close or refresh them before
          continuing.
        </$.div>
      </ConfirmModal>

      <ConfirmModal
        isOpen={showErrorModal}
        color="error"
        title="Org Switcher Error"
        icon={faRandom}
        renderButtons={() => <Button onClick={() => setShowErrorModal(false)}>Okay</Button>}
      >
        <$.div alignLeft mb={20}>
          An error occurred while changing your account's org assignment. Your org assignment has
          not been changed.
        </$.div>
        <$.div alignLeft mb={30}>
          Please try again. If the problem persists, please contact an administrator.
        </$.div>
      </ConfirmModal>
    </>
  );
}
