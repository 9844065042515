import dayjs, { type Dayjs } from 'dayjs';

import { allRoles, type Role } from 'api_measure/lib/roles';
import { type Provider } from 'api_measure/web/routes/providers/getProviders';

import { storageParser, useLocalStorageState } from 'src/ui/storageHooks';

import config from '~/config';

/**
 * Gets the user's date.
 * Allows for overriding the date for debugging/testing purposes by setting a localStorage value for 'debug-date'
 */
export const getUserDate = () => getDebugUser()?.date || dayjs();

export interface DebugUser {
  roles: Role[];
  email: string;
  date: Dayjs | null;
  provider: Provider | null;
}

/**
 * Returns the DebugUser object in localStorage. You should *only* use this if you can't use the react hook.
 */
export function getDebugUser() {
  const debugUser = localStorage.getItem('debug-user');
  try {
    return storageParser.parse<DebugUser>(debugUser || 'null') || null;
  } catch (e) {
    return null;
  }
}

export const canDebug = () => config.canDebug || !!localStorage.getItem('debug-823756');

export function useDebugUser(): {
  debugUser: DebugUser | null;
  setDebugUser: (user: DebugUser | ((user: DebugUser | null | undefined) => DebugUser)) => void;
  resetDebugRoles: (roles: Role[]) => void;
} {
  const [debugUser, setDebugUser] = useLocalStorageState<DebugUser | null>('debug-user', {
    defaultValue: null,
  });

  if (debugUser && !debugUser.roles) {
    setDebugUser((du) => ({ ...du!, roles: [] }));
  }

  if (debugUser?.roles?.some((role) => !allRoles.includes(role))) {
    setDebugUser((du) => ({ ...du!, roles: du!.roles.filter((role) => allRoles.includes(role)) }));
  }

  return canDebug()
    ? {
        debugUser,
        setDebugUser,
        resetDebugRoles: (roles: Role[]) => {
          setDebugUser((du) => ({
            ...du!,
            roles,
          }));
        },
      }
    : {
        debugUser: null,
        setDebugUser: () => null,
        resetDebugRoles: () => null,
      };
}
