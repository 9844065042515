import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import $, { type Extends, type StylixStyleProps } from '@stylix/core';

export type IconProps = Extends<
  Extends<FontAwesomeIconProps, StylixStyleProps>,
  { size?: string | number }
>;

export default function Icon(props: IconProps) {
  const { size = undefined, ...other } = props;
  return <$ $el={FontAwesomeIcon} width={size} height={size} {...other} />;
}
