import { faExternalLinkSquare } from '@fortawesome/pro-regular-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import { type To } from 'history';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ContextMenu, ContextMenuItem, useShowContextMenu } from './ContextMenu';

export type CardProps = StylixProps<
  'div',
  {
    onClick?(e: React.MouseEvent): void;
    linkTo?: To;
    disabled?: boolean;
  }
>;

// Exported so other components that want to add additional transitions can keep the drop shadow transition
export const cardTransition = 'box-shadow 200ms ease-out, transform 200ms ease-out';

export default React.forwardRef<HTMLDivElement, CardProps>(function Card(props, ref) {
  const { onClick, linkTo, disabled, ...rest } = props;

  const history = useHistory();

  const showContextMenu = useShowContextMenu();

  return (
    <$.div
      ref={ref}
      bgColor="white"
      boxShadow="0 2px 6px #0000002C"
      transition={cardTransition}
      borderRadius={6}
      padding={20}
      opacity={disabled ? 0.5 : 1}
      pointer-events={disabled ? 'none' : 'auto'}
      $css={{
        '&:hover':
          onClick || linkTo
            ? {
                cursor: 'pointer',
                boxShadow: '0 2px 6px #0000002C, 0 5px 15px #0002',
                transform: 'translateY(-1px) scale(1.01)',
              }
            : null,
      }}
      onContextMenu={useCallback(
        (e: any) => {
          if (!linkTo) return;
          e.preventDefault();
          showContextMenu(
            e,
            <ContextMenu>
              <ContextMenuItem
                onClick={() => {
                  window.open(history.createHref(linkTo as any));
                }}
                icon={faExternalLinkSquare}
                title="Open in new window"
              />
            </ContextMenu>,
          );
        },
        [linkTo, history],
      )}
      onClick={useCallback(
        (e: any) => {
          onClick && onClick(e);
          if (linkTo) {
            if (e.metaKey) window.open(history.createHref(linkTo as any));
            else history.push(linkTo as any);
          }
        },
        [linkTo, onClick, history],
      )}
      {...rest}
    />
  );
});
