import $, { customProps, styled, type StylixStyledComponent } from '@stylix/core';
import { type CSSProperties } from 'react';

export const customStylixProps = customProps({
  column: { flexDirection: 'column' },
  align: 'align-items',
  justify: 'justify-content',
  size: 'font-size',
  wrap: 'flex-wrap',
  weight: 'font-weight',
  vAlign: 'vertical-align',
  alignCenter: { textAlign: 'center' },
  alignRight: { textAlign: 'right' },
  alignLeft: { textAlign: 'left' },
  alignJustify: { textAlign: 'justify' },
  loadingSkeleton: {
    textIndent: '-100vw',
    animation: `SkeletonBg-darken infinite alternate 0.7s ease-in-out`,
    borderRadius: 4,
  },
});

declare module '@stylix/core' {
  interface StylixPropsExtensions {
    column?: boolean;
    align?: CSSProperties['alignItems'];
    justify?: CSSProperties['justifyContent'];
    size?: CSSProperties['fontSize'];
    wrap?: CSSProperties['flexWrap'];
    weight?: CSSProperties['fontWeight'];
    vAlign?: CSSProperties['verticalAlign'];
    alignCenter?: boolean; // textAlign="center"
    alignRight?: boolean; // textAlign="right"
    alignLeft?: boolean; // textAlign="left"
    alignJustify?: boolean; // textAlign="justify"
    bgColor?: CSSProperties['backgroundColor'];
    flexBox?: boolean;
  }

  interface Stylix$Component {
    flex: StylixStyledComponent<'div'>;
  }
}

$.flex = styled('div', { display: 'flex' });
