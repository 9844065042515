import { Menu as BPMenu, MenuItem, PopoverInteractionKind } from '@blueprintjs/core';
import {
  faBuilding,
  faCaretDown,
  faChartLine,
  faFileMedicalAlt,
  faHome,
  faSignOutAlt,
  faToolbox,
} from '@fortawesome/pro-solid-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import React, { useCallback, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { Role } from 'api_measure/lib/roles';
import { OrgFeature } from 'api_measure/web/routes/practice/practice.shared';

import PlatformStatusMenuIcon from 'src/Admin/Platform/PlatformStatusMenuIcon';
import useLogOut from 'src/App/useLogOut';
import { canDebug } from 'src/data/debugUser';
import track from 'src/data/mixpanel';
import { useHasOrgFeature } from 'src/data/user/roles';
import colors from 'src/ui/colors';
import { useResetGlobalFilters } from 'src/ui/GlobalFilters/useGlobalFilters';
import Icon from 'src/ui/Icon';
import { Popover } from 'src/ui/Popover';
import RequireRoles from 'src/ui/RequireRoles';

import DevMenu from './DevMenu';
import logo from './logo.svg';
import UserMenu from './UserMenu';

export const Menu = (props: StylixProps) => <$ $el={BPMenu} p="6px 0" {...props} />;

export function MenuLink({
  to,
  text,
  onClick,
  requireRoles,
  requireOrgFeatures,
  ...other
}: StylixProps<
  'div',
  {
    to?: string;
    text: string;
    onClick?: () => void;
    requireRoles?: Role[];
    requireOrgFeatures?: OrgFeature[];
  }
>) {
  const history = useHistory();
  const location = useLocation();
  const hasFeature = useHasOrgFeature();

  if (requireOrgFeatures?.length && !hasFeature(...requireOrgFeatures)) return null;

  return (
    <RequireRoles any={requireRoles}>
      <$
        $el={<MenuItem href={to} text={text} />}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          track('Navigation Menu', { Label: text });
          onClick?.();
          if (to && e.metaKey) window.open(to);
          else if (to && location.pathname !== to) {
            history.push(to);
          }
        }}
        fontSize={15}
        p="8px 30px 8px 20px"
        $css={{
          '&:hover': {
            color: 'inherit',
            textDecoration: 'inherit',
          },
        }}
        {...other}
        data-test="navbar-menu-link"
      />
    </RequireRoles>
  );
}

function NavMenu(
  p: StylixProps & {
    selected?: boolean;
    icon: any;
    label: string;
    href?: string;
    children?: any;
    linkProps?: StylixProps;
    requireRoles?: Role[];
    excludeRoles?: Role[];
    internalOnly?: boolean;
  },
) {
  const {
    selected,
    icon,
    label,
    href,
    children,
    linkProps,
    requireRoles,
    excludeRoles,
    internalOnly,
    onClick,
    ...rest
  } = p;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const mainLink = (
    <$.div p="24px 25px 0" data-test="navbar-menu" {...linkProps}>
      <$.flex
        flex-center
        p="0 4px 12px"
        relative
        cursor="pointer"
        $css={{
          '&:after': {
            content: "''",
            position: 'absolute',
            bottom: '-2.5px',
            left: 0,
            right: 0,
            height: '4px',
            background: selected ? '#c17130' : isMenuOpen ? '#DDD' : 'transparent',
            borderRadius: '2px',
            transition: 'all 0.1s linear',
          },
          '&:hover:after': !selected && {
            background: '#DDD',
          },
        }}
      >
        <Icon icon={icon} size={16} />
        <$.span inlineBlock ml={10}>
          {label}
        </$.span>
        {children && <Icon icon={faCaretDown} ml={10} />}
      </$.flex>
    </$.div>
  );

  return (
    <RequireRoles any={requireRoles} exclude={excludeRoles} internalOnly={internalOnly}>
      <$
        $el={href ? <NavLink to={href} /> : 'div'}
        block
        cursor="pointer"
        color="inherit"
        $css={{
          '&:hover': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
        onClick={(e) => {
          if (href) track('Navigation Menu', { Label: label });
          onClick?.(e);
        }}
        {...rest}
      >
        {children ? (
          <Popover
            usePortal={false}
            interactionKind={PopoverInteractionKind.CLICK}
            content={<Menu>{children}</Menu>}
            placement="bottom-start"
            onOpening={() => setIsMenuOpen(true)}
            onClosing={() => setIsMenuOpen(false)}
            offset={[25, 1]}
            transformOrigin="0 0 !important"
            borderRadius="0 0 4px 4px"
            boxShadow="0 10px 20px #0003, 0 4px 4px #0002"
          >
            {mainLink}
          </Popover>
        ) : (
          mainLink
        )}
      </$>
    </RequireRoles>
  );
}

export default function NavBar() {
  const history = useHistory();
  const logOut = useLogOut();

  const isSelectedPage = (page: string, exact = false) =>
    exact ? history.location.pathname === page : history.location.pathname.startsWith(page);

  /**
   * Determines if the current location path starts with any of the given paths.
   * However, will return false if the current location starts with any of the paths in `exclude`.
   */
  const hasSelectedSubmenu = (pageItems: string[], exclude: string[] = []) =>
    pageItems.some((page) => isSelectedPage(page)) &&
    exclude.every((page) => !isSelectedPage(page));

  const resetGlobalFilters = useResetGlobalFilters();
  const handleMenuClick = useCallback(() => {
    resetGlobalFilters();
  }, []);

  return (
    <$.header
      padding="30px 55px 0 55px"
      borderBottom="solid 1px #e0e1e1"
      background={colors.white.primary}
      className="no-print"
      fontSize={15}
      fontWeight={300}
      data-test="nav-bar"
    >
      <$.nav flex-center>
        {/* Logo */}
        <$ $el={NavLink} to="/" flex="0 0 auto" data-test="home-link">
          <$.img src={logo} alt="Home" block width={158} height={25} />
        </$>

        {/* User/dev Menu */}
        <$.flex
          flex-center
          flex="1 1 auto"
          height={30}
          align="stretch"
          justify="flex-end"
          columnGap={25}
        >
          <PlatformStatusMenuIcon />

          {canDebug() && <DevMenu />}

          <UserMenu />

          <$.flex
            flex-center
            cursor="pointer"
            data-test="logout-link"
            p="0 5px"
            onClick={logOut}
            $css={{ '&:hover': { color: colors.blue.primaryHover } }}
          >
            Log Out
            <Icon icon={faSignOutAlt} ml={10} size={18} />
          </$.flex>
        </$.flex>
      </$.nav>
      <$.nav flex-box justify="flex-start" align="center" ml={-27}>
        <NavMenu
          icon={faHome}
          label="Home"
          href="/"
          selected={isSelectedPage('/', true)}
          requireRoles={[Role.orgAdmin, Role.provider, Role.trialinq]}
        />

        <NavMenu
          icon={faBuilding}
          label="My Organization"
          href="/my-org"
          selected={isSelectedPage('/my-org')}
          requireRoles={[Role.orgManager]}
        />

        <NavMenu
          key="admin"
          icon={faToolbox}
          label="Administration"
          href="/admin"
          selected={isSelectedPage('/admin')}
          internalOnly
        />

        <NavMenu
          icon={faChartLine}
          label="Quality Tools"
          id="performance-menu"
          selected={
            hasSelectedSubmenu([
              '/measures',
              '/qopi',
              '/patient/actionable',
              '/patient/viewer',
              '/providers',
            ]) || isSelectedPage('/patient', true)
          }
          requireRoles={[Role.orgAdmin, Role.provider]}
        >
          <MenuLink
            to="/measures"
            onClick={handleMenuClick}
            text="All Measures Dashboard"
            requireRoles={[Role.orgAdmin, Role.provider]}
          />

          <MenuLink
            to="/qopi"
            onClick={handleMenuClick}
            text="QCP Dashboard"
            requireRoles={[Role.orgAdmin, Role.provider]}
          />

          <MenuLink
            to="/asco-certified"
            onClick={handleMenuClick}
            text="ASCO Certified"
            requireRoles={[Role.ascoCertified]}
            requireOrgFeatures={[OrgFeature.AscoCertified]}
          />

          <MenuLink
            to="/patient/actionable"
            onClick={handleMenuClick}
            text="Actionable Patients"
            requireRoles={[Role.orgAdmin, Role.provider]}
          />

          <MenuLink
            to="/patient"
            onClick={handleMenuClick}
            text="All Patients"
            requireRoles={[Role.orgAdmin, Role.provider]}
          />

          <MenuLink
            to="/providers"
            onClick={handleMenuClick}
            text="Provider Scorecard"
            requireRoles={[Role.orgAdmin]}
          />
        </NavMenu>

        <NavMenu
          icon={faFileMedicalAlt}
          label="Insights"
          selected={hasSelectedSubmenu(['/reports'])}
          href="/reports"
          requireRoles={[Role.trialinq]}
          excludeRoles={[Role.orgAdmin, Role.provider]}
        />

        <NavMenu
          icon={faFileMedicalAlt}
          label="Clinical and Insight Tools"
          id="insights-menu"
          selected={hasSelectedSubmenu(['/reports', '/patient/count-me-in', '/patient/journey'])}
          requireRoles={[Role.orgAdmin, Role.provider]}
        >
          <MenuLink to="/reports" onClick={handleMenuClick} text="CancerLinQ Insights" />
          <MenuLink to="/patient/count-me-in" onClick={handleMenuClick} text="Count Me In" />

          {/* TODO: Remove patientJourneyRoles permission check when Patient Journey is rolled out */}
          <MenuLink
            to="/patient/journey"
            onClick={handleMenuClick}
            text="Patient Journey"
            requireRoles={[Role.patientJourney]}
          />
        </NavMenu>
      </$.nav>
    </$.header>
  );
}
