import $ from '@stylix/core';
import React from 'react';

import { NewFeatureDialogType } from 'api_measure/web/routes/users/lib/users.types';

import {
  type NewFeatureDialogContent,
  type NewFeatureDialogContentProps,
} from 'src/Dashboard/NewFeatureDialog/useNewFeatureDialogContent';
import Button from 'src/ui/Button';

export const biosimilarsReportFeatureDialogContent: NewFeatureDialogContent = {
  newFeatureDialogType: NewFeatureDialogType.biosimilarsReport,
  Component: BiosimilarsReportFeatureDialogContent,
  useShouldShowContent: () => 'show',
};

export function BiosimilarsReportFeatureDialogContent(props: NewFeatureDialogContentProps) {
  return (
    <$.div flex-center flex-column text-center>
      <$.div
        border="8px solid #E8F7FF"
        background="#C2E6F2"
        width={140}
        height={140}
        border-radius={70}
        flex-center
        mt={25}
      >
        <$.img
          src={require('./biosimilars-report-icon.png')}
          width={90}
          height={90}
          object-fit="contain"
        />
      </$.div>

      <$.div size={24} mt={15}>
        Biosimilars Report
      </$.div>

      <$.div size={18} line-height={1.5} mt={15}>
        The Biosimilars Report will show utilization of biosimilar medications in your practice to
        support any goals around utilization and cost savings. The medications included will cover
        biologics used to treat cancer as well as the colony-stimulating growth factor biologics.
      </$.div>

      <Button
        variant="blue"
        large
        font-weight={400}
        mt={30}
        onClick={() => {
          props.closeDialog('/reports/Biosimilars Report');
        }}
      >
        Launch Biosimilars Report
      </Button>
    </$.div>
  );
}
