import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';
import $, { type StylixProps } from '@stylix/core';
import * as React from 'react';
import { mergeRefs } from 'react-merge-refs';

interface MenuWrapperProps {
  isOpen: boolean;
  controlElement: HTMLElement | null;
  menuProps: any;
  maxHeight?: number;
  children: (height: number) => React.ReactNode;
}

export default function MenuWrapper(props: StylixProps<'div', MenuWrapperProps>) {
  const { controlElement, isOpen, menuProps, maxHeight, children, ...other } = props;

  const [height, setHeight] = React.useState(0);

  const { refs, floatingStyles, placement } = useFloating({
    elements: {
      reference: controlElement,
    },
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
    strategy: 'fixed',
    middleware: [
      flip({
        fallbackPlacements: ['top'],
        fallbackStrategy: 'initialPlacement',
      }),
      size({
        boundary: document.body,
        padding: 8,
        apply({ rects, availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxWidth: `${availableWidth}px`,
            maxHeight: `${Math.min(availableHeight, maxHeight || Infinity)}px`,
            minHeight: `${Math.min(maxHeight || 0, 100)}px`,
          });
          setHeight(Math.min(availableHeight, 300));
        },
      }),
    ],
  });

  return (
    <$.div
      {...menuProps}
      style={floatingStyles}
      width="100%"
      ref={mergeRefs([refs.setFloating, menuProps.ref])}
      display={isOpen ? 'block' : 'none'}
      background="#FFF"
      borderRadius={4}
      boxShadow={
        placement === 'top'
          ? '0 0px 14px #0002, 0 2px 4px #0002'
          : '0 10px 14px #0002, 0 2px 4px #0002'
      }
      zIndex={1000}
      overflowY="auto"
      onClick={(e) => {
        e.stopPropagation();
      }}
      $css={{
        '&[data-popper-placement^="top"]': {
          boxShadow: '0 0px 12px #0002, 0 0px 4px #0002',
        },
      }}
      {...other}
    >
      {children(height)}
    </$.div>
  );
}
