import dayjs from 'dayjs';
import { type Duration } from 'dayjs/plugin/duration';

export const displayTimeZone = 'America/New_York';

export enum DateFormat {
  dateCompact = 'YYYYMMDD',
  dateSlash = 'MM/DD/YYYY',
  dateSlashShort = 'M/D/YYYY',
  dateDash = 'YYYY-MM-DD',
  dateTimeWords = 'MMMM D, YYYY [at] h:mm:ss a',
  dateTimeSlash = 'MM/DD/YYYY HH:mm:ss',
  dateTimeHuman = 'M/D/YYYY h:mm:ss a',
  dateTimeHumanMin = 'M/D/YYYY h:mm a',
  dateTimeHumanTZ = 'M/D/YYYY h:mm:ss a z',
  dateTimeDash = 'YYYY-MM-DD-HH:mm:ss',
  dateTimeCompact = 'YYYYMMDD_HHmmss',
  dateTimeISOTZ = 'YYYY-MM-DDTHH:mm:ssZ',
}

export type Timezone = 'EST' | 'PST' | 'UTC';

export const Timezones = {
  EST: 'America/New_York',
  PST: 'America/Los_Angeles',
  CST: 'America/Chicago',
  UTC: 'UTC',
};

export function formatDate<BadReturnType = string>(
  date: any,
  format: string | DateFormat = DateFormat.dateTimeWords,
  tz: null | Timezone | string = null,
  ifInvalid: string | BadReturnType | ((date: any) => BadReturnType) = 'N/A',
): string | BadReturnType {
  return date && dayjs(date).isValid()
    ? dayjs
        .utc(date)
        .tz(Timezones[tz as Timezone] || dayjs.tz.guess())
        .format(format)
    : typeof ifInvalid === 'function'
    ? (ifInvalid as any)(date)
    : ifInvalid;
}

export enum DurationUnit {
  milliseconds = 0,
  seconds = 1,
  minutes = 2,
  hours = 3,
  days = 4,
  months = 5,
  years = 6,
}

/**
 * Returns a short formatted duration string.
 * E.g. 1h 2m 3s
 */
export function formatDuration(
  duration: Duration,
  options: {
    minUnit?: DurationUnit;
    maxUnit?: DurationUnit;
  } = {},
): string {
  return (
    duration
      .format(
        ['SSS[ms]', 's[s]', 'm[m]', 'H[h]', 'D[d]', 'M[mo]', 'Y[y]']
          .slice(options.minUnit || 0, options.maxUnit || 7)
          .reverse()
          .join(' '),
      )
      // Remove all zero or undefined values
      .replace(/\b(0+|undefined)[a-z]+/gi, '')
      // Remove leading zeros
      .replace(/\b0+/g, '')
      // Remove duplicate spaces
      .replace(/ +/g, ' ')
      .trim()
  );
}
