import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import $ from '@stylix/core';
import React, { useCallback, useEffect, useState } from 'react';

import { Role } from 'api_measure/lib/roles';

import { trpc } from 'src/data/trpc';
import { useCurrentUser } from 'src/data/user/useCurrentUser';
import Button from 'src/ui/Button';
import ConfirmModal from 'src/ui/ConfirmModal';

export default function AttestationModal() {
  const { roles, userOrg, userOUs, userDate } = useCurrentUser();

  const [isOpen, setIsOpen] = useState(false);

  // Data that represents which checkboxes are checked
  const [checkboxes, setCheckboxes] = useState<{
    [ou_id: string]: {
      confirmed?: boolean;
      disabled: boolean;
    };
  }>({});

  const onClickCancelModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const submitAttestations = trpc.attestOus.useMutation();

  const onClickConfirmModal = useCallback(async () => {
    await submitAttestations.mutate(
      {
        year: userDate.year(),
        ous: Object.keys(checkboxes)
          .filter((ou_id) => !checkboxes[ou_id].disabled && checkboxes[ou_id].confirmed)
          .map(Number),
      },
      {
        onSuccess() {
          setIsOpen(false);
        },
      },
    );
  }, [checkboxes]);

  useEffect(() => {
    if (!userOrg) return;

    // Map result data to checkbox state
    const _checkboxes = userOUs.reduce((memo, ou) => {
      const confirmed = ou.attested;
      memo[ou.id] = { confirmed, disabled: confirmed };
      return memo;
    }, {} as typeof checkboxes);

    setCheckboxes(_checkboxes);

    // Open modal if there are unattested OUs
    setIsOpen(Object.values(_checkboxes).some((c: any) => !c.confirmed));
  }, [userOrg]);

  if (!roles.includes(Role.orgManager)) return null;
  if (!userOUs?.length) return null;

  return (
    <ConfirmModal
      isOpen={isOpen}
      color="default"
      title="Annual Subscriber Audit"
      icon={faExclamation}
      dialogProps={{
        canEscapeKeyClose: false,
        canOutsideClickClose: false,
      }}
      scrollable
      renderButtons={() => (
        <>
          <Button
            variant="ghost"
            onClick={onClickCancelModal}
            disabled={submitAttestations.isLoading}
          >
            Not Now
          </Button>
          <Button
            onClick={onClickConfirmModal}
            disabled={
              submitAttestations.isLoading ||
              // button is disabled if any unchecked & enabled checked boxes
              Object.values(checkboxes).every((c) => c.disabled || !c.confirmed)
            }
          >
            Confirm
          </Button>
        </>
      )}
    >
      <$.span inlineBlock size="0.9em" alignLeft>
        <p>
          Participation in CancerLinQ is subject to compliance with the Participation Policies for
          CancerLinQ Subscribers ("Policies") available at{' '}
          <a
            href="https://www.cancerlinq.org/about/resources"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.cancerlinq.org/about/resources
          </a>
          .
        </p>
        <p>
          Each year, we ask our subscribers to reaffirm their understanding of and compliance with
          the Policies. In particular, we draw your attention to the requirement to inform patients
          of your organization’s participation in CancerLinQ. By clicking "Confirm" below, you
          confirm (i) your organization’s understanding of the Policies, (ii) your organization’s
          continued compliance with the Policies, and (iii) that you are authorized to make this
          confirmation on your organization’s behalf.
        </p>
        <p>If you have any questions, please reach out to your customer success team member.</p>
      </$.span>
      <$.table width="100%" textAlign="left" size={13} borderCollapse="collapse">
        <thead>
          <$.tr>
            <$.th p="5px 0">Organization Unit</$.th>
            <$.th p="5px 0" alignCenter width="8em">
              Confirm
            </$.th>
          </$.tr>
        </thead>
        <tbody>
          {userOUs
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((ou) => (
              <tr key={ou.name}>
                <$.td p={0}>
                  <$.label block cursor="pointer" p="3px 0" htmlFor={`attest_checkbox_${ou.id}`}>
                    {ou.name}
                  </$.label>
                </$.td>
                <$.td p={0}>
                  <$.label
                    block
                    cursor={checkboxes[ou.id]?.disabled ? 'default' : 'pointer'}
                    p="3px 0"
                    alignCenter
                  >
                    <$.input
                      type="checkbox"
                      id={`attest_checkbox_${ou.id}`}
                      disabled={checkboxes[ou.id]?.disabled}
                      checked={!!checkboxes[ou.id]?.confirmed}
                      cursor="pointer"
                      onChange={() =>
                        setCheckboxes((c) => {
                          c[ou.id] = c[ou.id] || {};
                          c[ou.id].confirmed = !c[ou.id].confirmed;
                          return { ...c };
                        })
                      }
                    />
                  </$.label>
                </$.td>
              </tr>
            ))}
        </tbody>
      </$.table>
    </ConfirmModal>
  );
}
