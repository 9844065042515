import { createObserver, derive, reset, unwrap, useObserver } from 'keck';
import { get as _get, cloneDeep, isEmpty, isEqual, merge, set } from 'lodash-es';
import React, { useContext, useDebugValue, useEffect, useRef } from 'react';
import { type z } from 'zod';

import { allObjectPaths, mapRecursive, shallowEqual } from 'src/util/object';

const get = (obj: any, path?: null | string | number | (string | number)[]) => {
  return path === undefined || path === null || path === '' ? obj : _get(obj, path);
};

// Recursively maps all properties to the given type
type DeepMap<TType extends object, TMap> = {
  [K in keyof TType]: TType[K] extends object ? DeepMap<TType[K], TMap> : TMap;
};

type ValidateFn<TValues extends object, TErrors extends object = TValues> = (
  values: TValues,
  state: FormState<TValues, TErrors>,
) => Errors<TErrors> | undefined;

type ValidateFnWrapper<TValues extends object, TErrors extends object = TValues> = (
  values: TValues,
  state: FormState<TValues, TErrors>,
) => ValidateFn<TValues, TErrors>;

function resolveValidator<TValues extends object, TErrors extends object = TValues>(
  validator: ValidateFn<TValues, TErrors> | ValidateFnWrapper<TValues, TErrors> | undefined,
  values: TValues,
  state: FormState<TValues, TErrors>,
): Errors<TErrors> | undefined {
  let res = validator?.(values, state);
  if (typeof res === 'function') {
    res = res(values, state);
  }
  return res;
}

type Errors<TShape extends object> = DeepMap<TShape, string[]>;

export function zodValidator<TValues extends object>(schema: z.ZodType<any, any>) {
  // type TOutput = z.infer<T>;
  return ((values: TValues): Errors<TValues> | undefined => {
    if (!schema) return;
    const res = (schema as z.ZodSchema).safeParse(values);
    if (!res.success) {
      const errors = {} as any;
      for (const error of res.error.issues) {
        const path = error.path?.length ? error.path : ['@root'];
        const existing = get(errors, path);
        if (Array.isArray(existing)) {
          existing.push(error.message);
        } else if (!existing) {
          set(errors, path, [error.message]);
        }
        // If `existing` exists but is not an array, well then i don't know what to do
      }
      return errors as Errors<TValues>;
    }
  }) as ValidateFn<TValues>;
}

// Recursively set all properties of source onto target if they are different
function applyRecursive(target: any, source: any) {
  if (typeof target !== 'object' || typeof source !== 'object') return;
  if (target === null || source === null) return;

  for (const key in source) {
    if (
      target[key] &&
      source[key] &&
      Object.getPrototypeOf(target[key]) === Object.prototype &&
      Object.getPrototypeOf(source[key]) === Object.prototype
    ) {
      applyRecursive(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  for (const key in target) {
    if (!(key in source)) delete target[key];
  }
}

interface FormState<TValues extends object, TErrors extends object = TValues, TMeta = undefined> {
  values: TValues;
  meta: TMeta;
  errors: Partial<DeepMap<TErrors, string[] | undefined>> | null;
  touched: Partial<DeepMap<TValues, boolean>> | null;
  // dirty: Partial<DeepMap<TValues, boolean>> | null;
}

interface FormRef<TValues extends object, TErrors extends object = TValues> {
  // _initialValues: TValues;
  initialValues: TValues;
  state: FormState<TValues, TErrors>;
  Provider: React.FC<{ children: any }>;
}

interface FormContext<TValues extends object, TErrors extends object = TValues> {
  initialValues: TValues;
  state: FormState<TValues, TErrors>;
  isDirty(path?: string): boolean;
  isError(path?: string): boolean;
  isTouched: boolean;
  touchedErrors(path?: string): any;
  reset(): void;
  touchAll(...fields: string[]): void;
}

interface UseFormReturn<TValues extends object, TErrors extends object = TValues> {
  FormProvider: React.FC<{ children: any }>;
  form: FormContext<TValues, TErrors>;
}

const formContext = React.createContext<React.RefObject<FormRef<any>> | null>(null);

export function useKeckForm<TValues extends object, TErrors extends object = TValues>(options: {
  initialValues: TValues;
  validate?: ValidateFn<TValues, TErrors> | ValidateFnWrapper<TValues, TErrors>;
}): UseFormReturn<TValues, TErrors> {
  useDebugValue('useKeckForm');

  const validatorRef = useRef<(() => any) | null>(null);

  const formRef = useRef<FormRef<TValues, TErrors>>(null!);
  if (!formRef.current) {
    formRef.current = {
      initialValues: options.initialValues,
      state: {
        values: cloneDeep(options.initialValues),
        errors: null,
        touched: null,
        // dirty: null,
        meta: undefined,
      },

      Provider: (props: any) => <formContext.Provider value={formRef} {...props} />,
    };
    formRef.current.state.errors = validatorRef.current?.();
  }

  // formRef.current.initialValues = options.initialValues;

  const formState = useObserver(formRef.current.state);

  validatorRef.current = function validate() {
    const values = formRef.current.state.values;
    const errors = resolveValidator(options.validate, values, formRef.current.state) || null;
    if (!errors) formState.errors = null;
    else if (!formState.errors) formState.errors = errors;
    else applyRecursive(formState.errors, errors);
    return formRef.current.state.errors;
  };

  useEffect(() => {
    // Observe values and validate on changes. This uses vanilla Keck so that
    // we don't trigger a re-render on every keystroke.
    const store = createObserver(
      formRef.current.state,
      (state) => state.values,
      () => validatorRef.current?.(),
    );
    return () => reset(store);
  }, []);

  useEffect(() => {
    validatorRef.current?.();
  }, []);

  return {
    FormProvider: formRef.current.Provider,
    form: _useKeckFormContext(formRef.current),
  };
}

export function everyRecursive(obj: any, fn: (value: any) => boolean = (v) => !!v) {
  if (typeof obj !== 'object') return fn(obj);
  if (obj === null) return fn(obj);

  for (const key in obj) {
    if (!everyRecursive(obj[key], fn)) return false;
  }

  return true;
}

export function anyRecursive(obj: any, fn: (value: any) => boolean = (v) => !!v) {
  if (typeof obj !== 'object') return fn(obj);
  if (obj === null) return fn(obj);

  for (const key in obj) {
    if (anyRecursive(obj[key], fn)) return true;
  }

  return false;
}

function _useKeckFormContext<TValues extends object, TErrors extends object = TValues>(
  form: FormRef<TValues, TErrors>,
): FormContext<TValues, TErrors> {
  const formState = useObserver(form.state);

  return {
    get initialValues() {
      return form.initialValues;
    },
    set initialValues(values: TValues) {
      form.initialValues = values;
    },

    state: formState,

    isDirty(path?: string) {
      return derive(
        () => !isEqual(unwrap(get(formState.values, path)), unwrap(get(form.initialValues, path))),
      );
    },

    isError(path?: string) {
      return derive(() => !isEmpty(get(formState.errors, path)));
    },

    get isTouched() {
      return derive(() => anyRecursive(formState.touched, (value) => value));
    },

    touchedErrors(path?: string) {
      return derive(() => {
        const pathTouched = get(formState.touched, path);
        const pathErrors = get(formState.errors, path);

        if (pathTouched === true) return pathErrors;

        const touchedPaths = allObjectPaths(pathTouched);
        const touchedErrors = {};

        for (const touchedPath of touchedPaths) {
          if (get(pathErrors, touchedPath) && get(pathTouched, touchedPath)) {
            set(touchedErrors, touchedPath, get(pathErrors, touchedPath));
          }
        }
        return isEmpty(touchedErrors) ? null : touchedErrors;
      }, isEqual);
    },

    reset() {
      formState.values = cloneDeep(form.initialValues);
      formState.touched = null;
      // formState.dirty = null;
    },

    touchAll(...fields: string[]) {
      formState.touched = formState.touched || {};
      if (!fields.length) {
        formState.touched = mapRecursive(
          merge(cloneDeep(form.initialValues), form.state.values),
          () => true,
          true,
        );
      } else {
        for (const field of fields) {
          const touched = mapRecursive(
            get(merge(cloneDeep(form.initialValues), form.state.values), field),
            () => true,
            true,
          );
          set(formState.touched, field, touched);
        }
      }
    },
  };
}

export function useKeckFormContext<TValues extends object = any>(): FormContext<TValues> {
  useDebugValue('useKeckFormContext');
  const form = useContext(formContext)?.current;
  if (!form) throw new Error('Form context not found');
  return _useKeckFormContext(form);
}

interface KeckField<TValue, TError> {
  value: TValue;
  isDirty: boolean;
  isTouched: boolean;
  isError: boolean;
  error: TError | undefined;
  // setDirty: (value: any, isEqual?: (value1: any, value2: any) => boolean) => void;
  setValue: (value: any) => void;
  setTouched: () => void;
  props: {
    value: TValue;
    onChange: (e: any) => void;
    onBlur: (e: any) => void;
  };
}

interface KeckFieldOptions {
  form?: FormContext<any>;
  transform?: 'text' | 'number' | 'raw' | ((value: any) => any);
  onChange?: (e: any) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  dirtyCompare?: (value1: any, value2: any) => boolean;
}

export function useKeckField<TValue, TError = unknown>(
  fieldName: string,
  options?: KeckFieldOptions,
): KeckField<TValue, TError> {
  useDebugValue(`useKeckField(${fieldName})`);
  const { state, initialValues } = options?.form || useKeckFormContext();

  let transform: undefined | ((value: any) => any) = undefined;
  if (options?.transform === 'number')
    transform = (e: any) =>
      String(+e.target.value) === e.target.value ? +e.target.value : e.target.value;
  else if (options?.transform === 'raw') transform = (e: any) => e;
  else if (typeof options?.transform === 'function') transform = options.transform;
  else transform = (e: any) => e.target.value;

  const field = {
    get value() {
      return derive(() => get(state.values, fieldName));
    },
    get isDirty() {
      return derive(
        () => !isEqual(unwrap(get(state.values, fieldName)), unwrap(get(initialValues, fieldName))),
      );
    },
    get isTouched() {
      return derive(() => !!get(state.touched, fieldName));
    },
    get error() {
      return derive(() => unwrap(get(state.errors, fieldName)));
    },
    get isError() {
      return derive(() => !!get(state.errors, fieldName));
    },
    setValue(value: any) {
      set(state.values, fieldName, value);
    },
    setTouched() {
      state.touched = state.touched || {};
      set(state.touched, fieldName, true);
    },
    props: {
      get value() {
        return field.value;
      },
      onChange: (e: any) => {
        const value = transform!(e);
        field.setValue(value);
        // field.setDirty(value, options?.dirtyCompare);
        options?.onChange?.(e);
      },
      onBlur: (e: any) => {
        field.setTouched();
        options?.onBlur?.(e);
      },
    },
  };

  return field;
}

type UseKeckFieldArrayReturn<TArrayValue, TError> = {
  values: TArrayValue[];
  isDirty: boolean;
  touched: boolean[];
  isTouched: boolean;
  error: TError;
  isError: boolean;
  // setDirty: (value: any, isEqual?: (value1: any, value2: any) => boolean) => void;
  setValue: (value: any) => void;
  setTouched: () => void;
  push: (value: any) => void;
  pop: () => void;
  unshift: (value: any) => void;
  shift: () => void;
  remove: (index: number) => void;
  insert: (index: number, value: any) => void;
  move: (from: number, to: number) => void;
  swap: (from: number, to: number) => void;
  replace: (newValues: TArrayValue[]) => void;
};

export function useKeckFieldArray<TValue, TError = unknown>(
  fieldName: string,
  options?: { identifier?: (value: TValue) => any; form?: FormContext<any> },
): UseKeckFieldArrayReturn<TValue, TError> {
  useDebugValue(`useKeckFieldArray(${fieldName})`);

  const { state, initialValues } = options?.form || useKeckFormContext();

  // This will cause a re-render only when the list of unique ids change
  options?.identifier &&
    derive(() => {
      const values = unwrap(get(state.values, fieldName));
      return values?.map?.(options.identifier);
    }, shallowEqual);

  const fieldArray = {
    get values(): TValue[] {
      let values = derive(
        () => get(state.values, fieldName),
        (a, b) =>
          options?.identifier
            ? shallowEqual(a.map(options.identifier), b.map(options.identifier))
            : a === b,
      );
      if (!Array.isArray(values)) set(state.values, fieldName, (values = []));
      return values;
    },
    get isDirty(): boolean {
      return derive(
        () => !isEqual(unwrap(get(state.values, fieldName)), unwrap(get(initialValues, fieldName))),
      );
    },
    get touched() {
      let touched = derive(() => get(state.touched, fieldName));
      if (!Array.isArray(touched)) {
        state.touched = state.touched || {};
        set(state.touched, fieldName, (touched = []));
      }
      return touched;
    },
    get isTouched(): boolean {
      return derive(() => !!get(state.touched, fieldName));
    },
    get error(): TError {
      return derive(() => unwrap(get(state.errors, fieldName)));
    },
    get isError(): boolean {
      return derive(() => !!get(state.errors, fieldName));
    },
    // setDirty(value: any, isEqual = Object.is) {
    //   state.dirty = state.dirty || {};
    //   set(state.dirty, fieldName, !isEqual(value, get(initialValues, fieldName)));
    // },
    setValue(value: any) {
      set(state.values, fieldName, value);
    },
    setTouched() {
      state.touched = state.touched || {};
      set(state.touched, fieldName, true);
    },

    push(value: any) {
      if (!Array.isArray(fieldArray.values)) set(state.values, fieldName, []);
      fieldArray.values.push(value);
      state.touched = state.touched || {};
      set(state.touched, fieldName, fieldArray.touched || []);
      fieldArray.touched.length++;
    },
    pop() {
      fieldArray.values.pop();
      fieldArray.touched?.pop();
    },
    unshift(value: any) {
      fieldArray.values.unshift(value);
      fieldArray.touched?.unshift(undefined);
      delete fieldArray.touched?.[0];
    },
    shift() {
      fieldArray.values.shift();
      fieldArray.touched?.shift();
    },
    remove(index: number) {
      fieldArray.values.splice(index, 1);
      fieldArray.touched?.splice(index, 1);
    },
    insert(index: number, value: any) {
      fieldArray.values.splice(index, 0, value);
      fieldArray.touched?.splice(index, 0, undefined);
      delete fieldArray.touched?.[index];
    },
    move(from: number, to: number) {
      fieldArray.values.splice(to, 0, unwrap(fieldArray.values).splice(from, 1)[0]);
      fieldArray.touched?.splice(to, 0, unwrap(fieldArray.touched)?.splice(from, 1)[0]);
    },
    swap(from: number, to: number) {
      [fieldArray.values[from], fieldArray.values[to]] = [
        unwrap(fieldArray.values[to]),
        unwrap(fieldArray.values[from]),
      ];
      if (fieldArray.touched)
        [fieldArray.touched[from], fieldArray.touched[to]] = [
          unwrap(fieldArray.touched[to]),
          unwrap(fieldArray.touched[from]),
        ];
    },
    replace(newValues: TValue[]) {
      set(state.values, fieldName, newValues);
      state.touched = state.touched || {};
      set(
        state.touched,
        fieldName,
        newValues.map(() => true),
      );
    },
  };

  return fieldArray;
}
