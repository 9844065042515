import { faRandom } from '@fortawesome/pro-solid-svg-icons';
import $, { type StylixProps } from '@stylix/core';
import React, { useEffect, useState } from 'react';

import OrgSwitcherConfirmDialog from 'src/Admin/OrgSwitcher/OrgSwitcherConfirmDialog';
import { trpc } from 'src/data/trpc';
import { useCurrentUser } from 'src/data/user/useCurrentUser';
import Button from 'src/ui/Button';
import Card from 'src/ui/Card';
import ConfirmModal from 'src/ui/ConfirmModal';
import { Dropdown } from 'src/ui/Dropdown/Dropdown';

import { fastOrgSwitcherRoles, useEndOrgSwitchMutation } from './data/orgSwitcherQueries';

export default function OrgSwitcherCard(props: StylixProps<typeof Card>) {
  const { profile } = useCurrentUser();

  const endOrgSwitchMutation = useEndOrgSwitchMutation();

  const { data: orgList, isLoading: isLoadingOrgList } = trpc.listFastSwitcherOrgs.useQuery();

  const [showConfirmSwitchModal, setShowConfirmSwitchModal] = useState(false);
  const [showConfirmEndModal, setShowConfirmEndModal] = useState(false);

  const defaultSenderCode = orgList?.find((org) => org.sender_code === profile.org_assignment)
    ? profile.org_assignment
    : undefined;

  const [selectedSenderCode, setSelectedSenderCode] = useState<string | undefined>(
    profile.org_switcher?.org || undefined,
  );
  const selectedOrg = orgList?.find((org) => org.sender_code === selectedSenderCode);

  useEffect(() => {
    if (selectedSenderCode && selectedSenderCode !== profile.org_switcher?.org) {
      setShowConfirmSwitchModal(true);
    }
  }, [selectedSenderCode]);

  return (
    <>
      <Card fontSize={14} p="15px 15px" {...props}>
        <$.div fontSize={16} bold>
          Org Switcher
        </$.div>

        <Dropdown
          options={orgList?.map((org) => org.sender_code)}
          onChange={setSelectedSenderCode}
          value={selectedSenderCode}
          clearValue={defaultSenderCode}
          renderOption={(value) => {
            const org = orgList?.find((org) => org.sender_code === value);
            return `${org?.name} (${org?.sender_code})`;
          }}
          placeholder="Select an organization to switch..."
          isLoading={isLoadingOrgList}
          width="100%"
          mt={10}
        />

        {profile.org_switcher && (
          <Button
            variant="gold"
            large
            width="100%"
            mt={20}
            onClick={() => {
              setShowConfirmEndModal(true);
            }}
          >
            End Org Switcher Session
          </Button>
        )}
      </Card>

      <OrgSwitcherConfirmDialog
        isOpen={showConfirmSwitchModal}
        onClose={() => {
          setShowConfirmSwitchModal(false);
          setSelectedSenderCode(undefined);
        }}
        selectedOrg={selectedOrg}
        ous={selectedOrg?.ous}
        roles={fastOrgSwitcherRoles}
      />

      <ConfirmModal
        isOpen={showConfirmEndModal}
        color="warning"
        title="End Org Switcher Session"
        icon={faRandom}
        renderButtons={() => (
          <>
            <Button variant="ghost" onClick={() => setShowConfirmEndModal(false)}>
              Cancel
            </Button>
            <Button
              variant="gold"
              onClick={async () => {
                setShowConfirmEndModal(false);
                await endOrgSwitchMutation.mutateAsync();
              }}
            >
              End Org Switcher Session
            </Button>
          </>
        )}
      >
        <$.div mb={30}>
          This will end your org switcher session. Your account will be returned to its original
          practice assignment and user roles.
        </$.div>
      </ConfirmModal>
    </>
  );
}
