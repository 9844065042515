// Loads megaJson parser customizations (this is for trpc, adds additional types to megaJson such as Dayjs)
import 'api_measure/lib/megaJson';
// Load stylix customizations
import 'src/util/stylix';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import arraySupport from 'dayjs/plugin/arraySupport';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'src/App/App';

console.info('Git commit:', process.env.GIT_HASH);
console.info('NODE_ENV:', process.env.NODE_ENV);
console.log('REACT_CONFIG', process.env.REACT_CONFIG);

dayjs.extend(advancedFormat);
dayjs.extend(arraySupport);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax);
dayjs.extend(objectSupport);
dayjs.extend(relativeTime);
dayjs.extend(tz);
dayjs.extend(utc);

// Redeclare forwardRef to accept generic component types (like MyComponent<T>(props: { t: T }))
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

ReactDOM.createRoot(document.getElementById('app')).render(<App />);
