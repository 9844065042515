import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { throttle } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';

import Button, { type ButtonProps } from 'src/ui/Button';

export default function ScrollToTopButton(props: ButtonProps) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = throttle(() => {
      if (window.scrollY >= 100 && !visible) setVisible(true);
      else if (window.scrollY < 100 && visible) setVisible(false);
    }, 100);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [visible]);

  return (
    <Button
      className="no-print"
      variant="round"
      large
      faIcon={faArrowUp}
      position="fixed"
      bottom={0}
      right={30}
      transform={visible ? 'translateY(-30px)' : 'translateY(110%)'}
      transition="transform 200ms ease-out"
      onClick={useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, [])}
      {...props}
    />
  );
}
