import { faFileCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import $ from '@stylix/core';
import React from 'react';

import MessageText from 'src/ui/MessageText';

export default function PageNotFound() {
  return (
    <MessageText
      flex="1 1 auto"
      alignCenter
      icon={faFileCircleQuestion}
      ghost
      iconProps={{ fontSize: 50 }}
    >
      <$.div mt={20} size={24}>
        Page not found
      </$.div>
      <$.div mt={20} size={18}>
        Sorry, the page you requested could not be found.
      </$.div>
    </MessageText>
  );
}
