import $, { type StylixProps } from '@stylix/core';
import React from 'react';

/**
 * Displays characters as superscript text, properly aligning them with the top of the surrounding
 * text regardless of font size. Use `size={...}` to specify a font size; default is "0.4em".
 */
export default function Super(props: StylixProps<'span', { children: React.ReactNode }>) {
  const { fontSize, size, children, ...other } = props;
  return (
    <$.span inlineBlock lineHeight="1px" vAlign="text-top" height={1} mt="0.45ex" {...other}>
      <$.span inlineBlock lineHeight="0.7" fontSize={size || fontSize || '0.4em'} vAlign="text-top">
        {children}
      </$.span>
    </$.span>
  );
}
