import React, { useLayoutEffect, useState } from 'react';

import Loader from 'src/ui/Loader';

function getModule(obj: any) {
  return obj?.__esModule ? obj.default : obj;
}

interface CodeSplitComponentProps {
  componentLoader: any;
}

export default function CodeSplitComponent(props: CodeSplitComponentProps) {
  const { componentLoader } = props;

  const [loaded, setLoaded] = useState(false);
  const [Component, setComponent] = useState<any>(null);

  useLayoutEffect(() => {
    (async () => {
      const incomingComponent = await componentLoader();
      const c = getModule(incomingComponent);
      setComponent(() => c);
      setLoaded(true);
    })();
  }, []);

  return loaded ? <Component /> : <Loader>Loading...</Loader>;
}
