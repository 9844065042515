import $, { type StylixProps } from '@stylix/core';
import React from 'react';

const SubHeading = (props: StylixProps) => <$.h6 m="20px 0" {...props} />;

export default function EULAText(props: StylixProps) {
  return (
    <$.div {...props}>
      <$.p bold>
        These Terms of Use describe your rights and responsibilities with regard to use of the
        CancerLinQ<sup>®</sup> platform and related services; please read it carefully.
      </$.p>
      <p>
        These CancerLinQ<sup>®</sup> Terms of Use (&ldquo;the System Terms&rdquo;) apply to your
        access and use of the CancerLinQ platform, including any related websites, applications,
        software, tools, data, documentation, updates and upgrades (the &ldquo; CancerLinQ
        Services&rdquo;), made available by or on behalf of CancerLinQ LLC (&ldquo;CLQ,&rdquo;
        &ldquo;we&rdquo; or &ldquo;us&rdquo;), including those CancerLinQ Services provided by CLQ
        on behalf of its affiliates. For these System Terms, &ldquo;you&rdquo; or &ldquo;your&rdquo;
        refers to any person who views, accesses, uses, or obtains information from the platform
        including visitors and registered users of the CancerLinQ Services.
      </p>
      <p>
        You must be authorized to access and use the CancerLinQ Services by a hematology-oncology
        practice or practice group (a &ldquo;Subscriber&ldquo;) that has entered into a CancerLinQ
        Participation Agreement or other agreement with CLQ or its affiliates for the provision of
        CancerLinQ Services (&ldquo;Service Agreement&ldquo;). You may not use the CancerLinQ
        Services unless and until you are authorized to do so by a Subscriber.
      </p>
      <p>
        <strong>
          IF YOU DO NOT OR CANNOT AGREE TO ANY OF THESE SYSTEM TERMS, THEN YOU MAY NOT USE THE
          CANCERLINQ SERVICES.
        </strong>
      </p>
      <p>
        CLQ makes no representation that the CancerLinQ Services are appropriate or available for
        use in all locations or for any particular purpose. The CancerLinQ Services may not be
        available in your location. CLQ, our platform, and the CancerLinQ Service are based in the
        United States and, regardless of where you access our platform or the CancerLinQ Services,
        the information collected as part of that use will be transferred to and maintained on
        servers located in the United States. Any information we obtain about you will be stored in
        accordance with U.S. privacy laws, regulations, and standards, which may not be equivalent
        to the laws in your country of residence. By using our platform or any CancerLinQ Services
        and by clicking "Submit" below, you consent to this collection, transfer, storage, and
        processing of information to and in the United States.
      </p>
      <SubHeading>1. ACCEPTANCE OF SYSTEM TERMS</SubHeading>
      <p>
        THESE SYSTEM TERMS GOVERN YOUR USE OF THE CANCERLINQ SERVICES. PLEASE READ THESE SYSTEM
        TERMS CAREFULLY. BY USING THE CANCERLINQ SERVICES, AND BY CLICKING "SUBMIT" BELOW, YOU
        ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND ACCEPTED THE SYSTEM TERMS, INCLUDING OUR{' '}
        <a href="https://www.cancerlinq.org/terms-use" target="_blank" rel="noopener noreferrer">
          WEBSITE TERMS AND CONDITIONS OF USE
        </a>{' '}
        AND{' '}
        <a
          href="https://www.cancerlinq.org/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          WEBSITE PRIVACY POLICY
        </a>
        , EACH OF WHICH IS INCORPORATED BY REFERENCE INTO THESE SYSTEM TERMS.
      </p>
      <p>
        Unless expressly stated otherwise, if any provision of these System Terms is inconsistent
        with any provision in the Services Agreement entered into by Subscriber and CLQ, the terms
        of the Services Agreement will prevail, but solely to the extent of the inconsistency. For
        the avoidance of doubt, the order of authority is as follows:
        <ol>
          <li>The Services Agreement.</li>
          <li>The CancerLinQ Policies (if applicable per the Services Agreement).</li>
          <li>The System Terms.</li>
          <li>Additional System Terms (as described in Section 7).</li>
          <li>
            The{' '}
            <a
              href="https://www.cancerlinq.org/terms-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Website Terms And Conditions of Use
            </a>
            .
          </li>
          <li>
            The{' '}
            <a
              href="https://www.cancerlinq.org/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Website Privacy Policy
            </a>
            .
          </li>
        </ol>
      </p>
      <SubHeading>2. CHANGES TO SYSTEM TERMS</SubHeading>
      <p>
        The "Effective Date" of these System Terms is set forth at the top of these System Terms. We
        reserve the right to amend the System Terms at any time by posting the amended System Terms
        on the platform. We may provide notice to you regarding any changes to these System Terms
        via e-mail, regular mail, or posting notices or links to notices on the platform, or on
        www.cancerlinq.org. Your continued use of the CancerLinQ Services after the Effective Date
        constitutes your acceptance of the amended System Terms. Each amended System Terms
        supersedes all previous versions of the System Terms. For this reason, we encourage you to
        review the System Terms each time you access or use the CancerLinQ Services.
      </p>
      <SubHeading>3. LICENSE GRANT</SubHeading>
      <p>
        The CancerLinQ Services, including any information, graphics, artwork, text, video clip,
        audio clip, trademark, logo and other content provided on, in or through the CancerLinQ
        Services (collectively, "CLQ Content"), are owned by CLQ or its licensors and are protected
        under both United States and foreign copyright, trademark, and other laws.
      </p>
      <p>
        CLQ grants you a personal, nonexclusive, nontransferable, and limited license (without the
        right to sublicense) to access and use the CancerLinQ Services solely for uses consistent
        with the Services Agreement and subject to these System Terms. You acknowledge that the
        trade secrets and intellectual property embodied in the CancerLinQ Services have not been
        and will not be licensed or otherwise disclosed to you. You acknowledge that the software
        and HTML code that CLQ creates to generate the pages of the CancerLinQ Services are
        protected by CLQ&rsquo;s copyrights and other intellectual property rights. All rights not
        expressly granted herein are reserved by CLQ.
      </p>
      <p>
        If you believe the CancerLinQ platform contains content that infringes your copyright,
        please contact <a href="mailto:copyright@asco.org">copyright@asco.org</a> and provide
        information related to your claim of copyright infringement. Additional information
        regarding copyright concerns and intellectual property matters are available in our{' '}
        <a href="https://www.cancerlinq.org/terms-use" target="_blank" rel="noopener noreferrer">
          Website Terms
        </a>
        .
      </p>

      <SubHeading>4. YOUR USE OF THE CANCERLINQ SERVICES</SubHeading>

      <p>
        <$.span inlineBlock bold m="0 20px">
          4.1 User Credentials:
        </$.span>
        You must be authorized by a Subscriber to access and use the CancerLinQ Services. When you
        are authorized, you will receive a username and create a password (together, your
        &ldquo;User Credentials&rdquo;). You agree that you only will use your User Credentials and
        the CancerLinQ Services for the legitimate business purposes of Subscriber in accordance
        with the Services Agreement. You are responsible for all activities that occur through your
        User Credentials, whether or not authorized or undertaken by you. You should keep your User
        Credentials private, and you may not let anyone else use your Credentials to access or
        operate the CancerLinQ platform. You are responsible for notifying us if your password has
        been hacked or stolen. You may notify us by sending an email to&nbsp;
        <a href="mailto:privacy@cancerlinq.org">privacy@cancerlinq.org</a>. You are not permitted to
        impersonate any person or entity, or misrepresent your affiliation with a person or entity,
        or misrepresent the origin of any information that you provide to us. For additional
        information regarding use of the CancerLinQ platform, please refer to the Services
        Agreement, the Subscriber Policies, and Sections 4 (Prohibited Activities) and 5 (Security
        Measures) of the{' '}
        <a href="https://www.cancerlinq.org/terms-use" target="_blank" rel="noopener noreferrer">
          Website Terms
        </a>
        .
      </p>
      <p>
        <$.span inlineBlock bold m="0 20px">
          4.2 Your Responsibilities:
        </$.span>
        You are responsible for providing the equipment and services that you need to access and use
        the CancerLinQ Services. CLQ does not guarantee that the CancerLinQ Services are accessible
        on any particular equipment or device or with any particular software or hardware. You are
        responsible for the accuracy, quality, and legality of any information, data or materials
        that you may have the opportunity to upload, transmit or post to or through the CancerLinQ
        Services (collectively, "Your Content") and for ensuring that Your Content complies with
        these System Terms. You hereby grant a worldwide license to use, reproduce, transmit,
        display and adapt Your Content as necessary for CLQ to operate the CancerLinQ Services in
        accordance with these System Terms and the Services Agreement. You understand that CLQ may
        remove Your Content from the CancerLinQ Services if CLQ determines that it violates these
        System Terms.
        <br />
        <br />
        You are responsible for complying with all laws and regulations that apply to your use of
        the CancerLinQ Services, including export control laws and regulations imposed by the U.S.
        government (&ldquo;Export Laws&rdquo;). You agree that you will not export, re-export,
        transfer, or make available, directly or indirectly, all of any part of the CancerLinQ
        Services and/or other information or materials provided by CLQ hereunder, without first
        complying with Export Laws. Possible evidence of use of the CancerLinQ Services for illegal
        purposes may be provided to law enforcement authorities. You are responsible for complying
        with all rules, terms and conditions imposed by the Subscriber that provided you with your
        User Credentials.
        <br />
        <br />
        Except as specifically permitted in these System Terms or expressly authorized in writing by
        CLQ, you agree that you will not (directly or indirectly):
      </p>

      <ul>
        <li>
          post, publish, reproduce, transmit, distribute or otherwise view, use, or exploit the
          CancerLinQ Services in a manner that is inconsistent with this these System Terms, in
          violation of any federal, state or local law, rule, regulation or order, or could damage,
          disable, overburden, or impair the CancerLinQ Services or interfere with any other
          party&rsquo;s use and enjoyment of CancerLinQ Services;
        </li>
        <li>
          distribute, sell, assign, encumber, transfer, rent, lease, sublicense, modify or
          time-share the CancerLinQ Services in whole or in any part;
        </li>
        <li>use any of the CancerLinQ Services in any service bureau arrangement;</li>
        <li>
          copy, reproduce, adapt, create derivative works of, translate, localize, port, or
          otherwise modify the CancerLinQ Services or any part of the CancerLinQ Services;
        </li>
        <li>
          circumvent (or attempt to circumvent) any functionality that controls access to or
          protects the CancerLinQ Services, including log-in credentials;
        </li>
        <li>probe or test the vulnerability of the CancerLinQ Services;</li>
        <li>
          transmit or otherwise make available any virus, keyloggers, spyware, worms, Trojan horses,
          timebombs, or other malicious or harmful programming, application or code (collectively,
          &ldquo;Malware&rdquo;) designed to interrupt, destroy, or limit the functionality of any
          of the CancerLinQ Services;
        </li>
        <li>
          modify or eliminate any notice about copyright, trademark, or other intellectual property
          right in the CLQ Content or other part of the CancerLinQ Services;
        </li>
        <li>
          reverse engineer, disassemble, decompile, or otherwise use any means to discover the
          source code of or trade secrets in any part of the CancerLinQ Services;
        </li>
        <li>
          engage in any offensive or illegal conduct, including conduct that violates third-party
          privacy, intellectual property or other rights; or
        </li>
        <li>
          assist or knowingly permit any third party to engage in any of the acts proscribed above.
        </li>
      </ul>

      <SubHeading>5. OUR RESPONSIBILITIES</SubHeading>
      <p>
        We are responsible for providing the CancerLinQ Services in accordance with these System
        Terms and all applicable laws, rules, and regulations.
      </p>
      <p>
        CLQ reserves the right (but is not obligated) to improve, enhance, or modify the CancerLinQ
        Services. Unless otherwise required by applicable law or industry standards, we will,
        whenever reasonably practical, notify you in advance of a change to the CancerLinQ Services
        that may significantly adversely affect how the CancerLinQ Services operate or perform.
      </p>
      <SubHeading>
        6. LIMITATIONS OF LIABILITY, MANDATORY ARBITRATION, CLASS WAIVER, AND INDEMNIFICATION
      </SubHeading>
      <p>
        The{' '}
        <a href="https://www.cancerlinq.org/terms-use" target="_blank" rel="noopener noreferrer">
          Website Terms
        </a>
        , incorporated herein, contain provisions that apply to your use of the CancerLinQ platform
        and our website services generally, including limitations of liability, requirements to
        submit disputes to arbitration, indemnification, and a class action waiver. By clicking
        "Submit" below, you acknowledge and agree that these&nbsp;
        <a href="https://www.cancerlinq.org/terms-use" target="_blank" rel="noopener noreferrer">
          Website Terms
        </a>
        , together with the System Terms are essential terms governing your use of the CancerLinQ
        Services and you agree to all terms set forth therein, including without limitation, terms
        relating to limitations of liability, mandatory arbitration, indemnification, and class
        action waiver.
      </p>
      <SubHeading>7. ADDITIONAL TERMS APPLICABLE TO SPECIFIC CANCERLINQ SERVICES</SubHeading>
      <p>
        Additional terms of use may apply to your use of particular CancerLinQ Services provided
        through the CancerLinQ platform. Additional terms may be posted on areas of the platform
        where particular services are provided to you, or posted on pop-up windows which may appear
        in connection with your use of specific services. Without limiting the provisions of Section
        1, above, within the CancerLinQ platform, the phrase &ldquo;I Agree&rdquo; or simply
        &ldquo;Agree&rdquo; (or any words of like import) is intended to be an additional way in
        which you confirm your binding assent to additional terms, and by clicking on an &ldquo;I
        Agree&rdquo; or &ldquo;Agree&rdquo; button(or button with words of like import), you enter
        into a binding contract.
      </p>
      <SubHeading>8. PRIVACY</SubHeading>
      <p>
        Please carefully read our&nbsp;
        <a
          href="https://www.cancerlinq.org/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        &nbsp;to learn about the information that CLQ collects about you through the CancerLinQ
        Services and how we process it. By using the CancerLinQ Services, you consent to the
        collection, processing, and storage by CLQ of personal information about you and your use of
        the CancerLinQ Services in accordance with our Privacy Policy. CancerLinQ is committed to
        transparency regarding our privacy practices. We maintain reasonable appropriate
        administrative and technical controls to safeguard the security and privacy of your personal
        information. Should you have any questions or concerns, please contact&nbsp;
        <a href="mailto:privacy@cancerlinq.org">privacy@cancerlinq.org</a>.
      </p>
      <SubHeading>9. LINKS AND THIRD PARTY SERVICES</SubHeading>
      <p>
        You may find links to other Internet sites or resources on the CancerLinQ platform. You
        acknowledge and agree that (a) CLQ is not responsible for the availability of such external
        sites or resources, (b) all links and identifications are provided solely for your
        convenience and for other informational purposes, and (c) CLQ does not endorse and is not
        responsible or liable for any content, advertising, products, or other materials on or
        available from such sites, resources, or medical professionals, or their affiliations.
        Without limiting the foregoing, CLQ will not be responsible or liable, directly or
        indirectly, for any actual or alleged damage or loss caused by or in connection with the use
        of or reliance on any such content, goods or services available on or through any such site
        or resource.
      </p>

      <p>
        If you have questions about the CancerLinQ Service or these System Terms, please contact us
        at:
      </p>
      <$.span inlineBlock ml={30}>
        CancerLinQ LLC
        <br />
        2318 Mill Road, Suite 800
        <br />
        Alexandria, VA 22314
        <br />
        Phone: 888-282-2552
        <br />
        Attention: Chief Legal Officer
      </$.span>
    </$.div>
  );
}
