import $, { type StylixProps } from '@stylix/core';
import React from 'react';

export default function Footer(props: StylixProps) {
  return (
    <$.div
      flex="0 0 auto"
      background="#343f45"
      color="#FFF9"
      line-height={1.5}
      size={13}
      padding="15px 50px 30px"
      $css={{
        a: {
          color: '#81cdff',
          textDecoration: 'underline',
        },
      }}
      {...props}
    >
      <$.div>
        CancerLinQ is a voluntary resource for quality improvement and other health care operations.
        Patient care is always subject to the independent professional judgment of the treating
        physician. CancerLinQ is not a patient medical record. AJCC staging references are used with
        permission of the American College of Surgeons, Chicago, Illinois. The original source for
        this information is the AJCC Cancer Staging Manual, Eighth Edition (2017) published by
        Springer International Publishing. For more information, visit{' '}
        <a href="https://www.cancerlinq.org">cancerlinq.org</a>.
        <$.span
          mt={10}
          display="flex"
          column-gap={10}
          ml={8}
          align-items="baseline"
          justify-content="center"
        >
          <$.a
            href="https://www.cancerlinq.org/terms-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            Website Terms of Use
          </$.a>
          <$.span size={10}>&bull;</$.span>
          <$.a
            href="https://www.cancerlinq.org/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </$.a>
        </$.span>
      </$.div>
    </$.div>
  );
}
