import { PopoverInteractionKind } from '@blueprintjs/core';
import { faExternalLinkSquare, faLifeRing } from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faInfoCircle,
  faPhone,
  faRandom,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { useClickOutside, useToggle } from '@react-hookz/web';
import $, { type StylixProps } from '@stylix/core';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { DateFormat, formatDate } from 'api_measure/shared/time';

import OrgSwitcherCard from 'src/Admin/OrgSwitcher/OrgSwitcherCard';
import routeMaps from 'src/data/routeMaps';
import { getRoleLabels } from 'src/data/user/roles';
import { useCurrentUser } from 'src/data/user/useCurrentUser';
import colors from 'src/ui/colors';
import Icon from 'src/ui/Icon';
import { Popover } from 'src/ui/Popover';

const useLastEMRImportQuery = () =>
  useQuery<{ max_source_changed_at: string; source_system: string }[]>([
    routeMaps.measureActions.fetchIncremental(),
  ]);

export default function UserMenu(props: StylixProps) {
  const { roles, profile, internal, userOrg } = useCurrentUser();

  const roleLabels = getRoleLabels(roles);

  const { data: incrementalData } = useLastEMRImportQuery();

  const [isOpen, toggleOpen] = useToggle(false);

  const ref = React.useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => toggleOpen(false));

  return (
    <$.flex ref={ref} {...props}>
      <Popover
        isOpen={isOpen}
        arrow
        position="bottom-right"
        usePortal={false}
        offset={[0, 15]}
        renderTarget={({ isOpen: _isOpen, ref, ...props }) => {
          return (
            <$.flex
              flex="1 1 auto"
              flex-center
              cursor="pointer"
              p="0 5px"
              $css={{ '&:hover': { color: colors.blue.primaryHover } }}
              {...props}
              onClick={toggleOpen}
            >
              {profile.org_switcher && (
                <Icon icon={faRandom} size={18} color={colors.orange.primary} mr={10} />
              )}
              {userOrg ? userOrg.name : ''}
              <$.div ref={ref}>
                <Icon icon={faUser} ml={8} size={16} />
                <Icon icon={faCaretDown} ml={-2} size={15} />
              </$.div>
            </$.flex>
          );
        }}
        content={
          <$.div p="25px 20px 25px" width={400} color={colors.grey.neutral}>
            <$.div p="0 18px">
              <$.div size={18} weight={400} color={colors.orange.brand} data-test="org-menu-name">
                {profile.last_name && profile.first_name ? (
                  <>
                    {profile.first_name} {profile.last_name}
                  </>
                ) : (
                  ''
                )}
              </$.div>
              <$.div size={14} mt={2}>
                {profile.email}
              </$.div>
              <$.div size={12} weight={500} mt={10}>
                {roleLabels}
              </$.div>

              {incrementalData && (
                <$.div size={12} mt={20}>
                  {incrementalData.map((inc: any) => (
                    <$.div key={inc.source_system}>
                      Last {inc.source_system} import:{' '}
                      {formatDate(inc.max_source_changed_at, DateFormat.dateTimeSlash)}
                    </$.div>
                  ))}
                </$.div>
              )}
            </$.div>

            {(internal || profile.org_switcher) && <OrgSwitcherCard m="30px 0 0" />}

            <$.div borderTop="1px solid #DDD" mt={20} />

            <Popover
              interactionKind={PopoverInteractionKind.HOVER}
              display="block !important"
              position="bottom-left"
              transformOrigin="left top !important"
              matchTargetWidth
              offset={[0, 5]}
              content={
                <$.div p="6px 0">
                  <$.a href="https://support.cancerlinq.org" target="_blank" block p="8px 15px">
                    <Icon icon={faInfoCircle} mr={10} />
                    <$.span inlineBlock>
                      User Support
                      <Icon icon={faExternalLinkSquare} ml={5} />
                    </$.span>
                  </$.a>
                  <$.a href="tel:1-571-483-1600" block p="8px 15px">
                    <Icon icon={faPhone} mr={10} />
                    <$.span inlineBlock>1-571-483-1600</$.span>
                  </$.a>
                </$.div>
              }
            >
              <$.div
                mt={15}
                p="10px"
                cursor="pointer"
                borderRadius={4}
                $css={{ '&:hover': { background: '#f5f5f5' } }}
              >
                <Icon icon={faLifeRing} />
                <$.span inlineBlock ml={10}>
                  Help and Support
                </$.span>
              </$.div>
            </Popover>
          </$.div>
        }
        // targetStyles={{ display: 'flex' }}
      />
    </$.flex>
  );
}
