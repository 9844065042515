import { groupBy } from 'lodash-es';

import { Role } from 'api_measure/lib/roles';

import axios from 'src/data/axios';
import { buildUrl } from 'src/data/react-query';
import routeMaps from 'src/data/routeMaps';

/**
 * These are used to check if a role is required for a given dashboard title (as they appear in Looker).
 * If there is no entry, there is no role requirement for that dashboard.
 */
export const lookerDashboardRoleRequirements: Record<string, Role[]> = {
  'Biosimilars Report': [Role.orgAdmin, Role.provider],
  'Common Data Elements Completeness for Cancer Patients': [Role.orgAdmin, Role.provider],
  'Patient Demographics - CLQ Network': [Role.orgAdmin, Role.provider],
  'Patient Trends by Cancer Site - CLQ Network': [Role.orgAdmin, Role.provider],
};

/**
 * These are used to check if a role is required for a given dashboard category (as they appear in Looker).
 * If there is no entry, there is no role requirement for that category.
 */
export const lookerDashboardCategoryRoleRequirements: Record<string, Role[]> = {
  'Registry': [Role.insightsRegistry],
  'Registry Support': [Role.insightsRegistry],
};

export interface LookerDashboard {
  id: string;
  title: string;
  dashboard_filters: any[];
  space: { name: string };
}

/**
 * Util Functions
 */
export const lookerApiEndpoint = routeMaps.looker.fetchResource;

export async function lookerLogout() {
  try {
    await axios.get(buildUrl(lookerApiEndpoint('session'), { action: 'logout' }));
  } catch (err) {
    console.error(err);
  }
}

/** ------------------------------------------------------------- */

export async function getEmbedURL(dashboard: LookerDashboard) {
  const res = await axios.get(
    buildUrl(lookerApiEndpoint('embed'), { embedPath: `/embed/dashboards-next/${dashboard.id}` }),
  );
  if (!res.data) throw new Error('Something went wrong');
  return res.data;
}

/**
 * @returns {Array} of Array, sorted by report keys. Eg. [
 *   ["Data Quality", [{…}],
 *   ["Demographics", [{…}, …],
 * ]
 */
export async function getLookerDashboards(): Promise<Array<[string, any]>> {
  const res = await axios.get(lookerApiEndpoint('dashboard'));
  if (!res.data) throw new Error('Something went wrong');

  const categorized = groupBy(res.data, (report) => report.space.name);
  Object.keys(categorized).forEach((spaceName) => {
    categorized[spaceName].sort((a, b) => a.title.localeCompare(b.title, 'en', { usage: 'sort' }));
  });
  return Object.entries(categorized).sort(([spaceNameA], [spaceNameB]) => {
    return spaceNameA.localeCompare(spaceNameB, 'en', { usage: 'sort' });
  });
}
