import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import $, { useKeyframes, type StylixProps } from '@stylix/core';
import c from 'color';
import * as React from 'react';

import colors from 'src/ui/colors';
import Icon from 'src/ui/Icon';

import { Popover } from './Popover';

interface ProgressMeterProps {
  percent: number;
  color?: string;
  height: number;
  gradient?: boolean;
  animate?: boolean;
  markerPercent?: number;
  markerColor?: string;
  markerTooltip?: React.ReactNode;
  widthTransition?: `${number}s ${string}`;
}

export default function ProgressMeter(props: StylixProps<'div', ProgressMeterProps>) {
  const {
    percent,
    height,
    color = colors.blue.mid_dark,
    gradient = true,
    animate,
    markerPercent,
    markerColor,
    markerTooltip,
    widthTransition = '0.2s ease-in-out',
    ...rest
  } = props;

  const stopColor = gradient ? c(color).saturate(0.4).lighten(0.3) : color;

  const bgAnimation = useKeyframes({
    from: { backgroundPosition: '20px 20px' },
    to: { backgroundPosition: '0 0' },
  });

  return (
    <$.div relative {...rest}>
      <$.div borderRadius={100} overflow="hidden" background={colors.grey.light} height={height}>
        <$.div
          width={percent ? `${percent * 100}%` : 0}
          minWidth={percent > 0 ? height : 0}
          transition={`width ${widthTransition}`}
          background={`linear-gradient(to right, ${color}, ${stopColor})`}
          height="100%"
          borderRadius={100}
          overflow="hidden"
        >
          {animate && (
            <$.div
              width="100%"
              height="100%"
              backgroundImage={`
                linear-gradient(
                  -45deg,
                  #FFF8 25%,
                  #FFF0 25%,
                  #FFF0 50%,
                  #FFF8 50%,
                  #FFF8 75%,
                  #FFF0 75%,
                  #FFF0
                )`}
              backgroundSize="20px 20px"
              animation={`${bgAnimation} 1s linear infinite`}
            />
          )}
        </$.div>
      </$.div>

      {markerPercent !== undefined && (
        <$.div
          absolute
          top="100%"
          left={markerPercent * 100 + '%'}
          p="0 10px 10px"
          cursor="pointer"
          transform="translateX(-50%)"
        >
          <Popover
            arrow
            interactionKind="hover"
            content={<>{markerTooltip}</>}
            disabled={!markerTooltip}
            placement="top"
            offset={[0, height + 15]}
          >
            <Icon icon={faCaretUp} size={24} color={markerColor} />
          </Popover>
        </$.div>
      )}
    </$.div>
  );
}
