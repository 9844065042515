import { defaults } from 'lodash-es';

import { currentEnv, isLocal } from 'src/data/env';
import track from 'src/data/mixpanel';
import { isOktaEnv, useOktaAuth } from 'src/data/oktaAuth';
import { lookerLogout } from 'src/Looker/lookerHelpers';
import AppToaster from 'src/ui/AppToaster';
import { useResetGlobalFilters } from 'src/ui/GlobalFilters/useGlobalFilters';
import useLoadingModal from 'src/ui/useLoadingModal';
import { delay } from 'src/util/delay';

interface LogOutOptions {
  track?: boolean;
  overlay?: boolean;
  e?: React.UIEvent;
}

const defaultOptions: LogOutOptions = { track: true, overlay: true };

/**
 * Returns a function that will log the user out.
 * Accepts the following options:
 * - options.track: Whether to track the logout event in Mixpanel
 * - options.overlay: Whether to show the loading overlay while logging out
 *
 * The function can also accepts a React UIEvent, which will be preventedDefault()ed.
 */
export default function useLogOut() {
  const { oktaAuth } = useOktaAuth();
  const { showLoadingModal } = useLoadingModal();
  const resetGlobalFilters = useResetGlobalFilters();

  return async (options: React.UIEvent | LogOutOptions = defaultOptions) => {
    if ('type' in options) options = { e: options };

    options.e?.preventDefault();
    options = defaults(options as LogOutOptions, defaultOptions);

    if (options.track) await track('Log Out', null, { send_immediately: true });
    resetGlobalFilters();

    options.overlay && showLoadingModal('Logging out...');

    if (!isOktaEnv()) {
      await delay(2000);
      window.location.href = '/';
      return;
    }

    const attempts = 3;
    let error: any = null;
    const logoutActions = [
      // Log out of Looker
      () => lookerLogout(),

      // Log out of Okta
      () =>
        oktaAuth.signOut({
          postLogoutRedirectUri: isLocal()
            ? 'http://localhost:4000/logout'
            : currentEnv().baseUrl + '/logout',
        }),
    ];

    // Try each logout action 3 times, catching any errors
    // If any action fails, try the next action
    // If all actions fail, just send the user to the home page manually
    for (const action of logoutActions) {
      for (let i = 0; i < attempts; i++) {
        try {
          await action();
          error = null;
          break;
        } catch (e) {
          error = e;
        }
      }
      if (error) {
        console.error(error);
        AppToaster.error('An error occurred during logout.\n\n' + error.message);
        return;
      }
    }
  };
}
