import { Checkbox, PopoverInteractionKind, Position } from '@blueprintjs/core';
import $ from '@stylix/core';
import React, { useCallback, useEffect, useState } from 'react';

import useLogOut from 'src/App/useLogOut';
import { trpc } from 'src/data/trpc';
import { useCurrentUser } from 'src/data/user/useCurrentUser';
import Button from 'src/ui/Button';
import colors from 'src/ui/colors';
import { Popover } from 'src/ui/Popover';
import ScrollableDialog from 'src/ui/ScrollableDialog';
import useLoadingModal from 'src/ui/useLoadingModal';

import EULAText from './EULAText';

export default function EULA() {
  const { eula, reloadUser } = useCurrentUser();

  const [isOpen, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showDeclinePopover, setShowDeclinePopover] = useState(false);

  useEffect(() => {
    if (!eula) setOpen(true);
  }, [eula]);

  const { withLoadingModal } = useLoadingModal();

  const updateEulaAgreement = trpc.updateEulaAgreement.useMutation();

  const handleClickAccept = useCallback(async () => {
    setOpen(false);
    await withLoadingModal('Saving...', async () => {
      await updateEulaAgreement.mutateAsync();
      reloadUser();
    });
  }, []);

  const logout = useLogOut();

  // Renders nothing if user has already accepted;
  // prevents UI glitching out after user accepts if we don't use state variable
  const [isAlreadyAccepted] = useState(eula);
  if (isAlreadyAccepted) return null;

  return (
    <ScrollableDialog
      isOpen={isOpen}
      header={
        <$.div size={30} p="20px 50px 10px" borderBottom="1px solid #DDD">
          User Agreement
        </$.div>
      }
      footer={
        <$.div p="20px 50px" borderTop="1px solid #DDD">
          <$.div font-weight={500}>
            <Checkbox
              checked={isChecked}
              labelElement={
                <span>
                  I have read the System Terms,{' '}
                  <a
                    href="https://www.cancerlinq.org/terms-use"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Website Terms
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.cancerlinq.org/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  and agree to each of the foregoing.
                </span>
              }
              onChange={() => setIsChecked((c) => !c)}
            />
          </$.div>

          <$.div alignRight pt={10}>
            <Button onClick={handleClickAccept} disabled={!isChecked} mr={10}>
              Accept
            </Button>

            <Popover
              interactionKind={PopoverInteractionKind.CLICK}
              position={Position.TOP}
              isOpen={showDeclinePopover}
            >
              <Button variant="ghost" onClick={() => setShowDeclinePopover((x) => !x)}>
                Decline
              </Button>
              <$.div width={330} padding={20}>
                <$.div size={20} color={colors.red.dark} mb={10}>
                  Warning
                </$.div>
                <$.div mb={20}>
                  You must agree to the Terms and Conditions in order to continue and use
                  CancerLinQ.
                </$.div>
                <div>
                  <Button onClick={logout} mr={10}>
                    Decline and Log Out
                  </Button>
                  <Button variant="ghost" onClick={() => setShowDeclinePopover((x) => !x)}>
                    Cancel
                  </Button>
                </div>
              </$.div>
            </Popover>
          </$.div>
        </$.div>
      }
      maxWidth={650}
    >
      <EULAText p="20px 50px" />
    </ScrollableDialog>
  );
}
