import { faWrench } from '@fortawesome/pro-solid-svg-icons';
import { useClickOutside } from '@react-hookz/web';
import $, { type StylixProps } from '@stylix/core';
import dayjs from 'dayjs';
import { sortBy } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';

import { allRoles, Role, roleInfo } from 'api_measure/lib/roles';

import { useDebugUser } from 'src/data/debugUser';
import { isOktaEnv } from 'src/data/oktaAuth';
import { trpc } from 'src/data/trpc';
import { useUserHasRoles } from 'src/data/user/roles';
import { useCurrentUser } from 'src/data/user/useCurrentUser';
import colors from 'src/ui/colors';
import { MultiDropdown } from 'src/ui/Dropdown/MultiDropdown';
import Icon from 'src/ui/Icon';
import LabelWrapper from 'src/ui/LabelWrapper';
import Link from 'src/ui/Link';
import { Popover } from 'src/ui/Popover';
import { TextInput } from 'src/ui/TextInput';

export default function DevMenu(props: StylixProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(wrapperRef, () => setPopoverOpen(false));

  const { serverRoles, provider, profile } = useCurrentUser();
  const userHasRoles = useUserHasRoles();
  const { debugUser, setDebugUser, resetDebugRoles } = useDebugUser();

  const [date, setDate] = useState(debugUser?.date?.format('M/D/YYYY') || '');
  const [npi, setNpi] = useState(String(provider?.npi || debugUser?.provider?.npi || ''));
  const [searchNpi, setSearchNpi] = useState(npi);

  const getProvidersQuery = trpc.getProviders.useQuery(
    { npi: searchNpi!, all: true },
    { enabled: !!searchNpi },
  );

  useEffect(() => {
    setDebugUser((du) => ({
      ...du!,
      provider: userHasRoles(Role.provider) ? getProvidersQuery.data?.[0] || null : null,
    }));
  }, [userHasRoles, getProvidersQuery.data?.[0]?.provider_empi_id]);

  useEffect(() => {
    provider?.npi && setNpi(String(provider.npi));
  }, [provider?.npi]);

  return (
    <$.flex {...props} ref={wrapperRef}>
      <Popover
        isOpen={popoverOpen}
        arrow
        usePortal={false}
        content={
          <$.div padding={24} width={400}>
            <$.div size={22}>Debugger</$.div>

            <LabelWrapper
              $el="div"
              label="User roles"
              footer={
                <>
                  Note that these roles only affect user interface; you may still be restricted from
                  accessing certain data.{' '}
                  <Link onClick={() => resetDebugRoles(serverRoles)}>Reset</Link>
                </>
              }
              mt={30}
              small
              block
            >
              <MultiDropdown<Role>
                allLabel="All roles"
                options={sortBy(allRoles, (r) => roleInfo[r].label)}
                defaultValue={serverRoles}
                isClearable
                value={debugUser?.roles || []}
                onChange={(v) => setDebugUser((du) => ({ ...du!, roles: v }))}
                placeholder="Select a user role"
                renderOption={(role) => roleInfo[role].label}
                width="100%"
              />
            </LabelWrapper>

            <LabelWrapper label="Current Date" small block mt={30}>
              <TextInput
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                onBlur={() => {
                  const day = date && dayjs(date).isValid() ? dayjs(date) : null;
                  if (day) setDate(day.format('M/D/YYYY'));
                  setDebugUser((du) => ({
                    ...du!,
                    date: day || null,
                  }));
                }}
              />
            </LabelWrapper>

            {!isOktaEnv() && (
              <LabelWrapper label="Email" small block mt={30}>
                <TextInput
                  value={debugUser?.email || ''}
                  onChange={(e) => setDebugUser((du) => ({ ...du!, email: e.target.value }))}
                />
              </LabelWrapper>
            )}

            {userHasRoles(Role.provider) && (
              <LabelWrapper label="NPI" small block mt={30}>
                <TextInput
                  value={npi}
                  disabled={!!profile?.national_provider_id}
                  onChange={(e) => {
                    setNpi(e.target.value);
                  }}
                  onBlur={() => {
                    setSearchNpi(npi);
                  }}
                />
              </LabelWrapper>
            )}
          </$.div>
        }
        targetStyles={{
          'flex-center': true,
        }}
      >
        <$.div
          flex="1 1 auto"
          cursor="pointer"
          p="0 5px"
          onClick={() => setPopoverOpen((open) => !open)}
          $css={{
            '&:hover': {
              color: colors.blue.primaryHover,
            },
          }}
        >
          <Icon icon={faWrench} size={16} />
        </$.div>
      </Popover>
    </$.flex>
  );
}
